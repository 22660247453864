<div class="tr_card future_tr_card">
  <div class="row m-0 card_row">
    <div
      class="col-sm-12 col-md-12 col-lg-6 col-xl-6 d-flex card_wrapper mobile_hide"
      *ngFor="let tradeDtls of getTradingDetails"
    >
      <div class="statistics_section">
        <div
          class="tr_card-header flex-wrap d-flex justify-content-between align-items-center"
        >
          <div>
            <p class="m-0 title">On Binance</p>
            <h5 class="m-0 card_font">{{ tradeDtls.market }}</h5>
            <!-- text-muted  -->
          </div>
          <div>
            <p class="m-0 title">Current Price</p>
            <h6 class="m-0 card_font">{{ tradeDtls.price }} {{ "BTC" }}</h6>
            <!-- text-muted  -->
          </div>
          <div>
            <p class="m-0 title">Created On</p>
            <h6 class="m-0 card_font">
              {{ tradeDtls.createdAt | date : "MMM d , y | h:mm:ss a" }}
            </h6>
            <!-- text-muted  -->
          </div>
          <!-- <div>
                    <div

                    tooltip-class='tooltips'
                        class="star animate_hover pointer" ></div>
                </div> -->
          <div class="card_data">
            <div
              class="watchlist animate_hover pointer"
              tooltip-class="tooltips"
            >
              <img
                src="assets/images/tenx/innerPage/signal/watchlist.png"
                alt="bookmark"
              />
            </div>
            <div>
              <button
                type="button"
                class="trade_btn"
                placement="bottom"
                ngbTooltip="Coming Soon"
                tooltipClass="trade_tooltip"
              >
                Trade
              </button>
            </div>
          </div>
        </div>
        <div class="tr_card-content justify-content-between align-items-center">
          <div class="main-card-item-wrap align-items-center flex-wrap">
            <div
              class="main-card-item position"
              [ngClass]="
                tradeDtls.position === 'Short' ? 'short-time' : 'long-time'
              "
            >
              <span>{{ tradeDtls.position }}</span>
            </div>
            <div class="main-card-item">
              <span class="risk card_font"
                >Risk :<span>{{ tradeDtls.risk }}</span></span
              >
            </div>
            <div class="main-card-item leverage">
              <span class="risk card_font"
                >Leverage :
                <span>
                  {{ tradeDtls.leverage ? tradeDtls.leverage + "x" : "" }}
                </span></span
              >
            </div>
            <div class="main-card-item">
              <div class="d-flex align-items-center">
                <span class="risk card_font"
                  >Time Frame :
                  <span>{{ tradeDtls.timeFrame }}</span>
                </span>
              </div>
            </div>
          </div>
          <div class="row mx-0 my-2 align-items-center box-cnr">
            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 p-0">
              <div class="d-flex align-items-center">
                <span class="w-pre card_font entry">Entry : </span>
                <span class="profit"
                  >{{ tradeDtls.entryFrom }}-{{ tradeDtls.entryTo }}</span
                >
              </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 p-0">
              <div class="d-flex align-items-center">
                <span class="w-pre card_font stop_loss">Stop Loss : </span>
                <span class="loss"
                  >{{ tradeDtls.stopValue }} BTC ({{
                    tradeDtls.stopValuePercentage
                  }}%)</span
                >
              </div>
            </div>
          </div>
          <div class="d-flex my-2 target_wrapper">
            <div class="web-target-title">
              <div class="d-flex py-2 align-items-center">
                <span class="w-pre card_font target">Targets : </span>
              </div>
            </div>
            <div class="target-container flex-grow-1">
              <div class="d-flex flex-wrap exp-amount-wrap">
                <div class="mobile-target-title">Targets :</div>
                <div
                  *ngFor="
                    let item of tradeDtls.targetValueDetails;
                    let j = index
                  "
                  class="col-sm-6 col-md-6 col-lg-6 col-xl-6 py-2 d-flex align-items-start"
                >
                  <span class="indexNo mr-2">{{
                    j + 1 < 10 ? "0" + (j + 1) : j + 1
                  }}</span>

                  <div class="target_percent_container">
                    <span class="card_font">{{ item.targetValue }}</span>
                    <span class="card_font"
                      >( {{ item.targetPercentage }} % )</span
                    >
                    <!-- text-muted  -->
                  </div>
                </div>
              </div>
            </div>
            <div class="mobile_graph_section">
              <div
                class="graphOuter d-flex flex-column position-relative mh-100"
                [ngClass]="tradeDtls.graphType + ' ' + tradeDtls.theme"
              >
                <div class="blend-heighlight"></div>
                <div class="graph-profit">
                  <h5 class="card_font">{{ tradeDtls.totalPercentage }} %</h5>
                  <!-- <span class="card_font hourCount">In {{graphData['total_hours']}} Hours</span> -->
                  <span
                    class="card_font hourCount"
                    *ngIf="tradeDtls.totalHours > 24"
                    >In
                    {{ tradeDtls.totalHours / 24 | number : "1.0-0" }}
                    Days</span
                  >
                  <span
                    class="card_font hourCount"
                    *ngIf="tradeDtls.totalHours < 24"
                    >In {{ tradeDtls.totalHours }} Hours</span
                  >
                </div>
                <div class="graph">
                  <div
                    class="graph-single"
                    *ngFor="
                      let bars of tradeDtls.targetValueDetails;
                      let k = index
                    "
                  >
                    <div class="graph-single-line">
                      <div class="graph-single-line-empty">
                        <div
                          class="graph-single-line-fill"
                          [ngStyle]="{
                            height: bars.targetBarPercentage + '%'
                          }"
                        ></div>
                      </div>
                    </div>
                    <div class="graph-single-label">
                      {{ k + 1 }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="web_graph_section">
        <div
          class="graphOuter d-flex flex-column position-relative mh-100"
          [ngClass]="tradeDtls.graphType + ' ' + tradeDtls.theme"
        >
          <div class="blend-heighlight"></div>
          <div class="graph-profit">
            <h5 class="card_font">{{ tradeDtls.totalPercentage }} %</h5>
            <!-- <span class="card_font hourCount">In {{graphData['total_hours']}} Hours</span> -->
            <span class="card_font hourCount" *ngIf="tradeDtls.totalHours > 24"
              >In {{ tradeDtls.totalHours / 24 | number : "1.0-0" }} Days</span
            >
            <span class="card_font hourCount" *ngIf="tradeDtls.totalHours < 24"
              >In {{ tradeDtls.totalHours }} Hours</span
            >
          </div>
          <div class="graph">
            <div
              class="graph-single"
              *ngFor="let bars of tradeDtls.targetValueDetails; let k = index"
            >
              <div class="graph-single-line">
                <div class="graph-single-line-empty">
                  <div
                    class="graph-single-line-fill"
                    [ngStyle]="{
                      height: bars.targetBarPercentage + '%'
                    }"
                  ></div>
                </div>
              </div>
              <div class="graph-single-label">
                {{ k + 1 }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
