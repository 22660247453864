<div class="wrapper">
  <app-admin-header></app-admin-header>
  <app-admin-sidebar></app-admin-sidebar>
  <app-spinner></app-spinner>
  <section class="admin_view content_body_padding">
    <!-- <div style="width: 100%; height: auto;">
            <app-breadcrumb></app-breadcrumb>
        </div> -->
    <router-outlet></router-outlet>
  </section>
  <!-- <app-footer></app-footer> -->
</div>
