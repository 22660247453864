<div class="footer" id="footer" [ngClass]="footerBorderColor()">
  <div class="row footer-container">
    <div
      class="w-100 responsive"
      [ngClass]="footerBorderBtmMobile()"
      *ngIf="mobileView"
    >
      <div class="social-wrapper">
        <div class="logo">
          <img [src]="logo()" alt="footerLogo" />
        </div>
        <ul class="social-media">
          <li>
            <a
              href="https://www.facebook.com/tenx.io?mibextid=LQQJ4d"
              target="_blank"
            >
              <img [src]="fb()" alt="facebook"
            /></a>
          </li>
          <li>
            <a href="https://twitter.com/tenxgem" target="_blank">
              <img [src]="tweet()" alt="twitter"
            /></a>
          </li>
          <li>
            <a
              href="https://instagram.com/10xgem?igshid=YmMyMTA2M2Y="
              target="_blank"
            >
              <img [src]="insta()" alt="instagram"
            /></a>
          </li>
          <li>
            <a href="https://t.me/tenxgem" target="_blank">
              <img [src]="pin()" alt="pintrest"
            /></a>
          </li>
          <li>
            <a href="https://www.youtube.com/@tenxgem/featured" target="_blank">
              <img [src]="youtube()" alt="youtube"
            /></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-md-12 col-lg-9">
      <ul class="menu" [ngClass]="footerMenuColor()">
        <li class="logo">
          <a routerLink="/">
            <img [src]="logo()" alt="footerLogo" />
          </a>
        </li>
        <li>
          <a routerLink="/public/help-center">Help Centre</a>
        </li>
        <li>
          <a routerLink="/">Terms and Conditions</a>
        </li>
        <li><a routerLink="/">Privacy Policy</a></li>
        <li><a routerLink="/public/affiliate">Affiliate</a></li>
        <li>
          <a routerLink="/public/news">News</a>
        </li>
        <li>
          <a routerLink="/public/blogs">Blog</a>
        </li>
      </ul>
    </div>
    <div class="col-md-3 col-lg-3 not-responsive">
      <ul class="social-media">
        <li>
          <a
            href="https://www.facebook.com/tenx.io?mibextid=LQQJ4d"
            target="_blank"
          >
            <img [src]="fb()" alt="facebook"
          /></a>
        </li>
        <li>
          <a href="https://twitter.com/tenxgem" target="_blank">
            <img [src]="tweet()" alt="twitter"
          /></a>
        </li>
        <li>
          <a
            href="https://instagram.com/10xgem?igshid=YmMyMTA2M2Y="
            target="_blank"
          >
            <img [src]="insta()" alt="instagram"
          /></a>
        </li>
        <li>
          <a href="https://t.me/tenxgem" target="_blank">
            <img [src]="pin()" alt="pintrest"
          /></a>
        </li>
        <li>
          <a href="https://www.youtube.com/@tenxgem/featured" target="_blank">
            <img [src]="youtube()" alt="youtube"
          /></a>
        </li>
      </ul>
    </div>
  </div>
  <div
    *ngIf="navigationTrigger"
    class="navigation-trigger"
    id="navTrigger"
  ></div>
</div>
