import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/core/shared/storage.service';

@Component({
  selector: 'app-video-section',
  templateUrl: './video-section.component.html',
  styleUrls: ['./video-section.component.scss'],
})
export class VideoSectionComponent implements OnInit {
  constructor(private storeService: StorageService, private router: Router) {}
  showPlayIcon: boolean = true;
  ngOnInit(): void {
  
  }
  playVideo() {
    let video = document.getElementById('video');
    let videoPlayIcon = document.getElementById('videoPlayIcon');

    this.showPlayIcon = !this.showPlayIcon;
  }
  roadmapNotifyNavigation() {
    this.storeService.waitlist.next(true);
    // this.router.navigate(['/roadmap#notifyMe']);
    this.router.navigate(['/roadmap', 'notifyMe']);
  }
}
