import { Component, OnInit, OnChanges, Input } from '@angular/core';

@Component({
  selector: 'app-spot-market',
  templateUrl: './spot-market.component.html',
  styleUrls: ['./spot-market.component.scss'],
})
export class SpotMarketComponent implements OnInit, OnChanges {
  tradingDtls: any;
  @Input() tradingDetails?: any;
  constructor() {}
  ngOnInit(): void {}
  ngOnChanges(): void {
    this.tradingDtls = JSON.parse(this.tradingDetails? this.tradingDetails : null);
    // console.log(this.tradingDtls?.listSpotMarket);
  }
  
 
}
