<!-- Guest header  -->
<!-- <header class="header_wrapper_guest">
   <div class="container-fluid">
    <div class="row">
      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
        <a class="brand"><img src="assets/img/logo.png" alt="Logo" /></a>
      </div>
      <div class="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-6 col-6">
        <div class="btn_grp">
          <a class="signin_btn site_color_btn" [routerLink]="['/auth/login']">Sign In</a>
        </div>
      </div>
    </div>
  </div>

</header> -->
<div class="navigation_wrapper" *ngIf="!token || role == '1'">
  <div class="container">
    <div class="navigation_container">
      <div class="logo">
        <a [routerLink]="['']"
          ><img
            class="logo-primary"
            src="assets/images/tenx/logo.png"
            alt="logo"
        /></a>
        <!-- <img class="logo-black" src="assets/images/tenx/logo-black.png" alt="logo" /> -->
        <!-- <img class="logo-black-green" src="assets/images/tenx/logo-black-green.png" alt="logo" /> -->
      </div>
      <div class="signin">
        <a class="signin-background" routerLink="/login">Sign In</a>
      </div>
    </div>
  </div>
</div>

<!-- Guest header ends  -->

<header class="header_wrapper" *ngIf="token && role == '2'">
  <div class="container-fluid">
    <nav class="navbar_inner">
      <div class="header_nav_left">
        <div class="mobile_nav_block">
          <a class="brand" [routerLink]="['']">
            <img class="normal_logo" src="assets/img/logo.png" alt="Logo" />
            <img
              class="forex_header_logo_img"
              src="assets/img/black_green_logo.png"
              alt="Logo"
            />
          </a>
        </div>
        <div class="page_title mob_view_title">
          <a *ngIf="urlName == 'forex-market' || urlName == 'future-market'" class="pg_title_new">{{ headerTitle }}</a>
          <a *ngIf="urlName != 'forex-market' && urlName != 'future-market'" class="pg_title">{{ headerTitle }}</a>
        </div>
      </div>

      <div class="header_nav_right">
        <ul>
          <li>
            <button type="button" class="notify">
              <i class="fa-solid fa-bell"></i>
            </button>
          </li>
          <li class="mobile_toggle_sidebar" (click)="openNav()">
            <div class="tog_circle">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </li>
          <li>
            <div class="user_profile mob_view_userprofile">
              <h3 class="user_name">{{ firstName }}</h3>
              <div class="profile_img">
                <span class="profile_dp"
                  ><img
                    [src]="getProfileImg(profilePic)"
                    onerror="this.onerror=null;this.src='assets/img/profile_img.jpg';"
                    alt="Profile"
                /></span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</header>
