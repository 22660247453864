<!-- <section class="menu_sidebar sidenav" id="mySidenav" [ngStyle]="{'width': menuwidth+'%'}"> -->
<section class="menu_sidebar sidenav" id="mySidenav">
  <div class="menu_sidebar_inner">
    <div class="row">
      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
        <div class="sidebar_menus_list">
          <div class="user_data_block">
            <span class="profile_img">
              <span class="profile_img_inner">
                <img [src]="getProfileImg(profilePic)" onerror="this.onerror=null;this.src='../../../assets/img/profile_img.jpg';"
                  alt="UserImage"
                />
              </span>
            </span>
            <div class="user_data_nm">
              <h3>{{firstName}} {{lastName}}</h3>
              <a class="edit_profile" href="/public/customer-profile"
                ><i class="fa-solid fa-pencil"></i> Edit Profile</a
              >
            </div>
          </div>

          <div class="nav_menus">
            <ul>
              <li>
                <a
                  ><span
                    ><img
                      src="../../../assets/img/icons/menu/dashboard.svg"
                      alt="Dashboard"
                  /></span>
                  Dashboard</a
                >
              </li>
              <li>
                <a href="/public/forex-market"
                  ><span
                    ><img
                      src="../../../assets/img/icons/menu/signal_cards.svg"
                      alt="Signal"
                  /></span>
                  Signal Cards</a
                >
              </li>
              <li>
                <a
                  ><span
                    ><img
                      src="../../../assets/img/icons/menu/charts.svg"
                      alt="Charts"
                  /></span>
                  Charts</a
                >
              </li>
              <li>
                <a
                  ><span
                    ><img
                      src="../../../assets/img/icons/menu/10x.svg"
                      alt="10x"
                  /></span>
                  10x Gem</a
                >
              </li>
              <li>
                <a href="/public/affiliate"
                  ><span
                    ><img
                      src="../../../assets/img/icons/menu/referral.svg"
                      alt="Referral"
                  /></span>
                  Referral</a
                >
              </li>
              <li>
                <a [href]="subscriptionRoute"
                  ><span
                    ><img
                      src="../../../assets/img/icons/menu/subscriptions.svg"
                      alt="Subscriptions"
                  /></span>
                  Subscriptions</a
                >
              </li>
            </ul>
            <button type="button" class="log_out" (click)="logout()">
              <span
                ><img
                  src="../../../assets/img/icons/menu/logout.svg"
                  alt="Logout"
              /></span>
              Logout
            </button>
          </div>
        </div>
      </div>
      <div class="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12">
        <div class="content_menu">
          <h1>Coming Soon ...</h1>
          <ul>
            <li>
              <a><span>01 </span> / TenX Hub</a>
            </li>
            <li class="menu_active">
              <a><span>02 </span> / TenX NFT</a>
            </li>
            <li>
              <a><span>03 </span> / TenX Market</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
