import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landingpage-footer',
  templateUrl: './landingpage-footer.component.html',
  styleUrls: ['./landingpage-footer.component.scss']
})
export class LandingpageFooterComponent implements OnInit, AfterViewInit {
  public href: string = "";
  animationLoader: any;
  bottomReach: boolean = false;
  navigationTrigger: boolean = false;
  pageHeight: any;
  currentScrollHeight: any;
  onScrollFunc: () => void;

  constructor(private router: Router,) {
    this.onScrollFunc = () => {
      console.log(this.navigationTrigger);
      let documentHeight = document.body.scrollHeight;
      let currentScroll = window.scrollY + window.innerHeight;
      this.currentScrollHeight = currentScroll
      this.pageHeight = documentHeight;

      // console.log('pageHeight:',this.pageHeight )
      // console.log('scrolledHeight:',this.currentScrollHeight )
      // When the user is [modifier]px from the bottom, fire the event.
      let modifier = 0;

      // console.log('Bottom reached Triggered!')

      // console.log(this.pageHeight, this.currentScrollHeight)


      if (!this.navigationTrigger && currentScroll + 1 + modifier > documentHeight) {
        setTimeout(() => {
          //Enables the "navigationTrigger variable"
          this.navigationTrigger = true;
          // console.log(this.navigationTrigger)
        }, 1500)
      }
      else if (this.navigationTrigger == true && currentScroll + 1 + modifier >= documentHeight) {

        setTimeout(() => {
          this.router.navigate(['/']);
        }, 500)

      }
      //if user scroll to upwards while in bottom of page we disable the footer navigation variable "navigationTrigger"
      else if (this.navigationTrigger == true && this.currentScrollHeight < this.pageHeight - 220) {
        this.navigationTrigger = false;
        this.bottomReach = false;
      }

      // console.log('navigationTrigger:', this.navigationTrigger, 'bottomReach:', this.bottomReach)

    }
  }
  mobileView: any;
  ngOnInit(): void {
    this.href = this.router.url;
    let responsive = window.innerWidth;
    if (responsive <= 1050) {
      this.mobileView = true;
    }

    window.addEventListener('scroll', this.onScrollFunc);

  }
  logo() {
    if (this.href == "/tenx") {
      return 'assets/images/tenx/logo.png'
    } else if (this.href == "/signal") {
      return 'assets/images/tenx/logo.png'
    } else if (this.href == "/affiliate") {
      return 'assets/images/tenx/logo-black-green.png'
    } else if (this.href == "/roadmap" || this.href == "/roadmap/notifyMe") {
      return 'assets/images/tenx/logo-black.png'
    } else {
      return 'assets/images/tenx/logo.png'
    }
  }

  footerMenuColor() {
    if (this.href == "/tenx" || this.href == "/signal") {

      return 'li-white'
    } else {
      return 'li-black'
    }
  }
  footerBorderColor() {
    if (this.href == "/tenx") {
      return 'tenxBorder'
    } else if (this.href == "/affiliate") {
      return 'affiliateBorder'
    } else if (this.href == '/roadmap' || this.href == '/roadmap/notifyMe') {
      return 'roadmapBorder'
    } else {
      return 'signalBorder'
    }
  }

  footerBorderBtmMobile() {
    if (this.href == "/tenx") {
      return 'tenxBorderBtm'
    } else if (this.href == "/affiliate") {
      return 'affiliateBorderBtm'
    } else if (this.href == '/roadmap' || this.href == '/roadmap/notifyMe') {
      return 'roadmapBorderBtm'
    } else {
      return 'signalBorderBtm'
    }
  }

  // Dynamic footer social icons
  fb() {
    if (this.href == "/tenx" || this.href == "/signal") {
      return 'assets/images/tenx/innerPage/face_book.svg'
    } else {
      return 'assets/images/tenx/innerPage/affiliate-innerpage/facebook.png'
    }
  }
  tweet() {
    if (this.href == "/tenx" || this.href == "/signal") {
      return 'assets/images/tenx/innerPage/twitter.svg'
    } else {
      return 'assets/images/tenx/innerPage/affiliate-innerpage/twitter.png'
    }
  }
  insta() {
    if (this.href == "/tenx" || this.href == "/signal") {
      return 'assets/images/tenx/innerPage/p.svg'
    } else {
      return 'assets/images/tenx/innerPage/affiliate-innerpage/insta.png'
    }
  }
  pin() {
    if (this.href == "/tenx" || this.href == "/signal") {
      return 'assets/images/tenx/innerPage/pin.svg'
    } else {
      return 'assets/images/tenx/innerPage/affiliate-innerpage/pin.png'
    }
  }
  youtube() {
    if (this.href == "/tenx" || this.href == "/signal") {
      return 'assets/images/tenx/innerPage/youtube.svg'
    } else {
      return 'assets/images/tenx/innerPage/affiliate-innerpage/youtube.png'
    }
  }


  footerLinks(e: any) {
    this.router.navigate([e]);
  }
  ngAfterViewInit(): void {
    // window.addEventListener('scroll', ((e: any) => {
    //   this.triggerNavigation(e);
    // }).bind(this), false);
  }
  ngOnDestroy() {

    window.removeEventListener('scroll', this.onScrollFunc);


  }

}
