<app-overlay-sidebar [menuwidth]="menuwidth"></app-overlay-sidebar>
<aside class="sidebar">
  <div class="sidebar_inner">
    <div class="brand_top">
      <a [routerLink]="['']"><img src="assets/img/logo.png" alt="Logo" /></a>
    </div>
    <button type="button" class="sidebar_tog_btn" (click)="openNav()">
      <!-- <img class="tog_img" src="assets/img/menu.png" alt="Toggle" /> -->

      <div class="tog_circle">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>

      <!-- <img
        class="close_menu_img"
        src="assets/img/menu_close.png"
        alt="Toggle"
      /> -->
    </button>
  </div>
</aside>
