import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/core/shared/storage.service';

@Component({
  selector: 'app-join-now',
  templateUrl: './join-now.component.html',
  styleUrls: ['./join-now.component.scss']
})
export class JoinNowComponent implements OnInit {

  constructor(private router: Router, private storeService: StorageService) { }

  ngOnInit(): void {
  }
  roadmapNotifyNavigation() {
    this.storeService.waitlist.next(true);
    // this.router.navigate(['/roadmap#notifyMe']);
    this.router.navigate(['/roadmap', 'notifyMe']);
  }
}
