import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationError, NavigationStart, Params, Router } from '@angular/router';
import { StorageService } from 'src/app/core/shared/storage.service';
import { ApiService } from 'src/app/core/shared/api.service';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.scss']
})
export class AdminHeaderComponent implements OnInit {
  urlName: any;
  headerTitle: any;
  buttonName: string = '';
  buttonEnable: string = '';
  firstName: string = '';
  lastName: string = '';
  supportListData: any = [];
  token: String = '';
  role: String = '';
  currentRoute: any;
  singalCardList : string ='';

  constructor(
    private router: Router,
    private storage: StorageService,
    private _apiservice: ApiService,
  ) {
    this.currentRoute = "";
    this.router.events.subscribe((event: any = Event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url.split('/').pop();
        if (event.url.indexOf('signal-card-list') > -1)
          this.singalCardList = 'true';
        else
          this.singalCardList = 'false';

        // for header name
        if (this.currentRoute == 'dashboard') {
          this.headerTitle = 'Dashboard';
          this.buttonEnable = 'disable';
        }
        else {
          this.headerTitle = this.storage.headerName
        }

        // for header button
        if (this.currentRoute == 'blog-list') {
          this.buttonEnable = 'enable';
          this.buttonName = 'Create';
        } else if (this.currentRoute == 'help-center-list') {
          this.buttonEnable = 'enable';
          this.buttonName = 'Manage';
        } else if (this.currentRoute == 'subscription-list') {
          this.buttonEnable = 'enable';
          this.buttonName = 'Create'
        } else if (this.currentRoute == 'affiliate-list') {
          this.buttonEnable = 'enable';
          this.buttonName = 'Manage Vision'
        }
        else if (this.currentRoute == '1') {
          if(this.singalCardList == "true")
          this.buttonEnable = 'enable';
          else
          this.buttonEnable = 'disable';

          this.buttonName = 'Create'
        }
        else{
          this.buttonEnable = 'disable';
        }

      }
    });
  }

  ngOnInit(): void {
    this.firstName = this.storage.userFirstName;
    this.lastName = this.storage.userLastName;
    this.getSupportList();

  }

  create() {
    if (this.currentRoute == 'blog-list') {
      this.router.navigate(['admin/blog-create'])
    }
    else if (this.currentRoute == 'help-center-list') {
      this.router.navigate(['admin/manage-help-center'])
    }
    else if (this.currentRoute == 'subscription-list') {
      this.router.navigate(['admin/subscription-create'])
    }
    else if (this.currentRoute == 'affiliate-list') {
      this.router.navigate(['admin/vision-list'])
    }
    else if (this.currentRoute == '1') {
      this.router.navigate(['admin/signal-card-create'])
    }

  }

  getSupportList() {
    let apiUrl = `support/?Page=${'1'}&PerPage=${'10'}`
    this._apiservice.httpGet(apiUrl).subscribe((result) => {
      this.supportListData = result.data;
    })
  }


}
