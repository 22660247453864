import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class TosterService {

  constructor(private toastr: ToastrService) { }
  show(config: { type: string, title: string, subTitle?: string }): void {
    switch (config.type) {
      case 'Success':
        this.toastr.success(config.subTitle ? config.subTitle : '', config.title);
        break;
      case 'Error':
        this.toastr.error(config.subTitle ? config.subTitle : '', config.title);
        break;
      case 'Warning':
        this.toastr.warning(config.subTitle ? config.subTitle : '', config.title);
        break;
      case 'Info':
        this.toastr.info(config.subTitle ? config.subTitle : '', config.title);
        break;
      default:
        break;
    }
  }
}
