<div class="block-wraper" >
    <div class="row">
        <div class="col-md-6 col-sm-12 col-xs-12 pr-0 paddingLeft section-a">
            <div class="row marginRight">
                <div class="col-md-12 pr-0 spacing spacing-tab-screen">
                    <div class="box daily-spot text-wrapBlack">
                        <h2>4 Level Of Commision</h2>
                        <p>By sharing your affiliate link you can earn up to four levels of commission. First level is 10%, second level is 8%, third level is 6%, and fourth level is 4%</p>
                        <a routerLink="/public/help-center">Learn More</a>
                    </div>

                </div>
                <div class="col-md-6 col-sm-6 col-6 paddingRight spacing spacing-tab-screen">
                    <div class="box daily-future text-wrapWhite ">
                        <h2>Smart Contract Based Affiliate
                        </h2>
                        <p>All payments made through this affiliate program are based on our smart contract.</p>
                        <a routerLink="/public/help-center">Learn More</a>


                    </div>
                </div>
                <div class="col-md-6 col-sm-6 col-6 pr-0">
                    <div class="box daily-technical text-wrapWhite mob-clr-change-white">

                        <h2>Get Paid Instantly</h2>
                        <p>As soon as your referee subscribes, you receive your payment instantly which will also be available for withdrawal.</p>
                        <a routerLink="/public/help-center">Learn More</a>


                    </div>
                </div>
            </div>

        </div>
        <div class="col-md-6 col-sm-12 col-xs-12 pl-0 pr-0 spacing section-b">
            <div class="row m-0">
                <div class="col-md-12 col-sm-12 col-xs-12 responsiveExc paddingRight spacing">
                    <div class="box daily-spot text-wrapBlack">
                        <h2>No Minimal Withdrawal Limit</h2>
                        <p>There is no limit amount when you wish to withdraw.</p>
                        <a routerLink="/public/help-center">Learn More</a>

                    </div>
                </div>
                <div class="col-md-6 col-sm-6 col-6 paddingRight spacing">
                    <div class="box daily-future text-wrapWhite">
                        <h2>Get Paid By BNB Or BUSD</h2>
                        <p>All commissions made in either BNB or BUSD will be paid according to that coin used by your referee.</p>
                        <a routerLink="/public/help-center">Learn More</a>

                    </div>
                </div>
                <div class="col-md-6 col-sm-6 col-6 paddingRightres ">
                    <div class="box next text-wrapBlack mob-clr-change-black">
                        <h2>Unlimited Potential Of Commission</h2>
                        <p>You don’t only earn commission from who you directly invite, but also the consequenting referees gained from each level</p>
                        <a routerLink="/public/help-center">Learn More</a>

                    </div>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12 responsiveExcnew">
                    <div class="box daily-spot text-wrapBlack">
                        <h2>No Minimal Withdrawal Limit</h2>
                        <p>There is no limit amount when you wish to withdraw.</p>
                        <a routerLink="/public/help-center">Learn More</a>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>