import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/core/shared/api.service';
import { TosterService } from 'src/app/core/shared/toster.service';

@Component({
  selector: 'app-spot-trade-card',
  templateUrl: './spot-trade-card.component.html',
  styleUrls: ['./spot-trade-card.component.scss'],
})
export class SpotTradeCardComponent implements OnInit {
  constructor(
    private api: ApiService,
    private spinner: NgxSpinnerService,
    private toast: TosterService,
  ) {}
  showPlayIcon: boolean = true;
  @Input() tradingDetails?: any;
  getTradingDetails: any;
  ngOnInit(): void {}
  ngOnChanges(): void {
    // console.log(this.tradingDetails);
    this.getTradingDetails = this.tradingDetails?.listSpotMarket;
    // console.log(this.getTradingDetails?.totalPercentage);
  }
  addFavCard(data: any): void {
    //console.log(data);
    const actionType = data.favorite_status ? 'delete' : 'add';
    this.spinner.show();
    this.api
      .httpPost(`card/favorite/${actionType}/${data.id}`, '')
      .subscribe((res) => {
        this.spinner.hide();
        // this.refreshCard.emit({ refresh: true });
      });
  }
  addWatchList(data: any) {
    const actionType = data.watch_list_status ? 'delete' : 'add';
    this.spinner.show();
    this.api
      .httpPost(`card/watchlist/${actionType}/${data.id}`, '')
      .subscribe((res) => {
        this.toast.show({ type: 'Success', title: res.data });
        this.spinner.hide();
        // this.refreshCard.emit({ refresh: true });
      });
  }
}
