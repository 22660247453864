<div class="tenxgem_wrapper">
  <div class="row content-wrapper">
    <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2 header_section">
      <h1>10X Gem</h1>
      <p>
        You will have access to high potential crypto gems before anybody else
        knows about them. Our crypto experts conduct all the research and select
        the highest potential gems for you.
      </p>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-10 col-xl-10 panel_section">
      <div class="container-1">
        <div class="panel_box">
          <div class="image">
            <img src="assets/images/tenx/innerPage/signal/T1.png" alt="image" />
          </div>
          <div class="panel_content_wrap">
            <div class="panel_details">
              <div class="trending_wrap">
                <div class="trending">#Trending</div>
              </div>
              <h5>Shiba Coin</h5>
              <p class="date">April 21, 2022</p>
              <p class="content">
                Shiba Inu token is a decentralized cryptocurrency created in
                August 2020 by an anonymous person or group known as "Ryoshi"
              </p>
              <a routerLink="/public/help-center" class="readMore">Read More</a>
            </div>
          </div>
        </div>
        <div class="panel_box">
          <div class="image">
            <img src="assets/images/tenx/innerPage/signal/T3.png" alt="image" />
          </div>
          <div class="panel_content_wrap">
            <div class="panel_details">
              <div class="trending_wrap">
                <div class="trending">#Trending</div>
              </div>
              <h5>Ethereum</h5>
              <p class="date">April 21, 2022</p>
              <p class="content">
                Ethereum is a decentralized, open-source blockchain with smart
                contract functionality. Ether is the native cryptocurrency of
                the platform
              </p>
              <a routerLink="/public/help-center" class="readMore">Read More</a>
            </div>
          </div>
        </div>
      </div>
      <div class="container-2">
        <div class="panel_box">
          <div class="image">
            <img src="assets/images/tenx/innerPage/signal/T2.png" alt="image" />
          </div>
          <div class="panel_content_wrap">
            <div class="panel_details">
              <div class="trending_wrap">
                <div class="trending">#Trending</div>
              </div>
              <h5>Ape Coin</h5>
              <p class="date">April 21, 2022</p>
              <p class="content">
                Ape Coin (APE) is a cryptocurrency intended to support the
                development of a decentralized web3
              </p>
              <a routerLink="/public/help-center" class="readMore">Read More</a>
            </div>
          </div>
        </div>
        <div class="panel_box">
          <div class="image">
            <img src="assets/images/tenx/innerPage/signal/T4.png" alt="image" />
          </div>
          <div class="panel_content_wrap">
            <div class="panel_details">
              <div class="trending_wrap">
                <div class="trending">#Trending</div>
              </div>
              <h5>Solana</h5>
              <p class="date">April 21, 2022</p>
              <p class="content">
                Solana is a public blockchain platform with smart contract
                functionality. Its native cryptocurrency is SOL.
              </p>
              <a routerLink="/public/help-center" class="readMore">Read More</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
