import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AuthGuard } from './core/auth.guard';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { OtpForgotPasswordComponent } from './forgot-password/otp-forgot-password/otp-forgot-password.component';
import { LoginComponent } from './login/login.component';
import { OtpVerificationComponent } from './otp-verification/otp-verification.component';
import { PermissionDeniedComponent } from './permission-denied/permission-denied.component';
import { PublicComponent } from './public/public.component';
import { RegistrationVerifyOtpComponent } from './registration-verify-otp/registration-verify-otp.component';
import { RegistrationComponent } from './registration/registration.component';
import { GlobalSignalCardComponent } from './shared/global-signal-card/global-signal-card.component';
import { SubscriptionPopupComponent } from './subscription-popup/subscription-popup.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./landing-page/landing-page.module').then(m => m.LandingPageModule)
  },
  {
    path: 'public', loadChildren: () => import('./public/public.module').then(m => m.PublicModule),
    component: PublicComponent,

  },
    {
    path: 'login', component: LoginComponent
  },
  {
    path: 'registration', component: RegistrationComponent
  },
  {
    path: 'registration/:id', component: RegistrationComponent
  },
  {
    path: 'otp-verification/:id/:id1', component: OtpVerificationComponent
  },
  {
    path: 'forgot-password', component: ForgotPasswordComponent
  },
  {
    path: 'otp-forgot-password/:id', component: OtpForgotPasswordComponent
  },
  {
    path: 'change-password/:id', component: ChangePasswordComponent
  },
  {
    path: 'registration-otp-verification', component: RegistrationVerifyOtpComponent
  },
  {
    path: 'permission-denied', component: PermissionDeniedComponent,
  },
  {
    path: 'subscription-popup', component: SubscriptionPopupComponent,
  },
  {
    path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard],
    component: AdminComponent,
    data: {
      breadcrumb: '',
    },
  },
  {
    path: 'global-signal-card', component: GlobalSignalCardComponent,
  },
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  { path :'**' , redirectTo: ''},
];

@NgModule({
  imports: [RouterModule.forRoot(routes , { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
