<div class="each_signal_card">
  <div class="card_inner">
    <div class="signal_card_left">
      <div class="card_left_top">
        <div class="card_data">
          <label
            [ngClass]="{ forex_heading: data?.card_type == 'Forex market' }"
            >On Binance</label
          >
          <span
            [ngClass]="{ forex_color: data?.card_type == 'Forex market' }"
            >{{ data.market }}</span
          >
        </div>
        <div class="card_data">
          <label
            [ngClass]="{ forex_heading: data?.card_type == 'Forex market' }"
            >Current Price</label
          >
          <span *ngIf="data?.card_type == 'Spot market' || data?.card_type =='Margin trade'"
            [ngClass]="{ forex_color: data?.card_type == 'Forex market' }"
            >{{ data.price }} BTC</span
          >
          <span *ngIf="data?.card_type == 'Forex market'"
          [ngClass]="{ forex_color: data?.card_type == 'Forex market' }"
          >{{ data.price }}</span
        >
        </div>
        <div class="card_data">
          <label
            [ngClass]="{ forex_heading: data?.card_type == 'Forex market' }"
            >Created On</label
          >
          <span
            [ngClass]="{ forex_color: data?.card_type == 'Forex market' }"
            >{{ data?.created_at | date : "medium" }}</span
          >
        </div>
        <div class="card_data bk_mrk">
          <button
            type="button"
            [ngClass]="{ future_bookmark: data?.card_type == 'Margin trade' }"
            class="bookmark_btn"
            (click)="add()"
          >
            <i
              [ngClass]="
                data?.watch_list_status != 1
                  ? 'fa-regular fa-bookmark'
                  : 'fa-solid fa-bookmark'
              "
            ></i>
          </button>
          <button
            type="button"
            class="trade_btn"
            placement="top"
            ngbTooltip="Coming Soon"
            tooltipClass="trade_tooltip"
          >
            Trade
          </button>
        </div>
      </div>
      <div class="card_status">
        <div
          class="each_status"
          *ngIf="data.card_type != 'Spot market' && data.position == 'Short'"
        >
          <span
            [ngClass]="{ forex_position: data?.card_type == 'Forex market' }"
            class="short"
            ><i class="fa-solid fa-sort-down"></i> {{ data.position }}</span
          >
        </div>
        <div
          class="each_status"
          *ngIf="data.card_type != 'Spot market' && data.position == 'Long'"
        >
          <span
            [ngClass]="{ forex_position: data?.card_type == 'Forex market' }"
            class="long"
            ><i class="fa-solid fa-sort-down"></i> {{ data.position }}</span
          >
        </div>
        <div class="each_status">
          <label>Risk:</label>
          <span [ngClass]="{ forex_risk: data?.card_type == 'Forex market' }">{{
            data.risk_status
          }}</span>
        </div>
        <div class="each_status" *ngIf="data.leverage != 0">
          <label>Leverage:</label>
          <span [ngClass]="{ forex_risk: data?.card_type == 'Forex market' }"
            >{{ data?.leverage }}px</span
          >
        </div>
        <div class="each_status">
          <label>Time Frame:</label>
          <span [ngClass]="{ forex_risk: data?.card_type == 'Forex market' }">{{
            data.time_frame
          }}</span>
        </div>
      </div>
      <div
        class="card_left_middle"
        [ngClass]="{ forex_bg: data?.card_type == 'Forex market' }"
      >
        <div class="calc_sec">
          <div class="each_calc entry">
            <label
              [ngClass]="{ forex_entry: data?.card_type == 'Forex market' }"
              >Entry:</label
            >
            <span [ngClass]="{ forex_entry: data?.card_type == 'Forex market' }"
              >{{ data.entry_from }} - {{ data.entry_to }}</span
            >
          </div>
          <div class="each_calc loss">
            <label>Stop Loss:</label>
            <span
              >{{ data.stop_value }} {{ data.quote_currency }} ({{
                data.stop_loss_percentage
              }}%)</span
            >
          </div>
        </div>
      </div>
      <div class="card_left_bottom">
        <div class="card_left_bottom_inner">
          <div class="label_block">
            <label>Targets:</label>
          </div>
          <div class="value_block">
            <div class="value_row">
              <div class="value_col">
                <div
                  class="each_value"
                  *ngIf="data.target_value_obj[0] != undefined"
                >
                  <label
                    [ngClass]="{
                      forex_target: data?.card_type == 'Forex market'
                    }"
                    >{{ data.target_value_obj[0]?.index + 1 }}</label
                  >
                  <span
                    [ngClass]="{
                      forex_target_value: data?.card_type == 'Forex market'
                    }"
                    >{{ data.target_value_obj[0]?.targetValue }}
                    <em
                      [ngClass]="{
                        forex_heading: data?.card_type == 'Forex market'
                      }"
                      >({{ data.target_value_obj[0]?.targetPercentage }}%)</em
                    ></span
                  >
                </div>
                <div
                  class="each_value"
                  *ngIf="data.target_value_obj[1] != undefined"
                >
                  <label
                    [ngClass]="{
                      forex_target: data?.card_type == 'Forex market'
                    }"
                    >{{ data.target_value_obj[1]?.index + 1 }}</label
                  >
                  <span
                    [ngClass]="{
                      forex_target_value: data?.card_type == 'Forex market'
                    }"
                    >{{ data.target_value_obj[1]?.targetValue }}
                    <em
                      [ngClass]="{
                        forex_heading: data?.card_type == 'Forex market'
                      }"
                      >({{ data.target_value_obj[1]?.targetPercentage }}%)</em
                    ></span
                  >
                </div>
              </div>
              <div class="value_col">
                <div
                  class="each_value"
                  *ngIf="data.target_value_obj[2] != undefined"
                >
                  <label
                    [ngClass]="{
                      forex_target: data?.card_type == 'Forex market'
                    }"
                    >{{ data.target_value_obj[2]?.index + 1 }}</label
                  >
                  <span
                    [ngClass]="{
                      forex_target_value: data?.card_type == 'Forex market'
                    }"
                    >{{ data.target_value_obj[2]?.targetValue }}
                    <em
                      [ngClass]="{
                        forex_heading: data?.card_type == 'Forex market'
                      }"
                      >({{ data.target_value_obj[2]?.targetPercentage }}%)</em
                    ></span
                  >
                </div>
                <div
                  class="each_value"
                  *ngIf="data.target_value_obj[3] != undefined"
                >
                  <label
                    [ngClass]="{
                      forex_target: data?.card_type == 'Forex market'
                    }"
                    >{{ data.target_value_obj[3]?.index + 1 }}</label
                  >
                  <span
                    [ngClass]="{
                      forex_target_value: data?.card_type == 'Forex market'
                    }"
                    >{{ data.target_value_obj[3]?.targetValue }}
                    <em
                      [ngClass]="{
                        forex_heading: data?.card_type == 'Forex market'
                      }"
                      >({{ data.target_value_obj[3]?.targetPercentage }}%)</em
                    ></span
                  >
                </div>
              </div>
            </div>
            <div class="calc_mobile_block">
              <div class="card_total_calc">
                <h3
                  [ngClass]="{
                    forex_total_percentage: data?.card_type == 'Forex market'
                  }"
                >
                  {{ data.total_percentage }}%
                </h3>
                <span
                  [ngClass]="{
                    forex_total_hrs: data?.card_type == 'Forex market'
                  }"
                  >{{ data.total_hours }} Hours</span
                >
                <ul class="signal_lines">
                  <li>
                    <span *ngIf="data.target_value_obj[0]?.targetValue != undefined"
                      [ngClass]="{'inactive': data?.barChanging == false}"></span><label
                      *ngIf="data.target_value_obj[0]?.targetValue != undefined">1</label>
                  </li>
                  <li>
                    <span *ngIf="data.target_value_obj[1]?.targetValue != undefined"
                      [ngClass]="{'inactive': data?.barChanging == false}"></span><label
                      *ngIf="data.target_value_obj[1]?.targetValue != undefined">2</label>
                  </li>
                  <li>
                    <span *ngIf="data.target_value_obj[2]?.targetValue != undefined"
                      [ngClass]="{'inactive': data?.barChanging == false}"></span><label
                      *ngIf="data.target_value_obj[2]?.targetValue != undefined">3</label>
                  </li>
                  <li>
                    <span *ngIf="data.target_value_obj[3]?.targetValue != undefined"
                      [ngClass]="{'inactive': data?.barChanging == false}"></span><label
                      *ngIf="data.target_value_obj[3]?.targetValue != undefined">4</label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="signal_card_right">
      <div class="card_total_calc">
        <h3
          [ngClass]="{
            forex_total_percentage: data?.card_type == 'Forex market'
          }"
        >
          {{ data.total_percentage }}%
        </h3>
        <span [ngClass]="{ forex_total_hrs: data?.card_type == 'Forex market' }"
          >{{ data.total_hours }} Hours</span
        >
        <ul class="signal_lines">
          <li>
            <span *ngIf="data.target_value_obj[0]?.targetValue != undefined"
              [ngClass]="{'inactive': data?.barChanging == false}"></span><label
              *ngIf="data.target_value_obj[0]?.targetValue != undefined">1</label>
          </li>
          <li>
            <span *ngIf="data.target_value_obj[1]?.targetValue != undefined"
              [ngClass]="{'inactive': data?.barChanging == false}"></span><label
              *ngIf="data.target_value_obj[1]?.targetValue != undefined">2</label>
          </li>
          <li>
            <span *ngIf="data.target_value_obj[2]?.targetValue != undefined"
              [ngClass]="{'inactive': data?.barChanging == false}"></span><label
              *ngIf="data.target_value_obj[2]?.targetValue != undefined">3</label>
          </li>
          <li>
            <span *ngIf="data.target_value_obj[3]?.targetValue != undefined"
              [ngClass]="{'inactive': data?.barChanging == false}"></span><label
              *ngIf="data.target_value_obj[3]?.targetValue != undefined">4</label>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
