import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { gsap } from 'gsap';
@Component({
  selector: 'app-n-signal-page',
  templateUrl: './n-signal-page.component.html',
  styleUrls: ['./n-signal-page.component.scss'],
})
export class NSignalPageComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {
   
  }
 
  homePage() {
    this.router.navigate(['/']);
  }
}
