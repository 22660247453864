import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChildren
} from '@angular/core';
import { ScrollTrigger } from 'gsap/all';
import { Router } from '@angular/router';
import { TweenLite, Power1, gsap } from "gsap";


@Component({
  selector: 'app-tenx-inner-page-layout',
  templateUrl: './tenx-inner-page-layout.component.html',
  styleUrls: ['./tenx-inner-page-layout.component.scss'],
})
export class TenxInnerPageLayoutComponent implements OnInit {
  animationLoader: any;
  @ViewChildren("fade1, fade2, fade3, fade4, fade5, fade6") Elements: any;

  constructor(private _router: Router,
    private elementRef: ElementRef) { }
  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    const body = document.querySelector('body') as HTMLElement;
    body.style.overflow = 'hidden';
    this.animationLoader = true;


    window.scrollTo(0, 0);
    //Tenx Title page transition Mobile
    let responsive = window.innerWidth;
    //first page title only for mobile section only for mobile
    if (responsive <= 768) {
      gsap.registerPlugin(ScrollTrigger);

      const panels = document.querySelectorAll('.svgtrigger');
      // Array.from(panels).forEach((e) => console.log(e.clientWidth));
      let panelContainerWidth = 0;
      Array.from(panels).forEach((a) => {
        panelContainerWidth += a.clientWidth;
      });

      let sections = gsap.utils.toArray('.svgtrigger');
      // console.log(sections)
      gsap.to(sections, {
        x: -panelContainerWidth + window.innerWidth,
        ease: 'none',
        scrollTrigger: {
          start: 'top top',
          trigger: '.mob-scroll-triger-tittle',
          pin: true,
          scrub: 0.1,
          end: panelContainerWidth,

        },
      });


    }

    setTimeout(() => {

      const panels = document.querySelectorAll('.T-panel');
      // Array.from(panels).forEach((e) => console.log(e.clientWidth));
      let panelContainerWidth = 0;
      Array.from(panels).forEach((a) => {
        panelContainerWidth += a.clientWidth;
      });

      //first page title scroll for web only
      if (responsive > 768) {
        gsap.registerPlugin(ScrollTrigger);

        //getting all horizontal pages with class
        let Sections = gsap.utils.toArray('.T-panel'),
          getTotalWidth = () => {
            let width = 0;
            Sections.forEach((el: any) => (width += el.offsetWidth));
            return width;
          };

        if (Sections.length) {

          //horizontal section scroll gsap
          gsap.to(Sections, {
            x: -panelContainerWidth + window.innerWidth,
            ease: 'none',
            scrollTrigger: {
              trigger: '#THorizontalWrapper',
              pin: true,
              start: 0,
              scrub: 0.5,
              end: panelContainerWidth,
            },
          });


          //pricing section gsap horizontal scroll
          gsap.to('#one', {
            xPercent: -50,
            ease: 'none',
            // delay: 2.8,
            scrollTrigger: {
              trigger: '.one',
              pin: true,
              scrub: 0.5,
              start: 'top top',
            },
          });


        }
      }
      this.animationLoader = false;
      body.style.overflow = 'auto';

    }, 1600)


  }



  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {
    this.Elements.forEach((element: any) => {
      var rect = element.nativeElement.getBoundingClientRect();
      if (rect.left < window.innerWidth && rect.right > 0) {
        // console.log(rect.left)
        TweenLite.to(element.nativeElement, {
          opacity: 1,
          duration: .3,
          delay: .5
        });
      }
    });
  }


  homePage() {
    this._router.navigate(['/']);
  }
  signIn() {
    this._router.navigate(['/user/login']);
  }
  ngOnDestroy() {
    this.elementRef.nativeElement.removeEventListener('click', this.onWindowScroll())

  }
}
