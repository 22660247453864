import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
@Component({
  selector: 'app-forex-trade-card',
  templateUrl: './forex-trade-card.component.html',
  styleUrls: ['./forex-trade-card.component.scss'],
})
export class ForexTradeCardComponent implements OnInit, OnChanges {
  constructor() {}
  showPlayIcon: boolean = true;
  @Input() tradingDetails?: any;
  getTradingDetails: any;
  ngOnInit(): void {}
  ngOnChanges(): void {
    this.getTradingDetails = this.tradingDetails?.listForexMarket;

  }
}
