import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { passwordUpperCaseValidator, passwordLowerCaseValidator, passwordNumberValidator, passwordSpecialCharecterValidator, passwordEmailValidator, ConfirmedValidator } from 'src/app/core/validators/validate.validators';
import { ApiService } from '../core/shared/api.service';
import { StorageService } from '../core/shared/storage.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  form!: FormGroup;
  public submitted: boolean = false;
  public password: boolean = false;
  public confirmPassword: boolean = false;
  constructor(private fb: FormBuilder, private _active: ActivatedRoute, private _apiservice: ApiService, private toastr: ToastrService, private router: Router, private storage: StorageService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      password: ['', [passwordUpperCaseValidator, passwordLowerCaseValidator, passwordNumberValidator, passwordSpecialCharecterValidator, Validators.minLength(8), passwordEmailValidator(atob(this._active.snapshot.params['id']))]],
      confirmPassword: ['', [Validators.required]],
      token: [this.storage.forgetPasswordToken],
    },
      {
        validator: ConfirmedValidator('password', 'confirmPassword'),
      })
  }

  get f() {
    return this.form.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.valid) {
      let data = {
        password: this.form.value.password,
        token: this.form.value.token
      }
      this._apiservice.httpPost('user/change-password', data).subscribe((res: any) => {
        this.toastr.success('Success', res.data);
        this.router.navigate([`/login`]);
      }, (err) => {
        this.toastr.error('error', err);
      })
    }
  }

  otpforgotpassword() {
    this.router.navigate([`/otp-forgot-password/${this._active.snapshot.params['id']}`]);
  }
}
