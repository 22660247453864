import { AbstractControl, FormControl, FormGroup, ValidationErrors } from "@angular/forms"

export const PasswordStrengthValidator = function (control: AbstractControl): ValidationErrors | null {

  let value: string = control.value || '';
  let msg = "";
  if (!value) {
    return null
  }

  let upperCaseCharacters = /[A-Z]+/g;
  let lowerCaseCharacters = /[a-z]+/g;
  let numberCharacters = /[0-9]+/g;
  let specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  if (upperCaseCharacters.test(value) === false || lowerCaseCharacters.test(value) === false || numberCharacters.test(value) === false || specialCharacters.test(value) === false) {
    return { passwordStrength: 'Contains both lower and upper case letters.Contains at least one number (0-9) or special characters' }
  }
  return null
}

export const passwordUpperCaseValidator = function (control: AbstractControl): ValidationErrors | null {
  let value: string = control.value || '';
  let msg = "";
  if (!value) {
    return null
  }
  let upperCaseCharacters = /[A-Z]+/g;
  if (upperCaseCharacters.test(value) === false) {
    return { upperCase: true }
  } else {
    return null
  }
}

export const passwordLowerCaseValidator = function (control: AbstractControl): ValidationErrors | null {
  let value: string = control.value || '';
  let msg = "";
  if (!value) {
    return null
  }
  let lowerCaseCharacters = /[a-z]+/g;
  if (lowerCaseCharacters.test(value) === false) {
    return { lowerCase: true }
  } else {
    return null
  }
}

export const passwordNumberValidator = function (control: AbstractControl): ValidationErrors | null {
  let value: string = control.value || '';
  let msg = "";
  if (!value) {
    return null
  }
  let numberCharacters = /[0-9]+/g;
  if (numberCharacters.test(value) === false) {
    return { numberCase: true }
  } else {
    return null
  }
}

export const passwordSpecialCharecterValidator = function (control: AbstractControl): ValidationErrors | null {
  let value: string = control.value || '';
  let msg = "";
  if (!value) {
    return null
  }
  let specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  if (specialCharacters.test(value) === false) {
    return { specialCase: true }
  } else {
    return null
  }
}

export const passwordEmailValidator = function (email: string): ValidationErrors | null {
  return (control: AbstractControl): ValidationErrors | null => {
    let value: string = control.value || '';
    let msg = "";
    if (!value) {
      return null
    }
    if (value === email) {
      return { emailCase: true }
    } else {
      return null
    }
  }
}

export const ConfirmedValidator = function (controlName: string, matchingControlName: string): ValidationErrors | null {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (
      matchingControl.errors &&
      !matchingControl.errors["confirmedValidator"]
    ) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmedValidator: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

export const passwordEmailValidator2 = function (password: string, email: string): ValidationErrors | null {
  return (formGroup: FormGroup): ValidationErrors | null => {
    const control = formGroup.controls[password];
    const matchingControl = formGroup.controls[email];
    if (!control.value) {
      return null;
    }
    if (control.value == matchingControl.value) {
      return { emailCase: true }
    } else {
      return null;
    }
  }
}

export class noWhitespaceValidator {
  static noWhiteSpace(control: AbstractControl): ValidationErrors | null {
    const isWhitespace = (control.value || '').trim().length === 0;
    return isWhitespace ? { whitespace: true } : null;
  };
}
export class onlyAlphabet {
  static isAlphabet(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value) {
      if (value.match(/^([^0-9]*)$/)) {
        return null;
      } else {
        return { nonAlphabetic: true };

      }
    }
    return null
  }
}
export class emailValidator {
  static isEmailValid(control: FormControl): ValidationErrors | null {
    const email = control.value;
    if(email){
    const domain = email?.split('@')[1];
    const domainParts = domain?.split('.');
    if (domainParts?.length > 2 || /\d/.test(domain) || /[^a-zA-Z.-]/.test(domain)) {
      return { invalidEmail: true };
    }
    }
    return null;
}
}

export class numberValidator{
  static validNumber(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (isNaN(value)) {
        return { invalidNumber: true };
    } else {
        return null;
    }
}
}