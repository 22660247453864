import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/core/shared/storage.service';

@Component({
  selector: 'app-spot-dailytips',
  templateUrl: './spot-dailytips.component.html',
  styleUrls: ['./spot-dailytips.component.scss'],
})
export class SpotDailytipsComponent implements OnInit {
  spotData: any = [
    { id: 1, active: true },
    { id: 2, active: false },
    { id: 3, active: false },
    { id: 4, active: false },
    { id: 5, active: false },
    { id: 6, active: false },
    { id: 7, active: false },
    { id: 8, active: false },
    { id: 9, active: false },
    { id: 10, active: false },
    { id: 11, active: false },
    { id: 12, active: false }
  ];

  slider: any = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
 
  intervalId: any;
  constructor(public storageService: StorageService) { }

  ngOnInit(): void {
    this.storageService.spotData = '1';
    this.automaticSlider();
  }
  ngOnDestroy(): void {
    this.clearSlider();
  }
  // automatic slider using call back function
  automaticSlider() {
    // automatic sliderr
    this.clearSlider();
    this.intervalId = setInterval(() => {
      const currentIndex = this.slider.findIndex(
        (x: any) => x === this.storageService.spotData
      );
      this.storageService.spotData =
        this.slider[currentIndex] === '12'
          ? this.slider[0]
          : this.slider[currentIndex + 1];
      // console.log('interval running');
    }, 4000);
  }
  clearSlider() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
  tab(data: any) {
    this.automaticSlider();
    if (data == 1) {
      this.storageService.spotData = '1';
    }
    if (data == 2) {
      this.storageService.spotData = '2';
    }
    if (data == 3) {
      this.storageService.spotData = '3';
    }
    if (data == 4) {
      this.storageService.spotData = '4';
    }
    if (data == 5) {
      this.storageService.spotData = '5';
    }
    if (data == 6) {
      this.storageService.spotData = '6';
    }
    if (data == 7) {
      this.storageService.spotData = '7';
    }
    if (data == 8) {
      this.storageService.spotData = '8';
    }
    if (data == 9) {
      this.storageService.spotData = '9';
    }
    if (data == 10) {
      this.storageService.spotData = '10';
    }
    if (data == 11) {
      this.storageService.spotData = '11';
    }
    if (data == 12) {
      this.storageService.spotData = '12';
    }
    
  }
}
