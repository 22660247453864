<div class="auth_wrapper">
  <section class="auth_header">
    <div class="container-fluid">
      <span class="auth_logo">
        <a [routerLink]="['']"><img src="assets/img/auth_logo.png" alt="Brand" /></a>
      </span>
    </div>
  </section>

  <section class="auth_content_wrap">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 auth_bg_dark">
          <div class="auth_left_bg">
            <span><img src="assets/img/auth_bg_img.png" alt="AuthBg" /></span>
          </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 auth_rht_bg">
          <div class="auth_form_box">
            <div class="auth_title_grp signin_title_grp">
              <button type="button" class="back_link">
                <i class="fa fa-arrow-left" (click)="otpforgotpassword()"></i>
              </button>
              <h1>Change password</h1>
              <span>Create a strong password for security</span>
            </div>
            <div class="auth_form_fill">
              <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="row">
                  <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                      <div class="form-floating">
                        <input [type]="password ? 'text' : 'password'" class="form-control" id="floatingEmail" placeholder="New Password"
                          formControlName="password" maxlength="30" />
                        <span class="text-danger small" *ngIf="submitted && f['password']?.errors?.['required']">New Password is
                          required</span>
                        <label for="floatingEmail">New Password</label>
                        <button type="button" class="password_tog">
                          <i class="fa-solid fa-eye" *ngIf="!password" (click)="password=!password"></i>
                          <i class="fa-solid fa-eye-slash" *ngIf="password" (click)="password=!password"></i>
                        </button>
                      </div>

                      <div class="password_req">
                        <ul>
                          <li>
                            <i class="fa-solid" [ngClass]="{'fa-times text-red': (f['password'].errors?.['minlength'] || f['password'].value ==''),'fa-check':(!f['password'].errors?.['minlength'] && f['password'].value!='')}"></i> Contains at least
                            8 characters
                          </li>
                          <li>
                            <i class="fa-solid" [ngClass]="{'fa-times text-red': (f['password'].errors?.['upperCase'] || f['password'].errors?.['lowerCase'] || f['password'].value ==''),'fa-check':(!f['password'].errors?.['upperCase'] && !f['password'].errors?.['lowerCase'] && f['password'].value!='')}"></i>
                            Contains both lower (a-z) and upper case letters
                            (A-Z)
                          </li>
                          <li>
                            <i class="fa-solid" [ngClass]="{'fa-times text-red': (f['password'].errors?.['numberCase'] || f['password'].errors?.['specialCase'] || f['password'].value ==''),'fa-check':(!f['password'].errors?.['numberCase'] && !f['password'].errors?.['specialCase'] && f['password'].value!='')}"></i>
                            Contains at least one number (0-9) and a symbol
                          </li>
                          <li>
                            <i class="fa-solid" [ngClass]="{'fa-times text-red': f['password'].errors?.['emailCase'] || f['password'].value =='','fa-check':!f['password'].errors?.['emailCase'] && f['password'].value!=''}"></i> Does not contain
                            your email address
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                      <div class="form-floating">
                        <input [type]="confirmPassword ? 'text' : 'password'" class="form-control" id="floatingEmail1" placeholder="Confirm New Password"
                          formControlName="confirmPassword" maxlength="30" />
                          <span class="text-danger small" *ngIf="submitted && f['confirmPassword']?.errors?.['required']">Confirm Password is
                            required</span>
                        <label for="floatingEmail1">Confirm New Password</label>
                        <button type="button" class="password_tog">
                          <i class="fa-solid fa-eye" *ngIf="!confirmPassword" (click)="confirmPassword=!confirmPassword"></i>
                          <i class="fa-solid fa-eye-slash" *ngIf="confirmPassword" (click)="confirmPassword=!confirmPassword"></i>
                        </button>
                      </div>
                      <div class="password_req">
                        <ul>
                          <li>
                            <i class="fa-solid" [ngClass]="{'fa-times text-red': (f['confirmPassword'].errors?.['confirmedValidator'] || f['confirmPassword'].value ==''),'fa-check':(!f['confirmPassword'].errors?.['confirmedValidator'] && f['confirmPassword'].value!='')}"></i> Password not matched
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <button type="submit" class="btn submit_btn signin_btn">
                      Save and Login
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>