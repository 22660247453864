<footer class="footer_wrapper">
  <div class="container-fluid">
    <!-- <div class="risk_txt">
      <p>
        <strong>Risk warning :</strong> Cryptocurrency trading is subject to
        high market risk. Please make your trades cautiously. TenX will make
        best efforts to choose high-quality signals but will not be responsible
        for your trading losses.
      </p>
    </div> -->

    <div class="footer_nav_block">
      <div class="row">
        <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
          <div class="footer_menus_wrp">
            <div class="footer_logo">
              <span class="logo_normal"
                ><a [routerLink]="['']"
                  ><img role="button" src="assets/img/auth_logo.png" /></a
              ></span>
              <span class="logo_black"
                ><a [routerLink]="['']"
                  ><img role="button" src="assets/img/black_logo.png" /></a
              ></span>
              <span class="logo_black_green"
                ><a [routerLink]="['']"
                  ><img
                    role="button"
                    src="assets/img/black_green_logo.png" /></a
              ></span>
            </div>
            <div class="footer_menus">
              <ul>
                <li>
                  <a [routerLink]="['/public/help-center']">Help Centre</a>
                </li>
                <li>
                  <a href="#">Terms and Conditions </a>
                </li>
                <li>
                  <a href="#">Privacy Policy </a>
                </li>
                <li>
                  <a [routerLink]="['/public/affiliate']">Affiliate </a>
                </li>
                <li>
                  <a [routerLink]="['/public/news']">News </a>
                </li>
                <li>
                  <a [routerLink]="['/public/blogs']">Blog </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
          <div class="social_media">
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/tenx.io?mibextid=LQQJ4d"
                  target="_blank"
                >
                  <i
                    class="fa-brands fa-facebook-f"
                    role="button"
                    aria-hidden="true"
                  ></i
                ></a>
              </li>
              <li>
                <a href="https://twitter.com/tenxgem" target="_blank">
                  <i
                    class="fa-brands fa-twitter"
                    role="button"
                    aria-hidden="true"
                  ></i
                ></a>
              </li>
              <li>
                <a
                  href="https://instagram.com/10xgem?igshid=YmMyMTA2M2Y="
                  target="_blank"
                >
                  <i
                    class="fa-brands fa-instagram"
                    role="button"
                    aria-hidden="true"
                  ></i
                ></a>
              </li>
              <li>
                <a href="https://t.me/tenxgem" target="_blank">
                  <i
                    class="fa-brands fa-telegram"
                    role="button"
                    aria-hidden="true"
                  ></i
                ></a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/@tenxgem/featured"
                  target="_blank"
                >
                  <i
                    class="fa-brands fa-youtube"
                    role="button"
                    aria-hidden="true"
                  ></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
