<div class="spot_market_wrapper">
  <div class="row content-wrapper">
    <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2 header_section">
      <div class="header">
        <h1>Forex Market Signal</h1>
        <p>
          Introducing our spot market signal cards - a new way of showing you
          all the details you need for your trades. Including where to buy,
          entry price, stop loss, and all targets connected to the real time
          API.
        </p>
      </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-10 col-xl-10 panel_section">
      <div class="spot_main_wrapper">
        <div class="spot_container forex_container">
          <app-forex-trade-card  [tradingDetails]="tradingDtls"></app-forex-trade-card>
        </div>
      </div>
    </div>
  </div>
</div>
