<ng-container *ngIf="group">
    <div *ngIf="group.errors && group.errors[validatorName] && (
            group.get(controlName).touched || group.get(controlName).dirty && group.get(controlName).pristine)" class="form-error-message">
            <ng-container *ngTemplateOutlet="tempOutlet"></ng-container>
    </div>
</ng-container>

<ng-container *ngIf="!group">
    <div *ngIf="control.errors && control.errors[validatorName] && (
            control.touched || control.dirty && control.pristine)" class="form-error-message" [ngClass]="{'login-bg' : isLogin}">
            <ng-container *ngTemplateOutlet="tempOutlet"></ng-container>
    </div>
</ng-container>
<ng-template #tempOutlet>
    <ng-content ></ng-content>
</ng-template>