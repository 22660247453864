<div class="daily_market_wrapper">
  <div class="row content-wrapper">
    <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2 header_section">
      <h1>Daily Market Analysis</h1>
      <p>
        For each signal card published, there is also a corresponding market
        analytics chart published so you can see how we came to the conclusion
        on our signal cards.
      </p>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-10 col-xl-10 panel_section">
      <div class="container-1">
        <div class="box">
          <div class="header_wrap">
            <div class="title_wrap">
              <h5>Trading View</h5>
              <p class="date">April 21, 2022</p>
            </div>
            <div class="button_wrap">
              <button>1NCHBUSD</button>
            </div>
          </div>
          <div class="image">
            <img
              src="assets/images/tenx/innerPage/signal/1.png"
              alt="image"
            />
          </div>
        </div>
        <div class="box">
          <div class="header_wrap">
            <div class="title_wrap">
              <h5>Trading View Chart</h5>
              <p class="date">April 21, 2022</p>
            </div>
            <div class="button_wrap">
              <button>1NCHBUSD</button>
            </div>
          </div>
          <div class="image">
            <img
              src="assets/images/tenx/innerPage/signal/2.png"
              alt="image"
            />
          </div>
        </div>
      </div>
      <div class="container-2">
        <div class="box">
          <div class="header_wrap">
            <div class="title_wrap">
              <h5>Now</h5>
              <p class="date">April 21, 2022</p>
            </div>
            <div class="button_wrap">
              <button>1NCHBUSD</button>
            </div>
          </div>
          <div class="image">
            <img
              src="assets/images/tenx/innerPage/signal/3.png"
              alt="image"
            />
          </div>
        </div>
        <div class="box">
          <div class="header_wrap">
            <div class="title_wrap">
              <h5>Chart-Created-12-04-2022</h5>
              <p class="date">April 21, 2022</p>
            </div>
            <div class="button_wrap">
              <button>1NCHBUSD</button>
            </div>
          </div>
          <div class="image">
            <img
              src="assets/images/tenx/innerPage/signal/4.png"
              alt="image"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
