import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/core/shared/api.service';
import { Location } from '@angular/common';
import { StorageService } from 'src/app/core/shared/storage.service';

@Component({
  selector: 'app-otp-forgot-password',
  templateUrl: './otp-forgot-password.component.html',
  styleUrls: ['./otp-forgot-password.component.scss']
})
export class OtpForgotPasswordComponent implements OnInit {
  @ViewChild('otpValue') otpValue: any
  public otpVerificationForm: any = FormGroup;
  public inputForm: any = FormGroup;
  submitted: boolean = false;
  input1: any;
  input2: any;
  input3: any;
  input4: any;
  input5: any;
  input6: any;
  disabledVerifyButton!: boolean;
  startingTime: any;
  disableResendButton!: boolean;

  constructor(
    private fb: FormBuilder,
    private _apiservice: ApiService,
    private toastr: ToastrService,
    private router: Router,
    private _active: ActivatedRoute,
    private _location: Location,
    private storage: StorageService
  ) { }

  ngOnInit(): void {
    this.otpVerificationForm = this.fb.group({
      email: [atob(this._active.snapshot.params['id'])],
      otp: ['', Validators.required],
      state: [1]
    })
    this.countDown();

  }

  countDown(){
    var countDownDate:any = new Date();
    countDownDate.setMinutes(countDownDate.getMinutes() + 2);
    // Update the count down every 1 second
    this.startingTime = setInterval( () => {

      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var time = countDownDate - now;

      // Time calculations for  minutes and seconds
      var minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((time % (1000 * 60)) / 1000);

      // Display the result in the element with id="demo"
      const y: any = document.getElementById("demo");
      y.innerHTML = minutes + "m " + seconds + "s ";

      // If the count down is finished, write some text
      if (minutes == 0 && seconds == 0) {
        clearInterval(this.startingTime);
        const z: any = document.getElementById("demo");
        z.innerHTML = "OTP Expired";
        this.disableResendButton = false;
      }
    }, 1000);
    setTimeout(() => {
      this.disabledVerifyButton = true;
    },120000) 
  }

  get f() {
    return this.otpVerificationForm.controls;
  }

  otpVerification() {
    this.submitted = true;
    this.otpVerificationForm.patchValue({otp: this.otpVerificationForm.value.otp});
    if (this.otpVerificationForm.valid) {
      this._apiservice.httpPostPublic('user/verify-login-otp', this.otpVerificationForm.value).subscribe((res) => {
        if (res) {
          this.storage.setLocalStorageData('token',res?.data?.loginResponse?.token);
          this.storage.setLocalStorageData('forgetPasswordToken',res?.data?.loginResponse?.forgetPasswordToken);
          this.toastr.success('', 'OTP successfully verified');
          clearInterval(this.startingTime);
          this.router.navigate([`/change-password/${btoa(this.f.email.value)}`]);
        }
      },(err: any) =>{
        if(err.error = 'Invalid OTP'){
          this.otpValue.setValue('');
          this.otpVerificationForm.reset();
        }
      })
      
    }
  }

  digitValidate(ele: any) {
    ele.value = ele.data.replace(/[^0-9]/g, '');
  }

  tabChange(val: any) {
    let ele = document.querySelectorAll('input');
    if (ele[val - 1].value != '') {
      ele[val]?.focus();
    }
    else if (ele[val - 1].value == '') {
      ele[val - 2]?.focus();
    }
  }

  resendOtp() {
    try {      
      this.otpValue.setValue('');
      this.otpVerificationForm.reset();

      this._apiservice.httpPostPublic('user/forgot-password', {email:this.f.email.value}).subscribe((res) => {
        if (res) {
          this.toastr.success('', 'OTP send');
          clearInterval(this.startingTime);
          this.disabledVerifyButton = false;
          this.disableResendButton = true;
          this.countDown();
        }
      })
    } catch (err: any) {
      this.toastr.error('error', err);
    }
  }

  backClicked() {
    this._location.back();
  }

  onOtpChange(e:any){
    this.otpVerificationForm.patchValue({otp: e})
  }

}
