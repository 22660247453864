import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tenx-block',
  templateUrl: './tenx-block.component.html',
  styleUrls: ['./tenx-block.component.scss']
})
export class TenxBlockComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
