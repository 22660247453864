<div class="auth_wrapper">
  <section class="auth_header">
    <div class="container-fluid">
      <span class="auth_logo">
        <a [routerLink]="['']"><img src="assets/img/auth_logo.png" alt="Brand" /></a>
      </span>
    </div>
  </section>

  <section class="auth_content_wrap">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 auth_bg_dark">
          <div class="auth_left_bg">
            <span><img src="assets/img/auth_bg_img.png" alt="AuthBg" /></span>
          </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 auth_rht_bg">
          <div class="auth_form_box">
            <div class="auth_title_grp signin_title_grp">
              <h1>Sign In</h1>
              <span>New User? <a [routerLink]="['/registration']">Create</a> an account </span>
            </div>
            <div class="auth_form_fill">
              <form [formGroup]=loginForm (keydown)="loginByEnter($event)">
                <div class="row">
                  <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                      <div class="form-floating">
                        <input type="text" class="form-control" id="floatingEmail" placeholder="Email Address"
                          formControlName="email" maxlength="30" />
                        <label for="floatingEmail">Email Address</label>
                      </div>
                    </div>
                  </div>

                  <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                      <div class="form-floating">
                        <input [type]="showPassword ? 'text' : 'password'" class="form-control" id="floatingPassword"
                          placeholder="Password" formControlName="password" name="password" maxlength="30" />

                        <label for="floatingPassword">Password</label>
                        <button type="button" class="password_tog">
                          <i alt="show" class="fa-solid fa-eye" *ngIf="!showPassword"
                            (click)="showPassword = !showPassword" [class.hide]="showPassword"></i>
                          <i alt="hide" class="fa-solid fa-eye-slash" *ngIf="showPassword"
                            (click)="showPassword = !showPassword" [class.hide]="!showPassword"></i>
                        </button>
                      </div>

                      <a class="frgt_passwrd" routerLink="/forgot-password">Forgot password?</a>
                    </div>
                  </div>
                  <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="chkbox_sec">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" [checked]="keepLoggedIn" id="flexCheckDefault" (click)="keepLogin(keepLoggedIn)" formControlName="checked"/>
                      </div>

                      <label>Keep me <a href="javascript:void(0)">Logged in</a> with Tenx
                        account</label>
                    </div>
                  </div>
                  <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <button type="button" class="btn submit_btn signin_btn" (click)="login()" [disabled]="!loginForm.valid">
                      Sign In
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>