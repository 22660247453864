<div class="spot_market_wrapper">
  <div class="row content-wrapper">
    <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2 header_section">
      <div class="header">
        <h1>Future Market Signal</h1>
        <p>
          A future market signal card is a signal card made specifically and
          only for the future market. A future market is where the purchase or
          sale of a cryptocurrency involves futures contracts that are legal
          agreements to buy or sell an asset at a future date at a predetermined
          price.
        </p>
      </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-10 col-xl-10 panel_section">
      <div class="spot_main_wrapper">
        <div class="spot_container future_container">
          <app-future-trade-card
            [tradingDetails]="tradingDtls"
          ></app-future-trade-card>
        </div>
      </div>
    </div>
  </div>
</div>
