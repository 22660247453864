import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/core/shared/storage.service';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
})
export class PaymentsComponent implements OnInit {
  tabData: any = [
    { id: 1, active: true },
    { id: 2, active: false },
    { id: 3, active: false },
  ];

  slider: any = ['first', 'second', 'third'];
  // imageActive: string;
  intervalId: any;
  constructor(public StorageService: StorageService,private router: Router) {}

  ngOnInit(): void {
    this.StorageService.tabData = 'first';
    this.automaticSlider();
  }
  ngOnDestroy(): void {
    this.clearSlider();
  }

  // automatic slider using call back function
  automaticSlider() {
    // automatic sliderr
    this.clearSlider();
    this.intervalId = setInterval(() => {
      const currentIndex = this.slider.findIndex(
        (x: any) => x === this.StorageService.tabData
      );
      this.StorageService.tabData =
        this.slider[currentIndex] === 'third'
          ? this.slider[0]
          : this.slider[currentIndex + 1];
      // console.log('interval running');
    }, 4000);
  }
  clearSlider() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
  tab(data: any) {
    this.automaticSlider();
    if (data == 1) {
      this.StorageService.tabData = 'first';
    }
    if (data == 2) {
      this.StorageService.tabData = 'second';
    }
    if (data == 3) {
      this.StorageService.tabData = 'third';
    }
  }
  navigation(e: any) {
    if(e == 'roadmap') {
      this.StorageService.waitlist.next(true);
      this.router.navigate(['/roadmap', 'notifyMe']);
    } else {
      this.router.navigate(['/public/help-center']);

    }
  
  }
}
