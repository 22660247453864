import { Component, OnInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { gsap } from 'gsap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-animation-page',
  templateUrl: './animation-page.component.html',
  styleUrls: ['./animation-page.component.scss']
})

export class AnimationPageComponent implements OnInit {
  animation = false;
  constructor(private el: ElementRef, private router: Router, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    let tl = gsap.timeline({ defaults: { ease: "power4.inOut", }, repeat: -1 });

    // 1st animation rectangles
    tl.to('.t_box', { scale: 1, zIndex: 4, stagger: 1, duration: 0.51, ease: "power3.inOut" }, '-=.2')
    tl.to('.e_box', { scale: 1, zIndex: 2, stagger: 1, duration: 0.20, ease: "power3.inOut" }, '-=.10')
    tl.to('.n_box', { scale: 1, zIndex: 3, stagger: 1, duration: 0.51, ease: "power3.inOut" }, '-=.20')
    tl.to('.x_box', { scale: 1, zIndex: 3, stagger: 1, duration: 0.19, ease: "power3.inOut" }, '-=.50')

    // // 2nd animation semi circle border
    tl.to('.t_box', { borderRadius: "128px", stagger: .1, duration: 0.01, ease: "power3.inOut" }, "=2")
    tl.to('.e_box', { borderRadius: "128px", stagger: .1, duration: 0.1, ease: "power3.inOut" })
    tl.to('.n_box', { borderRadius: "128px", stagger: .1, duration: 0.1, ease: "power3.inOut" })
    tl.to('.x_box', { borderRadius: "128px", stagger: .1, duration: 0.1, ease: "power3.inOut" })

    //  3rd animation vertical cylinder bar shape
    tl.set('.group', { flexDirection: "row", duration: 1, stagger: .5, ease: "power3.inOut" }, "=2")
    tl.to('.t_box', { borderRadius: "246px", width: "18%", height: "70%", left: "18%", duration: 0.01, stagger: .1, ease: "power3.inOut", })
    tl.to('.e_box', { borderRadius: "246px", width: "18%", height: "70%", left: "36%", yPercent: 45, duration: 0.1, stagger: .1, ease: "power3.inOut", })
    tl.to('.n_box', { borderRadius: "246px", width: "18%", height: "70%", left: "54%", yPercent: -20, duration: 0.1, stagger: .1, ease: "power3.inOut", })
    tl.to('.x_box', { borderRadius: "246px", width: "18%", yPercent: -45, left: "72%", duration: 0.1, stagger: .1, ease: "power3.inOut", })


    // // 4th animation vertical rectangle bar
    tl.set('.group', { flexDirection: "row", duration: 1, stagger: .5, ease: "power3.inOut" }, "=2")
    tl.to('.t_box', { borderRadius: 0, width: "18%", height: "60%", paddingTop: "0", left: "18%", duration: 0.01, stagger: .1, ease: "power3.inOut", })
    tl.to('.e_box', { borderRadius: 0, width: "18%", height: "75%", yPercent: 0, paddingTop: "0", left: "36%", duration: 0.1, stagger: .1, ease: "power3.inOut", })
    tl.to('.n_box', { borderRadius: 0, width: "18%", height: "60%", paddingTop: "0", left: "54%", yPercent: -20, duration: 0.1, stagger: .1, ease: "power3.inOut", })
    tl.to('.x_box', { borderRadius: 0, width: "18%", left: "72%", top: 0, height: "88%", yPercent: 0, paddingTop: "0", duration: 0.1, stagger: .1, ease: "power3.inOut", })

    // 5th animation semi circle and circle
    tl.set('.group', { flexDirection: "column", duration: 1, stagger: .5, ease: "power3.inOut" }, "=2")
    tl.to('.t_box', { borderRadius: "0px 0px 0px 181px", width: "40%", height: "50%", top: 0, left: 0, yPercent: 0, xPercent: 0, duration: 0.1, stagger: .1, ease: "power1.inOut" })
    tl.to('.e_box', { borderRadius: "0px 181px 0px 0px", width: "60%", height: "50%", top: 0, right: 0, yPercent: 0, xPercent: 0, duration: 0.1, stagger: .1, ease: "power1.inOut" })
    tl.to('.n_box', { borderRadius: "0px 181px 181px 0px", width: "60%", height: "50%", bottom: 0, left: 0, yPercent: 0, xPercent: 0, duration: 0.1, stagger: .1, ease: "power1.inOut" })
    tl.to('.x_box', { borderRadius: "50%", width: "40%", height: "50%", top: "50%", left: "60%", yPercent: 0, duration: 0.1, stagger: .1, ease: "power1.inOut" })

    // // // 6th animation circle shape

    tl.to('.t_box', { borderRadius: "50%", width: "35%", height: "44%", top: 0, left: 0, yPercent: 0, xPercent: 0, duration: 0.1, ease: "power1.inOut" }, "=2")
    tl.to('.e_box', { borderRadius: "50%", width: "55%", height: "70%", top: 0, right: 0, yPercent: 0, xPercent: 0, duration: 0.1, ease: "power1.inOut" })
    tl.to('.n_box', { borderRadius: "50%", width: "45%", height: "56%", bottom: "9%", left: 0, yPercent: 0, xPercent: 0, duration: 0.1, ease: "power1.inOut" })
    tl.to('.x_box', { borderRadius: "50%", width: "29%", height: "38%", top: "50%", left: "60%", duration: 0.1, stagger: .1, ease: "power1.inOut" })

    // // // // // re-setting flex-direction: column; of main div
    tl.set('.group', { flexDirection: "column", duration: 2, stagger: .5, ease: "power3.inOut" }, "=2")


    setTimeout(() => {
      // stop animation on hover
      let group = document.getElementById('group');
      group?.addEventListener('mouseover', () => tl.pause());
      group?.addEventListener('mouseout', () => tl.play())

    }, 2000)

  }


  getClickedBox(e: any) {

    //Gsap animation timeline
    let tl = gsap.timeline();

    if (e == 't_box') {
      //animating T box
      gsap.to('.svg', { padding: "7rem 0rem 7rem 2rem", duration: .6 })
      gsap.to('.e_box, .n_box, .x_box', { duration: .5, ease: "expo.out", scale: 0, display: "none" })
      gsap.to('.t_box, .group, .group_container', { duration: 1.05, ease: "expo.inOut", width: "100%", height: "100vh", })
      gsap.to('.group', { margin: 0, delay: .6 }) //mobile screen margin
      tl.to('.t_box', { left: 0, top: 0, yPercent: 0, padding: 0, background: "black", xPercent: 0, duration: .5, ease: "expo.inOut", borderRadius: 0 }, "=.5")

      // after expand animation navigating to exact inner page.
      setTimeout(() => {
        this.router.navigate(['/tenx']);
      }, 1600)

    } else if (e == 'e_box') {
      //animating E box
      gsap.to('.t_box, .n_box, .x_box', { duration: .5, ease: "expo.out", scale: 0, display: "none" })
      gsap.to('.e_box, .group, .group_container', { duration: 1.05, ease: "expo.inOut", width: "100%", height: "100vh", })
      gsap.to('.svg', { padding: "7rem 0rem 7rem 2rem", duration: .6  })
      gsap.to('.group', { margin: 0, delay: .6 }) //mobile screen margin
      tl.to('.e_box', { left: 0, top: 0, yPercent: 0, xPercent: 0, padding: 0, duration: .5, ease: "expo.inOut", borderRadius: 0 }, "=.5")


      // after expand animation navigating to exact inner page.
      setTimeout(() => {
        this.router.navigate(['/signal']);
      }, 1600)

    } else if (e == 'n_box') {
      //animating N box
      gsap.to('.t_box, .x_box, .e_box', { duration: .5, ease: "expo.out", scale: 0, display: "none" })
      gsap.to('.n_box, .group, .group_container', { duration: 1.05, ease: "expo.inOut", width: "100%", height: "100vh", })
      gsap.to('.svg', { padding: "7rem 0rem 7rem 2rem", duration: .6 })
      gsap.to('.group', { margin: 0, delay: .6 }) //mobile screen margin
      tl.to('.n_box', { left: 0, top: 0, yPercent: 0, xPercent: 0, padding: 0, duration: .5, ease: "expo.inOut", borderRadius: 0 }, "=.5")

      //  after expand animation navigating to exact inner page.
      setTimeout(() => {
        this.router.navigate(['/affiliate']);
      }, 1600)

    } else if (e == 'x_box') {
      //animating X box
      gsap.to('.t_box, .n_box, .e_box', { duration: .5, scale: 0, display: "none" })
      gsap.to('.x_box, .group, .group_container', { duration: 1.05, width: "100%", height: "100vh", })
      gsap.to('.svg', { padding: "7rem 0rem 7rem 2rem", duration: .6 })
      gsap.to('.group', { margin: 0, delay: .6 }) //mobile screen margin
      tl.to('.x_box', { left: 0, top: 0, yPercent: 0, xPercent: 0, padding: 0, duration: .5, borderRadius: 0 }, "=.5")

      // after expand animation navigating to exact inner page.
      setTimeout(() => {
        this.router.navigate(['/roadmap']);
        this.cdr.detectChanges();
      }, 1600)

    }
  }

  homePage() {
    this.router.navigate(['/']);
  }

}