import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { uDonate_abi, uDonate_address } from 'src/app/utility/abis';
import { ApiService } from '../core/shared/api.service';
import { SpinnerService } from '../core/shared/spinner.service';
import { environment } from 'src/environments/environment';
import Web3 from 'web3';
import { Router } from '@angular/router';
declare var window: any;
declare var web3: any;

//var Web3 = require('web3')
//declare var window: any;
//var web3: any;
let contract: any;


@Component({
  selector: 'app-subscription-popup',
  templateUrl: './subscription-popup.component.html',
  styleUrls: ['./subscription-popup.component.scss']
})
export class SubscriptionPopupComponent implements OnInit {
  price: any;
  discount: any;
  plan: any;
  subscriptionId: any = String;
  popupType: any;
  currentSubscriptionDate!: Date;

  addTransactionData: any = [];
  convertCurrencyName: any = String;
  convertCurrencyData: any = [];
  public payForm: any = FormGroup;
  env = environment;

  constructor(
    public activeModal: NgbActiveModal,
    private spinner: SpinnerService,
    private _apiService: ApiService,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.popupType = 'subscribe'
    this.convertCurrencyName = 'BNB'
    this.convertCurrency();

    this.payForm = this.fb.group({
      coinType: ['BNB'],
      convertedCurrency: [''],
    });
  }

  showPayPopup(type: any) {
    if (type == 'pay') {
      this.popupType = 'pay';
    }
    else if (type == 'thanks') {
      this.popupType = 'thanks';
    }

  }

  async connect() {
    // await Web3.currentProvider;
    await window.web3.currentProvider.enable();
    web3 = new Web3(window.web3.currentProvider);

    if (typeof web3 !== 'undefined') {
      web3 = new Web3(window.web3.currentProvider);
    }

    else {
      web3 = new Web3(new Web3.providers.HttpProvider("https://matic-testnet-archive-rpc.bwarelabs.com/"));
    }
    window.ethereum.enable()
      .then((account: any) => {
      })
    contract = new web3.eth.Contract(uDonate_abi, uDonate_address);
    console.log('contract', contract)
  }

  async deposit() {
    await this.connect();

    const data = {
      planMonth: this.plan,
      discount: this.discount,
      amount: this.price,
      subscriptionId: this.subscriptionId,
      date: this.datePipe.transform(this.currentSubscriptionDate, 'yyyy-MM-dd')
    }

    let apiUrl = 'payment-history/add'

    this._apiService.httpPost(apiUrl, data).subscribe((res) => {
      this.addTransactionData = res.data;
      // console.log('hitting1')



      //false when BNB
      contract.methods.getLatestPrice(false, 1).call( (err:any, res:any) => {
        if (err) {
          console.log("An error occurred", err)
          return
        }
        console.log("The price is: ", res)
        this.price = res;
      })

    // let data = {
    //   _amount : this.price,
    //   period: 1,
    //   referralID : '',
    //   usersOwnReferralId : '',
    //   subscriber :'',
    //   isBUSD : false
    // }
    //   contract.methods.subscription(this.price, 1, 11, false).send((err:any, res:any) => {
    //     if (err) {
    //       console.log("An error occurred", err)
    //       return
    //     }
    //     console.log("save: ", res)
    //   })

      web3.eth.getAccounts().then((account: any) => {
        this.spinner.show();
        // console.log('hitting2')

        return contract.methods.subscription(this.price, this.plan, 0, false).send({ from: account[0] });
      }).then((tmp: any) => {
        console.log('temp', tmp)
        this.popupType = 'thanks';
        this.spinner.hide();

        // const data = {
        //   id: this.addTransactionData.id,
        //   // transactionHash: '0xe9975702518c79caf81d5da65dea689dcac701fcdd063f848d4f03c85392fd00',
        //   transactionHash: tmp.transactionHash,
        //   paymentType: 'MetaMask',
        //   currency: this.convertCurrencyName,
        //   currencyValue: this.convertCurrencyData.amount_rate,
        //   wallet: tmp.from
        // }
        

        // let apiUrl = 'payment-history/update'

        // this._apiService.httpPost(apiUrl, data).subscribe((res) => {
        //   this.popupType = 'thanks';
        //   this.show_balance();

        // }, (err: any) => {
        //   this.popupType = 'error';
        //   return;
        // })

      }).catch(((tmp: any) => {
        this.popupType = 'error';
        this.spinner.hide();
      })
      );

    });

  }

  async withdraw() {
    var inputval = (<HTMLInputElement>document.getElementById("amount")).value;
    web3.eth.getAccounts().then((account: any) => {
      return contract.methods.withdraw(inputval).send({ from: account[0] });
    }).then((tmp: any) => {
      tmp
      this.show_balance();
    }).catch(((tmp: any) => {
      alert(tmp);
    }))
  }

  async show_balance() {
    contract.methods.balanceOf("0xf2FB2457784B4C4719f89C2A686d95C5fdBA5C80").call().then((balance: any) => {
      balance;
      // alert(balance);
    })
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.success('', 'Copy successfully')
  }

  convertCurrency() {
    let apiUrl = `convert/currency?from=USD&to=${this.convertCurrencyName}&amount=${this.price}`
    this._apiService.httpGet(apiUrl).subscribe((res) => {
      this.convertCurrencyData = res.data;
      console.log('convertCurrencyData', this.convertCurrencyData)
      this.payForm.patchValue({ convertedCurrency: `${parseFloat(this.convertCurrencyData.amount_rate).toFixed(2)} ${this.convertCurrencyName}` })

    })
  }

  selectCoinType() {
    if (this.payForm.value.coinType == 'BNB') {
      this.convertCurrencyName = 'BNB'
    } else if (this.payForm.value.coinType == 'BUSD') {
      this.convertCurrencyName = 'BUSD'
    }
    setTimeout(() => {
      this.convertCurrency();
    }, 1200)
  }

  dismissAll() {
    if (this.popupType == 'thanks') {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['public/subscription']);
      });
    };
    this.modalService.dismissAll();
  }

}
