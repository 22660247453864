import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/auth.service';
import { ApiService } from 'src/app/core/shared/api.service';

@Component({
  selector: 'app-modal-popup',
  templateUrl: './modal-popup.component.html',
  styleUrls: ['./modal-popup.component.scss']
})
export class ModalPopupComponent implements OnInit {
  modalType:any = String;
  accessUserId: any = String;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  constructor(
    private authService: AuthService,
    public activeModal: NgbActiveModal,
    private _apiService: ApiService,
    private modalService: NgbModal,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
  }

  logout(){
    let apiUrl = 'user/logout?'
    if(this.modalType == 'admin'){
      this.modalService.dismissAll();
      this._apiService.httpGet(apiUrl).subscribe((res) =>{
        this.authService.logOut();
      }) 
    }
    else if(this.modalType == 'user'){
      this.modalService.dismissAll();
      this._apiService.httpGet(apiUrl).subscribe((res) =>{
        this.authService.userLogOut();
      }) 
    }
  }

  unFreeAccess(){
    const data = {
      userId: Number(this.accessUserId),
      noExpiry: false
    }
    let apiUrl = 'admin/free-access'
    this._apiService.httpPost(apiUrl,data).subscribe((res) =>{
      this.toastr.success('','Update successfully')
      this.modalService.dismissAll();
    })
  }

  passBack() {
    this.passEntry.emit(this.accessUserId);
    this.modalService.dismissAll();
    }

}
