import { Component, Input, OnInit, OnChanges } from '@angular/core';

@Component({
  selector: 'app-forex-market',
  templateUrl: './forex-market.component.html',
  styleUrls: ['./forex-market.component.scss'],
})
export class ForexMarketComponent implements OnInit, OnChanges {
  tradingDtls: any;
  @Input() tradingDetails: any;
  constructor() {}
  ngOnInit(): void {}
  ngOnChanges(): void {
    this.tradingDtls = JSON.parse(this.tradingDetails? this.tradingDetails : null);
    console.log(this.tradingDtls);
  }
}
