<!-- Before Login  top navbar -->
<div id="navBeforeLogin" class="navigation_wrapper"  [ngClass]="navBackground()">
  <div class=" navigation_container container">
    <div class="logo">
      <img
        (click)="homePage()"
        style="cursor: pointer"
        [src]="logo()"
        alt="logo"
      />
    </div>
    <div class="signin">
      <a class="signin-background" [ngClass]="SignInBtnColor()" (click)="roadmapNotifyNavigation()">Join Waitlist</a>
    </div>
  </div>
  </div>

  <!-- AfterLogin screen nav only for mobile screen -->
  <!-- <div id="navAfterLogin_MobileOnly" class="navigation_wrapper"  [ngClass]="navBackground()">
    <div class="container">
      <div class=" navigation_container">
        <div class="logo">
          <img
            (click)="homePage()"
            style="cursor: pointer"
            [src]="logo()"
            alt="logo"
          />
        </div>
        <div class="signin">
          <img 
          *ngIf="commonMobileNavIcon"
          class="afterLogin_MobileNavIcon"
          (click)="toggleAfterLoginNavIcon()"
          style="cursor: pointer"
          [src]="DynamicMobileNavIcon()"
          alt="logo"
          />
          <img 
          *ngIf="roadmapMobileNavIcon"
          class="afterLogin_MobileNavIcon"
          (click)="toggleAfterLoginNavIcon()"
          style="cursor: pointer"
          [src]="DynamicMobileNavIconRoadmap()"
          alt="logo"
        />
        </div>
      </div>
    </div>
  </div> -->

  <!-- After login side navbar -->
  <!-- <app-sidebar id="afterLoginSideNav_webOnly"></app-sidebar> -->