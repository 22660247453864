import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LandingPageModule } from './landing-page/landing-page.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './registration/registration.component';
import { OtpVerificationComponent } from './otp-verification/otp-verification.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { RegistrationVerifyOtpComponent } from './registration-verify-otp/registration-verify-otp.component';
import { PermissionDeniedComponent } from './permission-denied/permission-denied.component';
import { OtpForgotPasswordComponent } from './forgot-password/otp-forgot-password/otp-forgot-password.component';
import { SubscriptionPopupComponent } from './subscription-popup/subscription-popup.component';
import { NgOtpInputModule } from 'ng-otp-input';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegistrationComponent,
    OtpVerificationComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    RegistrationVerifyOtpComponent,
    PermissionDeniedComponent,
    OtpForgotPasswordComponent,
    SubscriptionPopupComponent
  ],
  imports: [
    BrowserModule,

    NgbModule,
    BrowserAnimationsModule, 
    ToastrModule.forRoot(),
    HttpClientModule,
    NgxSpinnerModule,
    LandingPageModule,
    CommonModule,
    FormsModule,
    AppRoutingModule,
    SharedModule,
    NgOtpInputModule,
  ],
  providers: [CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
