<div class="auth_wrapper">
  <section class="auth_header">
    <div class="container-fluid">
      <span class="auth_logo">
        <a [routerLink]="['']"><img src="assets/img/auth_logo.png" alt="Brand" /></a>
      </span>
    </div>
  </section>

  <section class="auth_content_wrap">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 auth_bg_dark">
          <div class="auth_left_bg">
            <span><img src="assets/img/auth_bg_img.png" alt="AuthBg" /></span>
          </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 auth_rht_bg">
          <div class="auth_form_box">
            <div class="auth_title_grp signin_title_grp">
              <button type="button" class="back_link" routerLink="/login">
                <i class="fa fa-arrow-left"></i>
              </button>
              <h1>Forgot password</h1>
              <span>Please enter the email address that is associated with your
                Tenx account</span>
            </div>
            <div class="auth_form_fill">
              <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="row">
                  <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                      <div class="form-floating">
                        <input type="text" class="form-control" id="floatingEmail" placeholder="Email id"
                          formControlName="email" maxlength="30" />
                        <span class="text-danger small"
                          *ngIf="submitted && form?.controls?.email?.errors?.required">Email is
                          required</span>
                        <label for="floatingEmail">Email id</label>
                      </div>
                    </div>
                  </div>

                  <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <button type="submit" class="btn submit_btn signin_btn">
                      Continue
                    </button>
                    <span class="bk_txt">Back to <a routerLink="/login">Sign in</a></span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>