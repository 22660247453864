import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-daily-market-analysis',
  templateUrl: './daily-market-analysis.component.html',
  styleUrls: ['./daily-market-analysis.component.scss'],
})
export class DailyMarketAnalysisComponent implements OnInit {
  constructor() {}
  showPlayIcon: boolean = true;
  ngOnInit(): void {
  
  }
 
}
