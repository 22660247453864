import { Component, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { StorageService } from 'src/app/core/shared/storage.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalPopupComponent } from '../modal-popup/modal-popup.component';

@Component({
  selector: 'app-admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AdminSidebarComponent implements OnInit {
  activeUrl: String = '';
  headername: string = '';
  currentRoute: string;

  constructor(
    private storage: StorageService,
    private router: Router,
    private modalService: NgbModal
  ) {
    this.currentRoute = "";
    this.router.events.subscribe((event: any = Event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
        if (this.currentRoute.includes('subscription')) {
          this.activeUrl = 'subscription';
        }
        else if (this.currentRoute.includes('blog')) {
          this.activeUrl = 'blog';
        }
        else if (this.currentRoute.includes('help-center')) {
          this.activeUrl = 'help-center';
        }
        else if (this.currentRoute.includes('support')) {
          this.activeUrl = 'support';
        }
        else if (this.currentRoute.includes('user')) {
          this.activeUrl = 'user';
        }
        else if (this.currentRoute.includes('affiliate')) {
          this.activeUrl = 'affiliate';
        }
        else if (this.currentRoute.includes('signal-card')) {
          this.activeUrl = 'signal-card';
        }
      }
    });
  }

  ngOnInit(): void {
  }

  saveData(headername: any) {
    this.storage.setLocalStorageData('header-name', headername);
  }

  logout() {
    const modalRef = this.modalService.open(ModalPopupComponent);
    modalRef.componentInstance.modalType = 'admin';
  }
}
