import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/core/shared/api.service';
import { passwordEmailValidator, passwordLowerCaseValidator, passwordNumberValidator, passwordSpecialCharecterValidator, passwordEmailValidator2, passwordUpperCaseValidator, noWhitespaceValidator, emailValidator } from 'src/app/core/validators/validate.validators';
import { StorageService } from '../core/shared/storage.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  public registrationForm:any  = FormGroup
  submitted: boolean = false;
  public showPassword!: boolean;
  refferralId:any = String;
  key: any;

  constructor(
    private fb: FormBuilder,
    private _apiservice: ApiService,
    private toastr: ToastrService,
    private router: Router,
    private storage: StorageService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.refferralId = this.route.snapshot.params['id'];
    });
    console.log('refferralId',this.refferralId)
    this.registrationForm = this.fb.group({
      first_name: ['',[Validators.required,noWhitespaceValidator.noWhiteSpace, Validators.pattern('^[A-Za-z]+$')]],
      last_name: ['',[Validators.required, noWhitespaceValidator.noWhiteSpace, Validators.pattern('^[A-Za-z]+$')]],
      email: ['',[Validators.required,Validators.email, emailValidator.isEmailValid]],
      username: ['',[Validators.required, noWhitespaceValidator.noWhiteSpace, Validators.pattern('^[A-Za-z]+$')]],
      password: ['',[passwordUpperCaseValidator,passwordLowerCaseValidator,passwordNumberValidator,passwordSpecialCharecterValidator,Validators.minLength(8)]],
      referral_code: ['',Validators.required],
      checkbox: ['',Validators.required]
    },{
      validator: passwordEmailValidator2('password','email')
    })
    if(this.refferralId != undefined){
      this.registrationForm.patchValue({referral_code:this.refferralId})
    }
  }

  get f(){
    return this.registrationForm.controls;
  }

  registration(){
    this.submitted = true;
    if(this.registrationForm.invalid){
      return;
    }
    const data ={
      first_name: this.registrationForm.value.first_name.trim(),
      last_name: this.registrationForm.value.last_name.trim(),
      email: this.registrationForm.value.email.trim(),
      username: this.registrationForm.value.username.trim(),
      password: this.registrationForm.value.password,
      referral_code: this.registrationForm.value.referral_code
    }
    this.storage.setLocalStorageData('registrationEmailId',this.registrationForm.value.email);
    this._apiservice.httpPostPublic('user/register',data).subscribe((res) =>{
      if(res){
        this.submitted = false;
        this.toastr.success('', 'OTP send to email');
        setTimeout(() => {
          this.router.navigate(['/registration-otp-verification']);
        }, 1000);
      };
    });
  };

  onlyAlphabet(event: KeyboardEvent): boolean{
    const charcode = event.which ? event.which : event.keyCode;
    if (charcode > 64 &&  charcode < 91 || charcode > 96 && charcode < 123) {
      return true;
    }
    return false;
   }
  
}
