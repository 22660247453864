import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-future-trade-card',
  templateUrl: './future-trade-card.component.html',
  styleUrls: ['./future-trade-card.component.scss'],
})
export class FutureTradeCardComponent implements OnInit {
  @Input() tradingDetails: any;
  getTradingDetails: any;
  ngOnInit(): void {}
  ngOnChanges(): void {
    // console.log(this.tradingDetails);
    this.getTradingDetails = this.tradingDetails?.listFutureMarket;
    // console.log(this.getTradingDetails);
  }
}
