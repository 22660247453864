import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ScrollTrigger } from 'gsap/all';
import { ActivatedRoute, Router } from '@angular/router';
import {gsap, TweenLite} from 'gsap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/core/shared/api.service';
import { SpinnerService } from 'src/app/core/shared/spinner.service';
import { StorageService } from 'src/app/core/shared/storage.service';

@Component({
  selector: 'app-roadmap-inner-page-layout',
  templateUrl: './roadmap-inner-page-layout.component.html',
  styleUrls: ['./roadmap-inner-page-layout.component.scss'],
})
export class RoadmapInnerPageLayoutComponent implements OnInit, AfterViewInit {
  emailForm!: FormGroup;
  panelsLength: any;
  animationLoader: any;
  @ViewChild('notifyMeNavigation') notifyMeNavigation!: ElementRef;
  @ViewChildren("fade1, fade2, fade3") Elements: any;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private toaster: ToastrService,
    private ApiService: ApiService,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private store: StorageService,
    private elementRef: ElementRef
  ) {


  }
  ngOnInit(): void {

    this.createForm();

  }

  scroll() {
    // element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center', setTimeout: 1000 });
    this.notifyMeNavigation.nativeElement?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center', setTimeout: 1000 });

  }
  navigation() {
    this.router.navigate(['/public/help-center']);
  }

  ngAfterViewInit() {
    const body = document.querySelector('body') as HTMLElement;
    body.style.overflow = 'hidden';
    this.animationLoader = true;
    this.store.waitlist.subscribe((res: any) => {
      if (res) {

        this.scroll();

      }
    })

    
    // navigating to notifyMe section based on QueryParams

    this.route.params.subscribe((res: any) => {
      // console.log(res)
      setTimeout(() => {
        if (res.notifyMe == "notifyMe") {
          setTimeout(()=> {

            this.scroll();
          },1800)
        }
      }, 1000)

    })


    window.scrollTo(0, 0);
    //Tenx Title page transition for Mobile
    let responsive = window.innerWidth;


    if (responsive <= 768) {

      gsap.registerPlugin(ScrollTrigger);

      const panels = document.querySelectorAll('.svgtrigger');
      // Array.from(panels).forEach((e) => console.log(e.clientWidth));
      let panelContainerWidth = 0;
      Array.from(panels).forEach((a) => {
        panelContainerWidth += a.clientWidth;
      });

      let sections = gsap.utils.toArray('.svgtrigger');
      // console.log(sections)
      gsap.to(sections, {
        x: -panelContainerWidth + window.innerWidth,
        ease: 'none',
        scrollTrigger: {
          start: 'top top',
          trigger: '.mob-scroll-triger-tittle',
          pin: true,
          scrub: 0.1,
          end: panelContainerWidth,

        },
      });


    }


    setTimeout(() => {

    const panels = document.querySelectorAll('.R-panel');
    // Array.from(panels).forEach((e) => console.log(e.clientWidth));
    // console.log(Array.from(panels));
    let panelContainerWidth = 0;
    Array.from(panels).forEach((a) => {
      this.panelsLength = panelContainerWidth += a.clientWidth;

    });

      //gsap scroll for web only
      if (responsive > 768) {
        gsap.registerPlugin(ScrollTrigger);

        let Sections = gsap.utils.toArray('.R-panel'),
          getTotalWidth = () => {
            let width = 0;
            Sections.forEach((el: any) => (width += el.offsetWidth));
            return width;
          };

        // gsap.to(window, {duration: 2, scrollTo:"#notifymeRoadmap"});
        if (Sections.length) {
          gsap.to(Sections, {
            x: -panelContainerWidth + window.innerWidth,
            ease: 'none',
            scrollTrigger: {
              trigger: '#RHorizontalWrapper',
              pin: true,
              start: 0,
              scrub: 0.5,
              end: panelContainerWidth,
            },
          });
        }

      }
      this.animationLoader = false;
      body.style.overflow = 'auto';

    }, 1600)





  }

  //Form for notifyMe button
  createForm() {
    this.emailForm = this.fb.group({
      email: [
        '',
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),
      ],
    });
  }
  
  

  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {
    this.Elements.forEach((element: any) => {
      var rect = element.nativeElement.getBoundingClientRect();
      if (rect.left < window.innerWidth && rect.right > 0) {
        TweenLite.to(element.nativeElement,  {
          opacity: 1,
          duration: .3,
          delay: .5
        });
      } 
    });
  }

  notifyMe() {
    if (this.emailForm.valid) {

      const body = {
        email: this.emailForm.value.email,
      };
      this.spinner.show();
      this.ApiService.postRoadMapNotify(body).subscribe((res) => {
        this.toaster.success('Thank you', 'SUCCESS');
      });
      this.spinner.hide();
      this.emailForm.reset();
    } else if (this.emailForm.value.email == '') {
      this.toaster.error('Please Enter Email Address');
    } else {
      this.toaster.error('Please Enter Valid Email Address');
    }
  }


  homePage() {
    this.router.navigate(['/']);
  }

  signIn() {
    this.router.navigate(['/user/login']);
  }


  ngOnDestroy() {
    this.elementRef.nativeElement.removeEventListener('scroll', this.onWindowScroll())
  }
  
  
}
