<div class="block-wraper" >
    <div class="row">
        <div class="col-md-6 col-sm-12 col-xs-12 pr-0 paddingLeft section-a">
            <div class="row marginRight">
                <div class="col-md-12 pr-0 spacing spacing-tab-screen">
                    <div class="box daily-spot text-wrapBlack">
                        <h2>Daily spot market signals</h2>
                        <p>We monitor the market 24 hours per day to share exclusive<br>
                            spot market opportunities through our signal cards posted <br>
                            several times</p>
                            <a routerLink="/public/help-center">Learn More</a>

                    </div>

                </div>
                <div class="col-md-6 col-sm-6 col-6 paddingRight spacing spacing-tab-screen">
                    <div class="box daily-future text-wrapWhite ">
                        <h2>unique referal commision</h2>
                        <p>Earn up to 4 levels of commission through our unique referral marketing.</p>
                        <a routerLink="/public/help-center">Learn More</a>


                    </div>
                </div>
                <div class="col-md-6 col-sm-6 col-6 pr-0">
                    <div class="box daily-technical next text-wrapBlack">

                        <h2>Daily technical analysis</h2>
                        <p>Access our daily technical analysis charts published along with our daily signals cards.</p>
                        <a routerLink="/public/help-center">Learn More</a>


                    </div>
                </div>
            </div>

        </div>
        <div class="col-md-6 col-sm-12 col-xs-12 pl-0 pr-0 spacing section-b">
            <div class="row m-0">
                <div class="col-md-12 col-sm-12 col-xs-12 responsiveExc paddingRight spacing">
                    <div class="box daily-spot text-wrapBlack">
                        <h2>Exclusive insight</h2>
                        <p>Enter our decentralized social media platform built solely for crypto <br>
                            enthusiasts. Coming soon…</p>
                            <a routerLink="/public/help-center">Learn More</a>

                    </div>
                </div>
                <div class="col-md-6 col-sm-6 col-6 paddingRight spacing">
                    <div class="box daily-future text-wrapWhite">
                        <h2>Daily future market signals</h2>
                        <p>We monitor the market 24 hours per 
                            day to share exclusive future market 
                            opportunities through our signal
                             cards posted several times daily.</p>
                             <a routerLink="/public/help-center">Learn More</a>

                    </div>
                </div>
                <div class="col-md-6 col-sm-6 col-6 paddingRightres">
                    <div class="box next text-wrapBlack">
                        <h2>Find the next crypto gem</h2>
                        <p>Be one of the first to be notified of 
                            new Crypot Gems before they go 
                            viral as part of our exclusive 
                            content.</p>
                            <a routerLink="/public/help-center">Learn More</a>

                    </div>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12 responsiveExcnew">
                    <div class="box daily-spot text-wrapBlack">
                        <h2>Exclusive insight</h2>
                        <p>Enter our decentralized social media platform built solely for crypto <br>
                            enthusiasts. Coming soon…</p>
                            <a routerLink="/public/help-center">Learn More</a>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>