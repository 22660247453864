import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AffiliateInnerPageLayoutComponent } from './affiliate/affiliate-inner-page-layout/affiliate-inner-page-layout.component';
import { AnimationPageComponent } from './landing-animation/animation-page/animation-page.component';


import { RoadmapInnerPageLayoutComponent } from './roadmap/roadmap-inner-page-layout/roadmap-inner-page-layout.component';
import { SignalInnerPageLayoutComponent } from './signal/signal-inner-page-layout/signal-inner-page-layout.component';
import { TenxInnerPageLayoutComponent } from './tenx/tenx-inner-page-layout/tenx-inner-page-layout.component';

const routes: Routes = [

  {path: '', component: AnimationPageComponent},
  {path: 'tenx', component: TenxInnerPageLayoutComponent},
  {path: 'signal', component: SignalInnerPageLayoutComponent},
  {path: 'affiliate', component: AffiliateInnerPageLayoutComponent},
  {path: 'roadmap', component: RoadmapInnerPageLayoutComponent},
  {path: 'roadmap/:notifyMe', component: RoadmapInnerPageLayoutComponent},

];

@NgModule({
  // imports: [RouterModule.forChild(routes)],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LandingPageRoutingModule {}
