<div class="spot_market_wrapper">
  <div class="row content-wrapper">
    <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2 header_section">
      <div class="header">
        <h1>Spot Market Signal</h1>
        <p>
          A spot market signal card is a signal card made specifically and only
          for the spot market. A spot market is where the purchase or sale of a
          cryptocurrency is enacted as an immediate delivery, the transaction is
          committed in its entirety.
        </p>
      </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-10 col-xl-10 panel_section">
      <div class="spot_main_wrapper">
        <div class="spot_container">
          <app-spot-trade-card
            [tradingDetails]="tradingDtls"
          ></app-spot-trade-card>
        </div>
      </div>
    </div>
  </div>
</div>
