import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingPageRoutingModule } from './landing-page-routing.module';
import { AffiliateBlockComponent } from './affiliate/affiliate-block/affiliate-block.component';
import { AffiliateInnerPageLayoutComponent } from './affiliate/affiliate-inner-page-layout/affiliate-inner-page-layout.component';
import { VideoSectionComponent } from './affiliate/video-section/video-section.component';
import { AnimationPageComponent } from './landing-animation/animation-page/animation-page.component';
import { RoadmapInnerPageLayoutComponent } from './roadmap/roadmap-inner-page-layout/roadmap-inner-page-layout.component';
import { SignalInnerPageLayoutComponent } from './signal/signal-inner-page-layout/signal-inner-page-layout.component';
import { DailyMarketAnalysisComponent } from './signal/daily-market-analysis/daily-market-analysis.component';
import { FutureDailytipsComponent } from './signal/future-dailytips/future-dailytips.component';
import { FutureMarketComponent } from './signal/future-market/future-market.component';
import { FutureTradeCardComponent } from './signal/future-trade-card/future-trade-card.component';
import { ForexMarketComponent } from './signal/forex-market/forex-market.component';
import { SpotDailytipsComponent } from './signal/spot-dailytips/spot-dailytips.component';
import { SpotMarketComponent } from './signal/spot-market/spot-market.component';
import { SpotTradeCardComponent } from './signal/spot-trade-card/spot-trade-card.component';
import { ForexTradeCardComponent } from './signal/forex-trade-card/forex-trade-card.component';
import { ForexDailytipsComponent } from './signal/forex-dailytips/forex-dailytips.component';
import { PlansComponent } from './tenx/plans/plans.component';
import { TenxBlockComponent } from './tenx/tenx-block/tenx-block.component';
import { TenxInnerPageLayoutComponent } from './tenx/tenx-inner-page-layout/tenx-inner-page-layout.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TenxGemComponent } from './signal/tenx-gem/tenx-gem.component';
import { JoinNowComponent } from './tenx/join-now/join-now.component';
import { NSignalPageComponent } from './tenx/n-signal-page/n-signal-page.component';
import { PaymentsComponent } from './tenx/payments/payments.component';
import { SharedModule } from '../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AnimationPageComponent,
    AffiliateBlockComponent,
    AffiliateInnerPageLayoutComponent,
    VideoSectionComponent,
    RoadmapInnerPageLayoutComponent,
    SignalInnerPageLayoutComponent,
    DailyMarketAnalysisComponent,
    FutureDailytipsComponent,
    ForexDailytipsComponent,
    ForexMarketComponent,
    FutureMarketComponent,
    FutureTradeCardComponent,
    SpotDailytipsComponent,
    SpotMarketComponent,
    SpotTradeCardComponent,
    ForexTradeCardComponent,
    TenxGemComponent,
    JoinNowComponent,
    NSignalPageComponent,
    PaymentsComponent,
    PlansComponent,
    TenxBlockComponent,
    TenxInnerPageLayoutComponent,
  ],
  imports: [
    CommonModule,
    LandingPageRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    NgbModule,
  ],
})
export class LandingPageModule {}
