import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-global-signal-card',
  templateUrl: './global-signal-card.component.html',
  styleUrls: ['./global-signal-card.component.scss']
})
export class GlobalSignalCardComponent implements OnInit {
  @Input() data: any = [];
  @Output() addInWatchList = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
    const data = this.data.target_value_obj
    if(parseFloat(data[data.length-1].targetPercentage) >= parseFloat(this.data.total_percentage)){
      this.data.barChanging = true;
      }
      else{
        this.data.barChanging = false;
      }
  }

  add() {
    this.addInWatchList.emit(this.data);
  }

}
