<div class="page-wraper">
  <app-nav></app-nav>
  <div class="innerPage-wrapper">
    <div class="arrow-wraper">
      <!-- <img *ngIf="!sliderCount" class="arrowLeft" src="../../../../../assets/images/tenx/innerPage/sliderLeftarrow.svg" alt="" /> -->
      <!-- <img (click)="scrollToLeft()" class="arrowLeftActive" *ngIf="sliderCount" src="../../../../../assets/images/tenx/innerPage/sliderLeftarrow-Active.svg" alt="" /> -->
      <!-- <img   (click)="homePage()"  class="closeSlider" *ngIf="sliderCount" src="../../../../../assets/images/tenx/innerPage/closeSlider.svg" alt="" /> -->
    </div>

    <!-- <div  class="one" drag-scroll-item> <app-tenx-title></app-tenx-title></div>
    <div  class="one" drag-scroll-item> <app-join-now ></app-join-now></div>
    <div  class="one" drag-scroll-item> <app-block ></app-block></div>
    <div  class="one" drag-scroll-item><app-payments ></app-payments></div>
    <div  class="one" drag-scroll-item><app-plans ></app-plans></div> -->
  </div>
</div>
<div class="mob-scroll-triger-tittle wrap wrap-responsive">
  <div class="container2 imageWrap responsive">
    <div class="tenx-title">
      <svg
        class="svg svgtrigger"
        width="738"
        height="418"
        viewBox="0 0 738 418"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.295899 0.189977H143.366V54.34H98.9059V418H44.7559V54.34H0.295899V0.189977ZM187.131 0.189977H312.531V54.34H241.281V183.16H312.531V235.03H241.281V363.85H312.531V418H187.131V0.189977ZM464.013 235.03H464.583L462.303 148.96V0.189977H513.603V418H465.153L408.723 183.16H408.153L410.433 269.23V418H359.133V0.189977H407.583L464.013 235.03ZM737.043 418H681.183L640.143 277.21L598.533 418H543.243L610.503 194.56L551.793 0.189977H608.223L640.713 120.46L674.343 0.189977H728.493L669.783 194.56L737.043 418Z"
          fill="#CCFC50"
        />
      </svg>
    </div>
  </div>
</div>
<div class="page-wrap">
  <app-animation-loader
    *ngIf="animationLoader"
    class="loader"
  ></app-animation-loader>
  <div class="wrap">
    <div class="container1" id="THorizontalWrapper">
      <div class="T-panel tenx-title text-responsive">
        <h1>
          <img
            src="assets/images/tenx/svg/tenx.svg"
            alt="tenx"
          />
        </h1>
      </div>

      <div class="T-panel fade-in" #fade1>
        <app-join-now></app-join-now>
      </div>

      <div class="T-panel fade-in" #fade2>
        <app-tenx-block></app-tenx-block>
      </div>
      <div class="T-panel fade-in" #fade3>
        <div class="textWrap">
          <h1>
            Blockchain based <br /><span>and built on</span><br />
            BNB smartchain.
          </h1>
        </div>
      </div>
    </div>
    <div class="panel1">
      <app-payments></app-payments>
    </div>
    <div class="horizontal one" id="one" #one>
      <div class="protocol">
        <div class="bottomCards">
          <div class="leverage fade-in" #fade4>
            <div class="col-md-9 col-lg-7 col-xl-7">
              <h2>
                Leverage the <br />
                experience of <span>crypto experts</span>
              </h2>
            </div>
            <div class="col-md-8 col-lg-5 col-xl-5">
              <div class="sub">
                <p>
                  TenX is built for every level of ambition, trust<br />
                  the expertise of our pro-traders when making<br />
                  your decisions to trade.
                </p>
              </div>
            </div>
          </div>
          <div class="responsivePlans fade-in" #fade5>
            <div class="plans_wrapper">
              <div class="plans planOne">
                <!-- <img class="tabImg" src="../../../../../assets/images/tenx/innerPage/planTwo.svg" alt="" /> -->
                <div class="plan-data white">
                  <h3>Monthly</h3>
                  <h4>Subscription</h4>
                  <h5>$199<span>/Year</span></h5>
                </div>
              </div>
              <div class="plans planTwo">
                <!-- <img class="tabImg" src="../../../../../assets/images/tenx/innerPage/planThree.svg" alt="" /> -->
                <div class="plan-data white">
                  <h3>3 Month</h3>
                  <h4>Subscription</h4>
                  <h5>$179<span>/Year</span></h5>
                  <h6>$538</h6>
                  <span>10% DISCOUNT</span>
                </div>
              </div>
              <div class="plans planThree">
                <!-- <img class="tabImg" src="../../../../../assets/images/tenx/innerPage/planFour.svg" alt="" /> -->
                <div class="plan-data white gray">
                  <h3>6 Month</h3>
                  <h4>Subscription</h4>
                  <h5>$170<span>/Year</span></h5>
                  <h6>$1,194</h6>
                  <span>15% DISCOUNT</span>
                </div>
              </div>
              <div class="plans planFour">
                <!-- <img class="tabImg" src="../../../../../assets/images/tenx/innerPage/planOne.svg" alt="" /> -->
                <div class="plan-data white black">
                  <h3>12 Month</h3>
                  <h4>Subscription</h4>
                  <h5>$160<span>/Year</span></h5>
                  <h6>$2,388</h6>
                  <span>20% DISCOUNT</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="horizontal two" id="two">
      <app-plans></app-plans>
    </div>
  </div>
</div>
<app-landingpage-footer></app-landingpage-footer>
<!-- <div *ngIf="navigationTrigger" class="navigation-trigger" id="navTrigger"></div> -->
