import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { StorageService } from './shared/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private router: Router, private cookieService: CookieService, private storage: StorageService) { }

  get isLoggedIn(): boolean {
    let returnValue = false;
    const token = this.storage.token;
    if(token){
      returnValue = true;
      if(this.storage.role == '2'){
        this.router.navigate(['permission-denied']);
      };
    }
    return returnValue;
  }
  
  logOut(){
    this.cookieService.delete('login-email');
    this.cookieService.delete('login-password');
    localStorage.clear();
    return this.router.navigate(['auth/login']);
  }

  get isUserLoggedIn(): boolean {
    let returnValue = false;
    const token = this.storage.token;
    if(token){
      returnValue = true;
      if(this.storage.role == '1'){
        this.router.navigate(['permission-denied']);
      };
    }
    return returnValue;
  }

  userLogOut() {
    this.cookieService.delete('login-email');
    this.cookieService.delete('login-password');
    localStorage.clear();
    return this.router.navigate(['login']);
  }
}
