<div id="myModal" class="modal fade d-block opacity-100">
  <div
    class="modal-dialog modal-dialog-centered modal-confirm subscribe_modal"
    style="border: none !important;"
  >
    <div class="modal-content">
      <button
        type="button"
        class="close_btn"
        data-dismiss="modal"
        aria-hidden="true"
        (click)="dismissAll()"
      >
        <i class="fa-solid fa-xmark"></i>
      </button>
      <div class="modal-body">
        <ng-container *ngIf="popupType == 'subscribe'">
          <div class="title_grp">
            <h2>Subscribe Now</h2>
          </div>
          <div class="subscribe_content">
            <h1>${{price}}</h1>
            <span>Amount to be Paid</span>
          </div>
          <div class="payment_method">
            <button type="button" class="payment_option metamask_pay" (click)="showPayPopup('pay')">
              <img src="../../assets/img/payment/metamask.svg" alt="Metamask" />
              <span>Pay by Metamask</span>
              <i class="fa-solid fa-angle-right"></i>
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="popupType == 'pay'">
          <div class="title_grp">
            <h2>
              <img
                class="title_payment"
                src="../../assets/img/payment/bnb.svg"
                alt="BNB"
              />
              Pay by BNB/BUSD
            </h2>
          </div>
          <div class="subscribe_content">
            <div class="payemnt_form">
              <form [formGroup]="payForm">
                <div class="row">
                  <div
                    class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                  >
                    <div class="form-group">
                      <label>Select Coin</label>
                      <div class="cntrl_grp">
                        <div class="row">
                          <div
                            class="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                          >
                            <select class="form-control" formControlName="coinType" (click)="selectCoinType()">
                              <option value="BNB">BNB</option>
                              <option value="BUSD">BUSD</option>
                            </select>
                          </div>
                          <div
                            class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12"
                          >
                            <input type="text" class="form-control" formControlName="convertedCurrency" 
                            readonly/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                  >
                    <div class="form-group">
                      <label>Deposit to Network</label>
                      <input type="text" class="form-control" value="BSC BNB Smart Chain(BEP20)" 
                      readonly/>
                    </div>
                  </div>
                  <div
                    class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                  >
                    <div class="form-group">
                      <label>Address</label>
                      <div class="cntrl_grp">
                        <input
                          type="text"
                          class="form-control read_txt"
                          [value]="env.abiAddress"
                          readonly
                        />
                        <div class="btn_grp">
                          <button type="button" class="btn" (click)="copyMessage(env.abiAddress)">
                            <img
                              src="../../assets/img/payment/copy.svg"
                              alt="Copy"
                            />
                          </button>
                          <button type="button" class="btn">
                            <img
                              src="../../assets/img/payment/qrcode.svg"
                              alt="QrCode"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                  >
                    <button type="button" class="submit_btn" (click)="deposit()">Submit</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="popupType == 'thanks'">
          <div class="success_content">
            <div class="success_round">
              <span>
                <img src="../../assets/img/payment/tick.png" alt="Success" />
              </span>
            </div>
            <h2>Thanks for Subscription</h2>
            <p>Your payment has been successfully completed</p>
          </div>
        </ng-container>
        <ng-container *ngIf="popupType == 'error'">
          <div class="payment_fail_content">
            <div class="payment_fail_iconblock">
              <div class="fail_round">
                <span>
                  <img src="../../assets/img/payment/fail.png" alt="Fail" />
                </span>
              </div>
              <p>Your payment is not completed</p>
            </div>
            <div class="amount_details">
              <h2>${{price}}</h2>
              <span>Balance amount need to be Paid</span>
              <div class="address_box">
                <div class="form-group">
                  <label>Address</label>
                  <div class="cntrl_grp">
                    <input
                      type="text"
                      class="form-control read_txt"
                      [value]="env.abiAddress"
                      readonly
                    />
                    <div class="btn_grp">
                      <button type="button" class="btn" (click)="copyMessage(env.abiAddress)">
                        <img src="../../assets/img/payment/copy.svg" alt="Copy" />
                      </button>
                      <button type="button" class="btn">
                        <img
                          src="../../assets/img/payment/qrcode.svg"
                          alt="QrCode"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <p>
                Need Help? Contact us at
                <a href="mailto:support@tenx.io">support@tenx.io</a>
              </p>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
