<!-- <div id="myModal" class="modal fade d-block opacity-100">
  <div class="modal-dialog modal-confirm">
    <div class="modal-content">
      <div class="modal-header flex-column">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-hidden="true"
          (click)="activeModal.dismiss('Cross click')"
        >
          &times;
        </button>
      </div>
      <div class="modal-body">
        <p>Do you really want to logout?.</p>
      </div>
      <div class="modal-footer justify-content-center">
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          (click)="activeModal.dismiss('Cross click')"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-danger"
          (click)="logout(); activeModal.dismiss('Cross click')"
        >
          Logout
        </button>
      </div>
    </div>
  </div>
</div> -->

<div id="myModal" class="modal fade d-block opacity-100">
  <div
    class="modal-dialog modal-dialog-centered modal-confirm logout_modal_dialog"
    style="border: none !important"
  >
    <div class="modal-content">
      <button
        type="button"
        class="close_btn"
        data-dismiss="modal"
        aria-hidden="true"
        (click)="passBack();"
      >
        <i class="fa-solid fa-xmark"></i>
      </button>
      <ng-container *ngIf="modalType == 'admin' || modalType == 'user'">
        <div class="modal-body logout_modal_body">
          <span><img src="../../../assets/img/logout.png" alt="Logout" /></span>
          <h3>Are you sure you want to logout?</h3>
        </div>
        <div class="modal_footer">
          <div class="btn_grp">
            <button
              type="button"
              class="cancel_btn"
              data-dismiss="modal"
              (click)="activeModal.dismiss('Cross click')"
            >
              Cancel
            </button>
            <button
              type="button"
              class="logout_btn"
              (click)="logout();"
            >
              Logout
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="modalType == 'freeAccess'">
        <div class="modal-body logout_modal_body">
          <h3>Are you sure to revert the free access?</h3>
        </div>
        <div class="modal_footer">
          <div class="btn_grp">
            <button
              type="button"
              class="cancel_btn"
              data-dismiss="modal"
              (click)="passBack();"
            >
              Cancel
            </button>
            <button
              type="button"
              class="logout_btn"
              (click)="unFreeAccess()"
            >
              Ok
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
