import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';
import { forkJoin, throwError, Observable, BehaviorSubject, map, catchError, finalize } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from './spinner.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  isUnauthShown: boolean = false;
  token: any;
  role = this.storage.role;
  constructor(
    private http: HttpClient,
    private _toast: ToastrService,
    private spinner: SpinnerService,
    private storage: StorageService,
    private router: Router
  ) { }
  getToken() {
    this.token = this.storage.token;
    return this.token;
  }

  checkResponse(response: any): any {
    const results = response;
    if (results.success || results.status) {
      return results;
    } else {
      return { error: results.error };
    }
  }
  handleError(error: any): any {
    if (error.status === 404) {
      if (error.error === "Not Found") {
        return false
      }
    } else if (error.status === 412) {
      this._toast.error('', error.error.error);
    } else if (error.status === 400) {
      this._toast.error('', error.error.error);
    }else if (error.status === 500) {
      this._toast.error('', error.error.error);
    }
     else if (error.status === 401) {
      this._toast.error('', error.error.error);
      setTimeout(()=>{
        if(this.role === '2' ){
          this.router.navigate(['/login']);
        }
        else{
          this.router.navigate(['/auth/login']);
        }
      },1500)
    } else if (error.status === 403) {
      this._toast.error('', error.error.error);
    }
    else{
      this._toast.error('', 'Access Denied');
      setTimeout(()=>{
        if(this.role === '2' ){
          this.router.navigate(['/login']);
        }
        else{
          this.router.navigate(['/auth/login']);
        }
      },1500)
    }

    return error.error;
  }

  httpGet(url: string): Observable<any> {
    this.spinner.show();
    const Link = environment.baseUrl + url;
    const header = new HttpHeaders().set('Authorization', this.getToken());
    
    const headers = { headers: header };
    // debugger
    return this.http.get(Link, headers)
      .pipe(map(Response => this.checkResponse(Response)),
        catchError((error) => throwError(this.handleError(error))), finalize(() => this.spinner.hide()));
  }

  httpPatch(url: string, data: any): Observable<any> {
    this.spinner.show();
    const Link = environment.baseUrl + url;
    const header = new HttpHeaders().set('Authorization', this.getToken());
    const headers = { headers: header };
    return this.http.patch(Link, data, headers)
      .pipe(map(Response => this.checkResponse(Response)),
        catchError((error) => throwError(this.handleError(error))), finalize(() => this.spinner.hide()));
  }

  httpDel(url: string): Observable<any> {
    this.spinner.show();
    const Link = environment.baseUrl + url;
    const header = new HttpHeaders().set('Authorization', this.getToken());
    const headers = { headers: header };
    return this.http.delete(Link, headers)
      .pipe(map(Response => this.checkResponse(Response)),
        catchError((error) => throwError(this.handleError(error))), finalize(() => this.spinner.hide()));
  }

  httpPost(url: string, data: any): Observable<any> {
    this.spinner.show();
    const Link = environment.baseUrl + url;
    const header = new HttpHeaders().set('Authorization', this.getToken());
    const headers = { headers: header };
    return this.http.post(Link, data, headers)
      .pipe(map(Response => this.checkResponse(Response)),
        catchError((error) => throwError(this.handleError(error))), finalize(() => this.spinner.hide()));
  }

  httpPostPublic(url: string, data: any): Observable<any> {
    this.spinner.show();
    const Link = environment.baseUrl + url;
    return this.http.post(Link, data)
      .pipe(map(Response => this.checkResponse(Response)),
        catchError((error) => throwError(this.handleError(error))), finalize(() => this.spinner.hide()));
  }

  httpGetLanding(url: string): Observable<any> {
    this.spinner.show();
    const Link = environment.baseUrl + url;
    const header = new HttpHeaders().set('Authorization', this.getToken());
    const headers = { headers: header };
    return this.http.get(Link, headers)
      .pipe(map(Response => this.checkResponse(Response)),
        catchError((error) => throwError(this.handleError(error))), finalize(() => this.spinner.hide()));
  }

  httpGetPublicLanding(url: string): Observable<any> {
    this.spinner.show();
    const Link = environment.baseUrl + url;
    return this.http.get(Link)
      .pipe(map(Response => this.checkResponse(Response)),
        catchError((error) => throwError(this.handleError(error))), finalize(() => this.spinner.hide()));
  }

  httpPut(url: string, data: any): Observable<any> {
    this.spinner.show();
    const Link = environment.baseUrl + url;
    const header = new HttpHeaders().set('Authorization', this.getToken());
    const headers = { headers: header };
    return this.http.put(Link, data, headers).pipe(
      map((Response) => this.checkResponse(Response)),
      catchError((error) => throwError(this.handleError(error))), finalize(() => this.spinner.hide()));
  }

  fileUpload(url: string, data: any) {
    this.spinner.show();
    const Link = environment.baseUrl + url;
    const header = new HttpHeaders().set('Authorization', this.getToken());
    const headers = { headers: header };
    return this.http
      .post(Link, data, headers)
      .pipe(
        map((Response) => this.checkResponse(Response)),
        catchError((error) => throwError(this.handleError(error))), finalize(() => this.spinner.hide()))
  }

  postRoadMapNotify(body: any) {
    const Link = environment.baseUrl + `roadmap/notify`;
    return this.http.post(Link, body).pipe(
      map((Response) => this.checkResponse(Response)),
      catchError((error) => throwError(this.handleError(error)))
    );
  }
  getSpotTradingDetails() {
    const Link = environment.baseUrl + `card/spotsignal?pageNo=1&pageSize=6`;
    return this.http.get(Link).pipe(
      map((Response) => this.checkResponse(Response)),
      catchError((error) => throwError(this.handleError(error)))
    );
  }
  getFutureTradingDetails() {
    const Link = environment.baseUrl + `card/futuresignal?pageNo=1&pageSize=6`;
    return this.http.get(Link).pipe(
      map((Response) => this.checkResponse(Response)),
      catchError((error) => throwError(this.handleError(error)))
    );
  }
  getForexTradingDetails() {
    const Link = environment.baseUrl + `card/forexsignal?pageNo=1&pageSize=6`;
    return this.http.get(Link).pipe(
      map((Response) => this.checkResponse(Response)),
      catchError((error) => throwError(this.handleError(error)))
    );
  }
}