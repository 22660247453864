import {
  AfterViewInit,
  Component,
  HostListener,
  OnInit,
  ViewChild,
  ViewChildren,

} from '@angular/core';
import { ScrollTrigger, } from 'gsap/all';
import { Router } from '@angular/router';
import { TweenLite, Power1,gsap } from "gsap";
import { StorageService } from 'src/app/core/shared/storage.service';

@Component({
  selector: 'app-affiliate-inner-page-layout',
  templateUrl: './affiliate-inner-page-layout.component.html',
  styleUrls: ['./affiliate-inner-page-layout.component.scss'],
})
export class AffiliateInnerPageLayoutComponent implements OnInit, AfterViewInit {
  animationLoader: any;
  @ViewChildren("fade1, fade2, fade3") Elements: any;
  constructor(private router: Router, private storage: StorageService) { }
  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
  
    this.animationLoader = true;
    const body = document.querySelector('body') as HTMLElement;
    body.style.overflow = 'hidden';

   

    window.scrollTo(0, 0);

    //Tenx Title page transition for Mobile
    let responsive = window.innerWidth;


    if (responsive <= 768) {

      gsap.registerPlugin(ScrollTrigger);

      const panels = document.querySelectorAll('.svgtrigger');
      // Array.from(panels).forEach((e) => console.log(e.clientWidth));
      let panelContainerWidth = 0;
      Array.from(panels).forEach((a) => {
        panelContainerWidth += a.clientWidth;
      });

      let sections = gsap.utils.toArray('.svgtrigger');
      // console.log(sections)
      gsap.to(sections, {
        x: -panelContainerWidth + window.innerWidth,
        ease: 'none',
        scrollTrigger: {
          start: 'top top',
          trigger: '.mob-scroll-triger-tittle',
          pin: true,
          scrub: 0.1,
          end: panelContainerWidth,

        },
      });

    }

    setTimeout(() => {

    const panels = document.querySelectorAll('.A-panel');
    // Array.from(panels).forEach((e) => console.log(e.clientWidth));
    let panelContainerWidth = 0;
    Array.from(panels).forEach((a) => {
      panelContainerWidth += a.clientWidth;

    });
    // console.log('Horizontal Wraper Width:' + panelContainerWidth);

    this.storage.tabData = 'first';

      if (responsive > 768) {
        gsap.registerPlugin(ScrollTrigger);

        let Sections = gsap.utils.toArray('.A-panel'),
          getTotalWidth = () => {
            let width = 0;
            Sections.forEach((el: any) => (width += el.offsetWidth));
            return width;
          };
        if (Sections.length) {
          gsap.to(Sections, {
            x: -panelContainerWidth + window.innerWidth,
            ease: 'none',
            scrollTrigger: {
              trigger: '#AHorizontalWrapper',
              pin: true,
              start: 0,
              scrub: 0.5,
              end: panelContainerWidth,
            },
          });
        }
        
      }
      this.animationLoader = false;
    body.style.overflow = 'auto';
      
    },1600)


  }

  
  
  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {
    this.Elements.forEach((element: any) => {
      var rect = element.nativeElement.getBoundingClientRect();
      if (rect.left < window.innerWidth && rect.right > 0) {
        // console.log(rect.left)
        TweenLite.to(element.nativeElement,  {
          opacity: 1,
          duration: .3,
          delay: .5
        });
      }
    });
  }

  roadmapNotifyNavigation() {
    this.storage.waitlist.next(true);
    // this.router.navigate(['/roadmap#notifyMe']);
    this.router.navigate(['/roadmap', 'notifyMe']);
  }
  

  homePage() {
    this.router.navigate(['/']);
  }

  signIn() {
    this.router.navigate(['/user/login']);
  }


  ngOnDestroy() {
    // window.removeEventListener('scroll', this.viewPort);
  }
}
