<div class="auth_wrapper">
    <section class="auth_header">
        <div class="container-fluid">
            <span class="auth_logo">
                <a [routerLink]="['']"><img src="assets/img/auth_logo.png" alt="Brand" /></a>
            </span>
        </div>
    </section>

    <section class="auth_content_wrap">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 auth_bg_dark">
                    <div class="auth_left_bg">
                        <span><img src="assets/img/auth_bg_img.png" alt="AuthBg" /></span>
                    </div>
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 auth_rht_bg">
                    <div class="auth_form_box">
                        <div class="auth_title_grp signin_title_grp">
                            <button type="button" class="back_link" (click)="backClicked()">
                                <i class="fa fa-arrow-left"></i>
                            </button>
                            <h1>Verify OTP</h1>
                            <span>Please enter the OTP sent to your email address</span>
                        </div>
                        <div class="auth_form_fill">
                            <form>
                                <div class="row">
                                    <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div class="form-group">
                                            <!-- <div class="otp_txtbox">
                                                <input type="text" class="otp_txt" maxlength="1" [(ngModel)]="input1"
                                                    name="first" (input)='digitValidate($event)'
                                                    (keyup)="tabChange(1)" />
                                                <input type="text" class="otp_txt" maxlength="1" [(ngModel)]="input2"
                                                    name="second" (input)='digitValidate($event)'
                                                    (keyup)="tabChange(2)" />
                                                <input type="text" class="otp_txt" maxlength="1" [(ngModel)]="input3"
                                                    name="third" (input)='digitValidate($event)'
                                                    (keyup)="tabChange(3)" />
                                                <input type="text" class="otp_txt" maxlength="1" [(ngModel)]="input4"
                                                    name="fourth" (input)='digitValidate($event)'
                                                    (keyup)="tabChange(4)" />
                                                <input type="text" class="otp_txt" maxlength="1" [(ngModel)]="input5"
                                                    name="fifth" (input)='digitValidate($event)'
                                                    (keyup)="tabChange(5)" />
                                                <input type="text" class="otp_txt" maxlength="1" [(ngModel)]="input6"
                                                    name="sixth" (input)='digitValidate($event)'
                                                    (keyup)="tabChange(6)" />
                                                <span class="text-danger small"
                                                    *ngIf="submitted && f?.otp?.errors?.required">OTP is
                                                    required</span>
                                            </div> -->
                                            
                                            <ng-otp-input class="otp_txt" #otpValue (onInputChange)="onOtpChange($event)" (input)='digitValidate($event)'  [config]="{length:6}"></ng-otp-input>
                                            <p class="timer_p">
                                                <span class="timer" id="demo"></span>
                                            </p>
                                        </div>
                                    </div>

                                    <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <button type="button" class="btn submit_btn signin_btn"
                                            (click)="otpVerification()" [disabled]="disabledVerifyButton">
                                            Verify
                                        </button>
                                        <span class="rsend">Didn't get OTP? <button class="resend_btn" (click)="resendOtp()" [disabled]="disableResendButton">Resend
                                                OTP</button></span>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>