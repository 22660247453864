<div class="auth_wrapper">
  <section class="auth_header">
    <div class="container-fluid">
      <span class="auth_logo">
        <a [routerLink]="['']"><img src="assets/img/auth_logo.png" alt="Brand" /></a>
      </span>
    </div>
  </section>

  <section class="auth_content_wrap">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 auth_bg_dark">
          <div class="auth_left_bg">
            <span><img src="assets/img/auth_bg_img.png" alt="AuthBg" /></span>
          </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 auth_rht_bg">
          <div class="auth_form_box">
            <div class="auth_title_grp">
              <h1>Create an account</h1>
              <span>Already have an account? <a href="/login">Sign In</a></span>
            </div>
            <div class="auth_form_fill">
              <form [formGroup]="registrationForm">
                <div class="row">
                  <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                      <div class="form-floating">
                        <input type="text" class="form-control" id="floatingEmail" placeholder="Email Address"
                          formControlName="email" maxlength="30" />
                        <div class="errors text-danger small"
                          *ngIf="registrationForm.get('email').invalid && (registrationForm.get('email').touched || registrationForm.get('email').dirty)">
                          <span class="text-danger small"
                            *ngIf="registrationForm.get('email').hasError('invalidEmail') || registrationForm.get('email').hasError('email')">Enter a valid
                            email</span>
                        </div>

                        <label for="floatingEmail">Email Address</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <div class="form-group">
                      <div class="form-floating">
                        <input type="text" class="form-control" id="floatingFname" placeholder="First Name"
                          formControlName="first_name" maxlength="30" (keypress)="onlyAlphabet($event)"/>

                        <label for="floatingFname">First Name</label>
                        <div *ngIf="
                            f?.first_name.invalid &&
                            (f?.first_name.dirty || f?.first_name.touched)
                          ">
                          <div class="text-danger small" *ngIf="f?.first_name?.errors?.['required']">
                            First name is required.
                          </div>
                          <div class="text-danger small" *ngIf="f?.first_name?.errors?.['whitespace']">
                            Whitespaces not allowed.
                          </div>
                          <div class="text-danger small" *ngIf="f?.first_name?.errors?.['pattern']">
                            Only alphabets are allowed.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <div class="form-group">
                      <div class="form-floating">
                        <input type="text" class="form-control" id="floatingLname" placeholder="Last Name"
                          formControlName="last_name" maxlength="30" (keypress)="onlyAlphabet($event)"/>

                        <label for="floatingLname">Last Name</label>
                        <div *ngIf="
                        f?.last_name.invalid &&
                        (f?.last_name.dirty || f?.last_name.touched)
                      ">
                          <div class="text-danger small" *ngIf="f?.last_name?.errors?.['required']">
                            Last name is required.
                          </div>
                          <div class="text-danger small" *ngIf="f?.last_name?.errors?.['whitespace']">
                            Whitespaces not allowed.
                          </div>
                          <div class="text-danger small" *ngIf="f?.first_name?.errors?.['pattern']">
                            Only alphabets are allowed.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                      <div class="form-floating">
                        <input type="text" class="form-control" id="floatingUsername" placeholder="Username"
                          formControlName="username" maxlength="30" (keypress)="onlyAlphabet($event)"/>

                        <label for="floatingUsername">Username</label>
                        <div *ngIf="
                        f?.username.invalid &&
                        (f?.username.dirty || f?.username.touched)
                      ">
                          <div class="text-danger small" *ngIf="f?.username?.errors?.['required']">
                            Username is required.
                          </div>
                          <div class="text-danger small" *ngIf="f?.username?.errors?.['whitespace']">
                            Whitespaces not allowed.
                          </div>
                          <div class="text-danger small" *ngIf="f?.first_name?.errors?.['pattern']">
                            Only alphabets are allowed.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                      <div class="form-floating">
                        <input type="text" class="form-control" id="floatingPassword" placeholder="Password"
                          formControlName="password" [type]="showPassword ? 'text' : 'password'" maxlength="30" />
                        <label for="floatingPassword">Password</label>
                        <button type="button" class="password_tog">
                          <i class="fa-solid fa-eye" *ngIf="!showPassword" (click)="showPassword = !showPassword"
                            [class.hide]="showPassword"></i>
                          <i class="fa-solid fa-eye-slash" *ngIf="showPassword" (click)="showPassword = !showPassword"
                            [class.hide]="!showPassword"></i>
                        </button>
                      </div>

                      <div class="password_req">
                        <ul>
                          <li>
                            <i class="fa-solid"
                              [ngClass]="{'fa-times text-red': (f['password'].errors?.['minlength'] || f['password'].value ==''),'fa-check':(!f['password'].errors?.['minlength'] && f['password'].value!='')}"></i>
                            Contains at least
                            8 characters
                          </li>
                          <li>
                            <i class="fa-solid"
                              [ngClass]="{'fa-times text-red': (f['password'].errors?.['upperCase'] || f['password'].errors?.['lowerCase'] || f['password'].value ==''),'fa-check':(!f['password'].errors?.['upperCase'] && !f['password'].errors?.['lowerCase'] && f['password'].value!='')}"></i>
                            Contains both lower (a-z) and upper case letters
                            (A-Z)
                          </li>
                          <li>
                            <i class="fa-solid"
                              [ngClass]="{'fa-times text-red': (f['password'].errors?.['numberCase'] || f['password'].errors?.['specialCase'] || f['password'].value ==''),'fa-check':(!f['password'].errors?.['numberCase'] && !f['password'].errors?.['specialCase'] && f['password'].value!='')}"></i>
                            Contains at least one number (0-9) and a symbol
                          </li>
                          <li>
                            <i class="fa-solid"
                              [ngClass]="{'fa-times text-red': f['password'].errors?.['emailCase'] || f['password'].value =='','fa-check':!f['password'].errors?.['emailCase'] && f['password'].value!=''}"></i>
                            Does not contain
                            your email address
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                      <div class="form-floating">
                        <input type="text" class="form-control" id="floatingUsername"
                          formControlName="referral_code" />
                        <label for="floatingUsername">Have Referal Code</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="chkbox_sec">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" formControlName="checkbox" value=""
                          id="flexCheckDefault" />
                      </div>
                      <label>I Agree <a href="#">Terms and Conditions</a> and
                        <a href="#">Privacy Policy</a></label>

                    </div>
                  </div>
                  <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <button type="button" class="btn submit_btn" (click)="registration()"
                      [disabled]="!registrationForm.valid || !registrationForm.value.checkbox">
                      Create account
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>