import { Component, HostListener, OnInit, ViewChildren } from '@angular/core';
import { ScrollTrigger } from 'gsap/all';
import { Router } from '@angular/router';
import { TweenLite, Power1, gsap } from 'gsap';
import { ApiService } from 'src/app/core/shared/api.service';
import { SpinnerService } from 'src/app/core/shared/spinner.service';
import { StorageService } from 'src/app/core/shared/storage.service';

@Component({
  selector: 'app-signal-inner-page-layout',
  templateUrl: './signal-inner-page-layout.component.html',
  styleUrls: ['./signal-inner-page-layout.component.scss'],
})
export class SignalInnerPageLayoutComponent implements OnInit {
  imageActive: any;
  intervalId: any;
  tabData = '';

  slider: any = ['first', 'second', 'third'];
  images = ['assets/images/tenx/svg/signal.svg'];
  loaded: any = 0;

  spotTradingDetails: any;
  futureTradingDetails: any;
  forexTradingDetails: any;

  bottomReach: boolean = false;
  navigationTrigger: boolean = false;
  pageHeight: any;
  currentScrollHeight: any;
  animationLoader: any;
  @ViewChildren('fade1, fade2, fade3, fade4,fade5, fade6')
  Elements: any;

  constructor(
    private router: Router,
    private ApiService: ApiService,
    private spinner: SpinnerService,
    public storageService: StorageService
  ) {
    this.automaticSlider();
  }
  ngOnInit(): void {
    this.getTradingDetails();
    this.storageService.tabData = '1';
  }
  ngOnDestroy(): void {
    this.clearSlider();
  }
  ngAfterViewInit(): void {
    const body = document.querySelector('body') as HTMLElement;
    body.style.overflow = 'hidden';
    this.animationLoader = true;

    window.scrollTo(0, 0);
    //Tenx Title page transition for Mobile
    let responsive = window.innerWidth;
    if (responsive <= 768) {
      gsap.registerPlugin(ScrollTrigger);

      const panels = document.querySelectorAll('.svgtrigger');
      // Array.from(panels).forEach((e) => console.log(e.clientWidth));
      let panelContainerWidth = 0;
      Array.from(panels).forEach((a) => {
        panelContainerWidth += a.clientWidth;
      });

      let sections = gsap.utils.toArray('.svgtrigger');
      // console.log(sections)
      gsap.to(sections, {
        x: -panelContainerWidth + window.innerWidth,
        ease: 'none',
        scrollTrigger: {
          start: 'top top',
          trigger: '.mob-scroll-triger-tittle',
          pin: true,
          scrub: 0.1,
          end: panelContainerWidth,
        },
      });
    }

    setTimeout(() => {
      const panels = document.querySelectorAll('.S-panel');
      // Array.from(panels).forEach((e) => console.log(e.clientWidth));
      let panelContainerWidth = 0;
      Array.from(panels).forEach((a) => {
        panelContainerWidth += a.clientWidth;
      });

      if (responsive > 768) {
        gsap.registerPlugin(ScrollTrigger);

        let Sections = gsap.utils.toArray('.S-panel'),
          getTotalWidth = () => {
            let width = 0;
            Sections.forEach((el: any) => (width += el.offsetWidth));
            return width;
          };

        if (Sections.length) {
          gsap.to(Sections, {
            x: -panelContainerWidth + window.innerWidth,
            ease: 'none',
            scrollTrigger: {
              trigger: '#SHorizontalWrapper',
              pin: true,
              start: 0,
              scrub: 0.5,
              end: panelContainerWidth,
            },
          });
        }
      }
      this.animationLoader = false;
      body.style.overflow = 'auto';
    }, 2500);
  }

  homePage() {
    this.router.navigate(['/']);
  }

  signIn() {
    this.router.navigate(['/user/login']);
  }

  getTradingDetails() {
    this.spinner.show();
    this.ApiService.getSpotTradingDetails().subscribe((res: any) => {
      // console.log(res.data);
      this.spotTradingDetails = JSON.stringify(res.data);
      // console.log('parsed Json'+this.tradingDetails);
    });
    this.spinner.show();
    this.ApiService.getFutureTradingDetails().subscribe((res: any) => {
      // console.log(res.data);
      this.futureTradingDetails = JSON.stringify(res.data);
      // console.log('parsed Json'+this.tradingDetails);
    });
    this.ApiService.getForexTradingDetails().subscribe((res: any) => {
      // console.log(res.data);
      this.forexTradingDetails = JSON.stringify(res.data);
      // console.log('parsed Json'+this.tradingDetails);
      this.spinner.hide();
    });
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    this.Elements.forEach((element: any) => {
      var rect = element.nativeElement.getBoundingClientRect();
      if (rect.left < window.innerWidth && rect.right > 0) {
        TweenLite.to(element.nativeElement, {
          opacity: 1,
          duration: 0.3,
          delay: 0.5,
        });
      }
    });
  }
  automaticSlider() {
    this.clearSlider();
    this.intervalId = setInterval(() => {
      const currentIndex = this.slider.findIndex(
        (x: any) => x === this.tabData
      );
      this.tabData =
        this.slider[currentIndex] === 'third'
          ? this.slider[0]
          : this.slider[currentIndex + 1];
    }, 2450);
  }

  clearSlider() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  tab(data: any) {
    this.automaticSlider();
    if (data == 1) {
      this.tabData = 'first';
    }
    if (data == 2) {
      this.tabData = 'second';
    }
    if (data == 3) {
      this.tabData = 'third';
    }
  }
}
