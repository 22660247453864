<div class="payment-wrap">
    <!-- <h1>Blockchain based <br><span>and built on</span><br>
        BNB smartchain.</h1> -->
    <div class="text-wrap borderTop">
        <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-8 col-xl-8">
                <h3>Smart Contract</h3>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                <p>All payments made through TenX including<br> 
                    the affiliate program and subscription are<br>
                     processed using BNB smart contract.</p>
            </div>
        </div>
    </div>
    <div class="text-wrap">
        <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-8 col-xl-8">
                <h3>BNB and BUSD</h3>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                <p>We only accept BNB and BUSD as payments on<br> 
                    our platform.</p>
            </div>
        </div>
    </div>

    <div class="text-wrap">
        <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-8 col-xl-8">
                <h3>Affiliate Payment</h3>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                <p>All commissions through our affiliate<br>
                     marketing program are paid in BNB or BUSD</p>
            </div>
        </div>
    </div>

    <div class="text-wrap">
        <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-8 col-xl-8">
                <h3>Transparency using Smart Contract</h3>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                <p>By using smart contract, all payments are<br>
                    secured ensuring full transparency.</p>
            </div>
        </div>
    </div>

    <div class="text-wrap borderBottom">
        <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-8 col-xl-8">
                <h3>Swap</h3>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                <p>Easily swap and withdraw your commision in<br>
                    BNB and BUSD at any time via your dashboard.</p>
            </div>
        </div>
    </div>

    <div class="imagine">
        <div class="row wrapper">
            <div class="col-md-12 col-lg-6 col-xl-6">
               
                <p>We are proud to launch our Signal Cards as the first<br>
                     phase of TenX. Stay tuned to our launch of TenX<br>
                     Community and Tenx NFTs.
                </p>
            </div>
            <div class="col-md-12 col-lg-6 col-xl-6">
                <h4>What’s<br>
                    <span>coming next</span>
                </h4>
            </div>
        </div>
    </div>
     


    <div class="row">
        <div class="col-md-12">
            <div class="tabWraper">
                <div class="row tabWrapper-container">
                    <div class="col-md-5 col-lg-4 col-xl-4">
                        <ul>
                            <li *ngIf="StorageService.tabData=='first'" class="text">TenX Signals</li>
                            <li *ngIf="StorageService.tabData=='second'" class="text">TenX Community</li>
                            <li *ngIf="StorageService.tabData=='third'" class="text">NFT Marketplace</li>
                            <li (click)="tab(1)" class="tabCount" [ngClass]="{'active':StorageService.tabData=='first'}">1</li>
                            <li (click)="tab(2)" class="tabCount" [ngClass]="{'active':StorageService.tabData=='second'}">2</li>
                            <li (click)="tab(3)" class="tabCount" [ngClass]="{'active':StorageService.tabData=='third'}">3</li>
                        </ul>
                    </div>
                    <div class="col-md-7 col-lg-8 col-xl-8">
                        <!-- <img class="backgrountImg" src="../../../../../assets/images/tenx/innerPage/backImg.png" alt="" /> -->
                        <div class="tabimageWraper">
                            <img *ngIf="StorageService.tabData=='first'"  class="tabImg fade-in"
                                src="../../../../../assets/images/tenx/innerPage/Component1.png" alt="" />
                            <img *ngIf="StorageService.tabData=='second'"  class="tabImg fade-in"
                                src="../../../../../assets/images/tenx/innerPage/Component2.png" alt="" />
                            <img *ngIf="StorageService.tabData=='third'" class="tabImg fade-in"
                                src="../../../../../assets/images/tenx/innerPage/Component3.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="unlocking">
                <h2>Unlocking<br> <span>The Future</span></h2>
                <p>Be among the first to join our waitlist and enjoy a <br>
                    special promotion.
                </p>
                <button class="know-more" (click)="navigation('helpcenter')">Know More</button>
                <button class="button" (click)="navigation('roadmap')">Join Us</button>
            </div>
        </div>
    </div>
    <!-- <div class="protocol">
        <div class="row">
            <div class="col-md-8">
                <h2>Leverage the <br>
                    experience of <span>crypto experts</span></h2>
            </div>
            <div class="col-md-4">
                <div class="sub">
                    <p>TenX is built for every level of ambition, trust<br>
                        the expertise of our pro-traders when making<br>
                        your decisions to trade.</p>
                </div>

            </div>

        </div>
    </div> -->
</div>
  