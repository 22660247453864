<div class="newGen">
  <div class="row alighItem content-wrapper">
    <div class="col-md-5">
      <h1>
        Web3 Exclusive<br />
        Insights for Traders
      </h1>
      <p>
        Access daily trading tips for spot, future markets and <br />the forex
        market, market analytics and premium content <br />provided by a team of
        pro-traders.
      </p>
      <button (click)="roadmapNotifyNavigation()" class="button">
        Join Now
      </button>
    </div>
    <div class="col-md-7">
      <img
        class="banner"
        src="../../../../../assets/images/tenx/innerPage/joinAsBanner.png"
        alt=""
      />
    </div>
  </div>
</div>
