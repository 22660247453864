import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tenx-gem',
  templateUrl: './tenx-gem.component.html',
  styleUrls: ['./tenx-gem.component.scss'],
})
export class TenxGemComponent implements OnInit {
  constructor() {}
  showPlayIcon: boolean = true;
  ngOnInit(): void {}

}
