import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import gsap from 'gsap';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss'],
})
export class PlansComponent implements OnInit, AfterViewInit {
  question: any = [
    {
      title: 'Why subscribe to TenX?',
      content:
        'Gain access to daily spot and future market signals, exclusive content, as well as daily market analytics',
    },
    {
      title: 'How do I pay my subscription with Metamask?',
      content: 'After you sign up through our website, you will be redirected to the subscription plan page. Click the plan you would like to register to, then the MetaMask icon will appear, click that and you will be asked to confirm your subscription’s transaction. ',
    },
    {
      title: 'How does the affiliate marketing program work on tenx.io?',
      content: 'By sharing your affiliate link you can earn up to four levels of commission. The first level is 10%, second level is 8%, third level is 6%, and fourth level is 4%. Here is how our affiliate program works based on each level.',
      levels: 'Here is how our affiliate program works based on each level.',
      levelContentA: 'Level 1: The first time you share your affiliate link to a friend (Alex), you receive 10% of Alex’s subscription fee, and Alex is considered your first level of your affiliate program.',
      levelContentB: 'Level 2: Alex invites his friend (Tony). Tony is considered your second level, so you will earn 8% of Tony’s subscription fee. Tony is Alex’s first level and will therefore earn 10% of Tony’s subscription fee. ',
      levelContentC: 'Level 3: Tony invites his friend (Mia). Mia is now considered your third level, so you will earn 6% of Mia’s subscription fee. Mia is Tony’s first level and will therefore earn 10% of Mia’s subscription fee. Mia is Alex’s second level and will therefore earn 8% of Mia’s subscription fee. ',
      levelContentD: 'Level 4: Mia invites her friend (Jess). Jess is now your fourth level, so you will earn 4% of Jess’s subscription fee. Jess is Mia’s first level and will therefore earn 10% of Mia’s subscription fee. Jess is Tony’s second level and will therefore earn 8% of Jess’s subscription fee. Jess is Alex’s third level and will therefore earn 6% of Jess’s subscription fee. '
    
    },
  ];

  bottomReach: boolean = false;
  navigationTrigger: boolean = false;
  pageHeight: any;
  currentScrollHeight: any;

  constructor(private router: Router) { }
  ngOnInit(): void { }
  ngAfterViewInit(): void {


  }





  homePage() {
    this.router.navigate(['/']);
  }
  ngOnDestroy() {
    // window.removeEventListener('scroll');
  }
}
