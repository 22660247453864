<aside class="sidebar">
  <div class="sidebar_inner">
    <div class="brand_top">
      <a [routerLink]="['/admin/support-list']"
        ><img src="assets/img/logo.png" alt="Logo"
      /></a>
    </div>

    <div class="sidebar_menus">
      <ul>
        <li routerLinkActive="active">
          <a>
            <span
              ><img src="assets/img/admin/sidebar/menu1.svg" alt="MenuItem"
            /></span>
          </a>
        </li>
        <li placement="bottom" ngbTooltip="Signal Card" [ngClass]="activeUrl == 'signal-card' ? 'active' : ''">
          <a
            (click)="saveData('Signal Cards')"
            [routerLink]="['/admin/signal-card-list/1']">
            <span
              ><img src="assets/img/admin/sidebar/menu2.svg" alt="MenuItem"
            /></span>
          </a>
        </li>
        <li  placement="bottom" ngbTooltip="Affiliate" [ngClass]="activeUrl == 'affiliate' ? 'active' : ''">
          <a
            (click)="saveData('Affiliate')"
            [routerLink]="['/admin/affiliate-list']">
            <span
              ><img src="assets/img/admin/sidebar/menu3.svg" alt="MenuItem"
            /></span>
          </a>
        </li>
        <li placement="bottom" ngbTooltip="User Management" [ngClass]="activeUrl == 'user' ? 'active' : ''">
          <a
            (click)="saveData('User Management')"
            [routerLink]="['/admin/user-list/1']"
          >
            <span
              ><img src="assets/img/admin/sidebar/menu4.svg" alt="MenuItem"
            /></span>
          </a>
        </li>
        <li placement="bottom" ngbTooltip="Support" [ngClass]="activeUrl == 'support' ? 'active' : ''">
          <a
            (click)="saveData('Support')"
            [routerLink]="['/admin/support-list']"
          >
            <span
              ><img src="assets/img/admin/sidebar/menu5.svg" alt="MenuItem"
            /></span>
          </a>
        </li>
        <li routerLinkActive="active">
          <a>
            <span
              ><img src="assets/img/admin/sidebar/menu6.svg" alt="MenuItem"
            /></span>
          </a>
        </li>
        <li routerLinkActive="active">
          <a>
            <span
              ><img src="assets/img/admin/sidebar/menu7.svg" alt="MenuItem"
            /></span>
          </a>
        </li>
        <li placement="bottom" ngbTooltip="Blog" [ngClass]="activeUrl == 'blog' ? 'active' : ''">
          <a
            (click)="saveData('Blog Page')"
            [routerLink]="['/admin/blog-list']"
          >
            <span
              ><img src="assets/img/admin/sidebar/menu8.svg" alt="MenuItem"
            /></span>
          </a>
        </li>
        <li
          placement="bottom" ngbTooltip="Subscription Management"
          [ngClass]="activeUrl == 'subscription' ? 'active' : ''"
        >
          <a
            (click)="saveData('Subscription Management')"
            [routerLink]="['/admin/subscription-list']"
          >
            <span
              ><img src="assets/img/admin/sidebar/menu9.svg" alt="MenuItem"
            /></span>
          </a>
        </li>
        <li
          placement="bottom" ngbTooltip="Help Center Management"
          [ngClass]="activeUrl == 'help-center' ? 'active' : ''"
        >
          <a
            (click)="saveData('Help Center Mangement')"
            [routerLink]="['/admin/help-center-list']"
          >
            <span
              ><img src="assets/img/admin/sidebar/menu10.svg" alt="MenuItem"
            /></span>
          </a>
        </li>
        <li routerLinkActive="active">
          <a>
            <span
              ><img src="assets/img/admin/sidebar/menu11.svg" alt="MenuItem"
            /></span>
          </a>
        </li>
        <li routerLinkActive="active" placement="bottom" ngbTooltip="Log Out">
          <a (click)="logout()" class="logout_menu">
            <span
              ><img src="assets/img/admin/sidebar/logout.svg" alt="MenuItem"
            /></span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</aside>
