<div class="spot_dailytips_wrapper">
  <!-- <div class="vertical-green-line">
    <div class="grey"></div>
    <div class="grey"></div>
    <div class="green"></div>
  </div> -->
  <div class="content-wrapper">
    <div class="header_section">
      <h1>Daily Exclusive Tips for Crypto Future Market</h1>
      <p>How to use the signal card on the Future Market</p>
    </div>
    <div class="panel_section">
      <div class="section_title">Future Signal</div>
      <div class="tr_card">
        <div class="row m-0 card_row">
          <div
            class="col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex card_wrapper"
          >
            <div class="statistics_section">
              <div
                class="tr_card-header flex-wrap d-flex justify-content-between align-items-center"
              >
                <div class="onBinance">
                  <div
                    class="static"
                    [ngClass]="{ active: storageService.futureData == '1' }"
                    (click)="tab(1)"
                  >
                    <p class="m-0 title">On Binance</p>
                    <h5 class="m-0 card_font">1INCHBTC</h5>
                  </div>
                  <div
                    class="popup fade-in"
                    *ngIf="storageService.futureData == '1'"
                  >
                    <p class="m-0 title">On Binance</p>
                    <h5 class="m-0 card_font">1INCHBTC</h5>
                    <div class="dashed-line">
                      <div class="toolTip">
                        This trading pair is available on Binance.
                      </div>
                      <div class="circle">
                        <img
                          src="assets/images/tenx/innerPage/signal/circle.png"
                          alt="image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="currentPrice">
                  <div
                    class="static"
                    [ngClass]="{
                      active: storageService.futureData == '2'
                    }"
                    (click)="tab(2)"
                  >
                    <p class="m-0 title">Current Price</p>
                    <h6 class="m-0 card_font">0.00003374 BTC</h6>
                  </div>
                  <div
                    class="popup fade-in"
                    *ngIf="storageService.futureData == '2'"
                  >
                    <p class="m-0 title">Current Price</p>
                    <h6 class="m-0 card_font">0.00003374 BTC</h6>
                    <div class="dashed-line">
                      <div class="toolTip">
                        Connected to API showing the real time price.
                      </div>
                      <div class="circle">
                        <img
                          src="assets/images/tenx/innerPage/signal/circle.png"
                          alt="image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="createdOn">
                  <div
                    class="static"
                    [ngClass]="{ active: storageService.futureData == '3' }"
                    (click)="tab(3)"
                  >
                    <p class="m-0 title">Created On</p>
                    <h6 class="m-0 card_font">Apr 8, 2022 | 3.31.53 PM</h6>
                  </div>
                  <div
                    class="popup fade-in"
                    *ngIf="storageService.futureData == '3'"
                  >
                    <p class="m-0 title">Created On</p>
                    <h6 class="m-0 card_font">Apr 8, 2022 | 3.31.53 PM</h6>
                    <div class="dashed-line">
                      <div class="toolTip">
                        The time and date this signal was created.
                      </div>
                      <div class="circle">
                        <img
                          src="assets/images/tenx/innerPage/signal/circle.png"
                          alt="image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="watchList_wrapper">
                    <div
                      class="static"
                      [ngClass]="{ active: storageService.futureData == '4' }"
                      (click)="tab(4)"
                    >
                      <div class="watchlist static animate_hover pointer">
                        <img
                          src="assets/images/tenx/innerPage/signal/watchlist.png"
                          alt="bookmark"
                        />
                      </div>
                    </div>
                    <div
                      class="popup fade-in"
                      *ngIf="storageService.futureData == '4'"
                    >
                      <div class="watchlist static animate_hover pointer">
                        <img
                          src="assets/images/tenx/innerPage/signal/watchlist.png"
                          alt="bookmark"
                        />
                      </div>
                      <div class="dashed-line">
                        <div class="toolTip">
                          Click to save onto your watchlist to view all your
                          watchlists there.
                        </div>
                        <div class="circle">
                          <img
                            src="assets/images/tenx/innerPage/signal/circle.png"
                            alt="image"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tr_card-content justify-content-between align-items-center"
              >
                <div class="main-card-item-wrap align-items-center flex-wrap">
                  <div class="main-card-item long-time d-flex position">
                    <div
                      class="static"
                      [ngClass]="{ active: storageService.futureData == '5' }"
                      (click)="tab(5)"
                    >
                      <span><i class="fa-solid fa-sort-up"></i> Long</span>
                    </div>
                    <div
                      class="popup fade-in"
                      *ngIf="storageService.futureData == '5'"
                    >
                      <span>Long</span>
                      <div class="dashed-line">
                        <div class="toolTip">
                          Take a long or short position.
                        </div>
                        <div class="circle">
                          <img
                            src="assets/images/tenx/innerPage/signal/circle.png"
                            alt="image"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="main-card-item item_risk">
                    <div
                      class="static"
                      [ngClass]="{ active: storageService.futureData == '6' }"
                      (click)="tab(6)"
                    >
                      <span class="risk card_font">Risk :<span>Low</span></span>
                    </div>
                    <div
                      class="popup fade-in"
                      *ngIf="storageService.futureData == '6'"
                    >
                      <span class="risk card_font">Risk :<span>Low</span></span>
                      <div class="dashed-line">
                        <div class="toolTip">
                          There are two levels of risks: low and high.
                        </div>
                        <div class="circle">
                          <img
                            src="assets/images/tenx/innerPage/signal/circle.png"
                            alt="image"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="main-card-item leverage">
                    <div
                      class="static"
                      [ngClass]="{ active: storageService.futureData == '7' }"
                      (click)="tab(7)"
                    >
                      <span class="risk card_font"
                        >Leverage : <span> 3x </span></span
                      >
                    </div>
                    <div
                      class="popup fade-in"
                      *ngIf="storageService.futureData == '7'"
                    >
                      <span class="risk card_font"
                        >Leverage : <span> 3x </span></span
                      >
                      <div class="dashed-line">
                        <div class="toolTip">
                          This is a suggestion of what level of leverage you
                          have to use on this trade.
                        </div>
                        <div class="circle">
                          <img
                            src="assets/images/tenx/innerPage/signal/circle.png"
                            alt="image"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="main-card-item item_timeFrame">
                    <div class="d-flex align-items-center">
                      <div
                        class="static"
                        [ngClass]="{ active: storageService.futureData == '8' }"
                        (click)="tab(8)"
                      >
                        <span class="risk card_font"
                          >Time Frame :
                          <span>Short</span>
                        </span>
                      </div>
                      <div
                        class="popup fade-in"
                        *ngIf="storageService.futureData == '8'"
                      >
                        <span class="risk card_font"
                          >Time Frame :
                          <span>Short</span>
                        </span>
                        <div class="dashed-line">
                          <div class="toolTip">
                            Short - will happen within the next few days. Medium
                            - may take a week to ten days. Long - may take more
                            than one month.
                          </div>
                          <div class="circle">
                            <img
                              src="assets/images/tenx/innerPage/signal/circle.png"
                              alt="image"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mx-0 my-2 align-items-center box-cnr">
                  <div
                    class="col-sm-6 col-md-6 col-lg-6 col-xl-6 p-0 entry_wrapper"
                  >
                    <div
                      class="static"
                      [ngClass]="{ active: storageService.futureData == '9' }"
                      (click)="tab(9)"
                    >
                      <div class="d-flex align-items-center">
                        <span class="w-pre card_font entry">Entry : </span>
                        <span class="profit">0.00007 - 0.000075</span>
                      </div>
                    </div>
                    <div
                      class="popup fade-in"
                      *ngIf="storageService.futureData == '9'"
                    >
                      <div class="d-flex align-items-center">
                        <span class="w-pre card_font entry">Entry : </span>
                        <span class="profit">0.00007 - 0.000075</span>
                      </div>
                      <div class="dashed-line">
                        <div class="toolTip">
                          Our suggestion as the best price to purchase this
                          coin.
                        </div>
                        <div class="circle">
                          <img
                            src="assets/images/tenx/innerPage/signal/circle.png"
                            alt="image"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-sm-6 col-md-6 col-lg-6 col-xl-6 p-0 stop_loss_wrapper"
                  >
                    <div
                      class="static"
                      [ngClass]="{ active: storageService.futureData == '10' }"
                      (click)="tab(10)"
                    >
                      <div class="d-flex align-items-center">
                        <span class="w-pre card_font stop_loss"
                          >Stop Loss :
                        </span>
                        <span class="loss">0.000006 BTC (-20.83%)</span>
                      </div>
                    </div>
                    <div
                      class="popup fade-in"
                      *ngIf="storageService.futureData == '10'"
                    >
                      <div class="d-flex align-items-center">
                        <span class="w-pre card_font stop_loss"
                          >Stop Loss :
                        </span>
                        <span class="loss">0.000006 BTC (-20.83%)</span>
                      </div>
                      <div class="dashed-line">
                        <div class="toolTip">
                          At what price to put your stop loss.
                        </div>
                        <div class="circle">
                          <img
                            src="assets/images/tenx/innerPage/signal/circle.png"
                            alt="image"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex my-2 target_wrapper">
                  <div class="web-target-title">
                    <div
                      class="static d-flex py-2 align-items-center"
                      [ngClass]="{
                        active: storageService.futureData == '11'
                      }"
                      (click)="tab(11)"
                    >
                      <span class="w-pre card_font target">Targets : </span>
                    </div>
                    <div
                      class="popup fade-in d-flex py-2 align-items-center"
                      *ngIf="storageService.futureData == '11'"
                    >
                      <span class="w-pre card_font target">Targets : </span>
                      <div class="dashed-line">
                        <div class="toolTip">
                          These are the targets for your selling prices.
                        </div>
                        <div class="circle">
                          <img
                            src="assets/images/tenx/innerPage/signal/circle.png"
                            alt="image"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="target-container flex-grow-1">
                    <div class="d-flex flex-wrap exp-amount-wrap">
                      <div
                        class="mobile-target-title"
                        [ngClass]="{
                          active: storageService.futureData == '11'
                        }"
                        (click)="tab(11)"
                      >
                        Targets :
                      </div>
                      <div
                        class="mobile-target-title popup fade-in"
                        *ngIf="storageService.futureData == '11'"
                      >
                        Targets :
                        <div class="dashed-line">
                          <div class="toolTip">
                            These are the targets for your selling prices.
                          </div>
                          <div class="circle">
                            <img
                              src="assets/images/tenx/innerPage/signal/circle.png"
                              alt="image"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-sm-6 col-md-6 col-lg-6 col-xl-6 py-2 d-flex single_target"
                      >
                        <span class="indexNo mr-2">01</span>

                        <div class="target_percent_container">
                          <span class="card_font">0.0000068 </span>
                          <span class="card_font">(17.24%)</span>
                          <!-- text-muted  -->
                        </div>
                      </div>
                      <div
                        class="col-sm-6 col-md-6 col-lg-6 col-xl-6 py-2 d-flex single_target"
                      >
                        <span class="indexNo mr-2">02</span>

                        <div class="target_percent_container">
                          <span class="card_font">0.0000085 </span>
                          <span class="card_font">(17.24%)</span>
                          <!-- text-muted  -->
                        </div>
                      </div>
                      <div
                        class="col-sm-6 col-md-6 col-lg-6 col-xl-6 py-2 d-flex single_target"
                      >
                        <span class="indexNo mr-2">03</span>

                        <div class="target_percent_container">
                          <span class="card_font">0.0000085 </span>
                          <span class="card_font">(17.24%)</span>
                          <!-- text-muted  -->
                        </div>
                      </div>
                      <div
                        class="col-sm-6 col-md-6 col-lg-6 col-xl-6 py-2 d-flex single_target"
                      >
                        <span class="indexNo mr-2">04</span>

                        <div class="target_percent_container">
                          <span class="card_font">0.0000085 </span>
                          <span class="card_font">(17.24%)</span>
                          <!-- text-muted  -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mobile_graph_section">
                    <div
                      class="graphOuter d-flex flex-column position-relative mh-100"
                    >
                      <div class="blend-heighlight"></div>
                      <div class="graph-profit">
                        <div class="totalProfit">
                          <div
                            class="static"
                            [ngClass]="{
                              active: storageService.futureData == '12'
                            }"
                            (click)="tab(12)"
                          >
                            <h5 class="card_font">51.72%</h5>
                          </div>
                          <div
                            class="popup fade-in"
                            *ngIf="storageService.futureData == '12'"
                          >
                            <h5 class="card_font">51.72%</h5>
                            <div class="dashed-line">
                              <div class="toolTip">
                                This shows the total profit calculation.
                              </div>
                              <div class="circle">
                                <img
                                  src="assets/images/tenx/innerPage/signal/circle.png"
                                  alt="image"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="daysWrapper">
                          <div
                            class="static"
                            [ngClass]="{
                              active: storageService.futureData == '13'
                            }"
                            (click)="tab(13)"
                          >
                            <span class="card_font hourCount">In 16 Days</span>
                          </div>
                          <div
                            class="popup fade-in"
                            *ngIf="storageService.futureData == '13'"
                          >
                            <span class="card_font hourCount">In 16 Days</span>
                            <div class="dashed-line">
                              <div class="toolTip">
                                How long it will take to achieve this target.
                              </div>
                              <div class="circle">
                                <img
                                  src="assets/images/tenx/innerPage/signal/circle.png"
                                  alt="image"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="graphWrapper">
                        <div
                          class="static"
                          [ngClass]="{
                            active: storageService.futureData == '14'
                          }"
                          (click)="tab(14)"
                        >
                          <div class="graph">
                            <div class="graph-single">
                              <div class="graph-single-line">
                                <div class="graph-single-line-empty">
                                  <div
                                    class="graph-single-line-fill"
                                    [ngStyle]="{
                                      height: 100 + '%'
                                    }"
                                  ></div>
                                </div>
                              </div>
                              <div class="graph-single-label">1</div>
                            </div>
                            <div class="graph-single">
                              <div class="graph-single-line">
                                <div class="graph-single-line-empty">
                                  <div
                                    class="graph-single-line-fill"
                                    [ngStyle]="{
                                      height: 100 + '%'
                                    }"
                                  ></div>
                                </div>
                              </div>
                              <div class="graph-single-label">2</div>
                            </div>
                            <div class="graph-single">
                              <div class="graph-single-line">
                                <div class="graph-single-line-empty">
                                  <div
                                    class="graph-single-line-fill"
                                    [ngStyle]="{
                                      height: 100 + '%'
                                    }"
                                  ></div>
                                </div>
                              </div>
                              <div class="graph-single-label">3</div>
                            </div>
                            <div class="graph-single">
                              <div class="graph-single-line">
                                <div class="graph-single-line-empty">
                                  <div
                                    class="graph-single-line-fill"
                                    [ngStyle]="{
                                      height: 100 + '%'
                                    }"
                                  ></div>
                                </div>
                              </div>
                              <div class="graph-single-label">4</div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="popup fade-in"
                          *ngIf="storageService.futureData == '14'"
                        >
                          <div class="graph_image">
                            <img
                              src="assets/images/tenx/innerPage/signal/graph.png"
                              alt=""
                            />
                          </div>
                          <div class="dashed-line">
                            <div class="toolTip">
                              Once the target is hit, this bar will turn green.
                            </div>
                            <div class="circle">
                              <img
                                src="assets/images/tenx/innerPage/signal/circle.png"
                                alt="image"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="web_graph_section">
              <div
                class="graphOuter d-flex flex-column position-relative mh-100"
              >
                <div class="blend-heighlight"></div>
                <div class="graph-profit">
                  <div class="totalProfit">
                    <div
                      class="static"
                      [ngClass]="{
                        active: storageService.futureData == '12'
                      }"
                      (click)="tab(12)"
                    >
                      <h5 class="card_font">51.72%</h5>
                    </div>
                    <div
                      class="popup fade-in"
                      *ngIf="storageService.futureData == '12'"
                    >
                      <h5 class="card_font">51.72%</h5>
                      <div class="dashed-line">
                        <div class="toolTip">
                          This shows the total profit calculation.
                        </div>
                        <div class="circle">
                          <img
                            src="assets/images/tenx/innerPage/signal/circle.png"
                            alt="image"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="daysWrapper">
                    <div
                      class="static"
                      [ngClass]="{
                        active: storageService.futureData == '13'
                      }"
                      (click)="tab(13)"
                    >
                      <span class="card_font hourCount">In 16 Days</span>
                    </div>
                    <div
                      class="popup fade-in"
                      *ngIf="storageService.futureData == '13'"
                    >
                      <span class="card_font hourCount">In 16 Days</span>
                      <div class="dashed-line">
                        <div class="toolTip">
                          How long it will take to achieve this target.
                        </div>
                        <div class="circle">
                          <img
                            src="assets/images/tenx/innerPage/signal/circle.png"
                            alt="image"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="graphWrapper">
                  <div
                    class="static"
                    [ngClass]="{
                      active: storageService.futureData == '14'
                    }"
                    (click)="tab(14)"
                  >
                    <div class="graph">
                      <div class="graph-single">
                        <div class="graph-single-line">
                          <div class="graph-single-line-empty">
                            <div
                              class="graph-single-line-fill"
                              [ngStyle]="{
                                height: 100 + '%'
                              }"
                            ></div>
                          </div>
                        </div>
                        <div class="graph-single-label">1</div>
                      </div>
                      <div class="graph-single">
                        <div class="graph-single-line">
                          <div class="graph-single-line-empty">
                            <div
                              class="graph-single-line-fill"
                              [ngStyle]="{
                                height: 100 + '%'
                              }"
                            ></div>
                          </div>
                        </div>
                        <div class="graph-single-label">2</div>
                      </div>
                      <div class="graph-single">
                        <div class="graph-single-line">
                          <div class="graph-single-line-empty">
                            <div
                              class="graph-single-line-fill"
                              [ngStyle]="{
                                height: 100 + '%'
                              }"
                            ></div>
                          </div>
                        </div>
                        <div class="graph-single-label">3</div>
                      </div>
                      <div class="graph-single">
                        <div class="graph-single-line">
                          <div class="graph-single-line-empty">
                            <div
                              class="graph-single-line-fill"
                              [ngStyle]="{
                                height: 100 + '%'
                              }"
                            ></div>
                          </div>
                        </div>
                        <div class="graph-single-label">4</div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="popup fade-in"
                    *ngIf="storageService.futureData == '14'"
                  >
                    <div class="graph_image">
                      <img
                        src="assets/images/tenx/innerPage/signal/graph.png"
                        alt=""
                      />
                    </div>
                    <div class="dashed-line">
                      <div class="toolTip">
                        Once the target is hit, this bar will turn green.
                      </div>
                      <div class="circle">
                        <img
                          src="assets/images/tenx/innerPage/signal/circle.png"
                          alt="image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="border_right-bottom"></div>
  </div>
</div>
