import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/core/shared/api.service';
import { Location } from '@angular/common';
import { StorageService } from '../core/shared/storage.service';
@Component({
  selector: 'app-registration-verify-otp',
  templateUrl: './registration-verify-otp.component.html',
  styleUrls: ['./registration-verify-otp.component.scss']
})
export class RegistrationVerifyOtpComponent implements OnInit {
  @ViewChild('otpValue') otpValue: any
  public otpVerificationForm: any = FormGroup;
  public inputForm: any = FormGroup;
  submitted: boolean = false;
  input1: any;
  input2: any;
  input3: any;
  input4: any;
  input5: any;
  input6: any;
  getRegistrationEmailId: String = '';
  disabledVerifyButton!: boolean;
  startingTime: any;
  disableResendButton!: boolean;

  constructor(
    private fb: FormBuilder,
    private _apiservice: ApiService,
    private toastr: ToastrService,
    private router: Router,
    private _location: Location,
    private storage: StorageService
  ) { }

  ngOnInit(): void {
    this.otpVerificationForm = this.fb.group({
      email: [''],
      otp: ['', Validators.required],
      site: ['']
    });
    this.countDown();
    this.getRegistrationEmailId = this.storage.registrationEmailId;
  }

  countDown(){
    var countDownDate:any = new Date();
    countDownDate.setMinutes(countDownDate.getMinutes() + 2);
    // Update the count down every 1 second
    this.startingTime = setInterval( () => {

      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var time = countDownDate - now;

      // Time calculations for  minutes and seconds
      var minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((time % (1000 * 60)) / 1000);

      // Display the result in the element with id="demo"
      const y: any = document.getElementById("demo");
      y.innerHTML = minutes + "m " + seconds + "s ";

      // If the count down is finished, write some text
      if (minutes == 0 && seconds == 0) {
        clearInterval(this.startingTime);
        const z: any = document.getElementById("demo");
        z.innerHTML = "OTP Expired";
        this.disableResendButton = false;
      }
    }, 1000);
    setTimeout(() => {
      this.disabledVerifyButton = true;
    },120000) 
  }

  otpVerification() {
    //const otpValue = `${this.input1}${this.input2}${this.input3}${this.input4}${this.input5}${this.input6}`
    const data = {
      email: this.getRegistrationEmailId,
      otp: this.otpVerificationForm.value.otp,
    }

    this._apiservice.httpPostPublic('user/verify-otp',data).subscribe((res) => {
      if (res) {
        this.submitted = false;
        this.toastr.success('', 'Registration successfully');
        clearInterval(this.startingTime);
        this.storage.removeLocalStorageData('registrationEmailId');
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 1000);
      }
    },(err: any) =>{
      if(err.error = 'Invalid OTP'){
        this.otpValue.setValue('');
        this.otpVerificationForm.reset();  
      }
    })
  }

  digitValidate(ele: any){
    ele.value = ele.data.replace(/[^0-9]/g, '');
  }

  tabChange(val: any){
    let ele = document.querySelectorAll('input');
    if(ele[val-1].value != ''){
      ele[val].focus();
    }
    else if(ele[val - 1].value == ''){
      ele[val -2].focus();
    }
  }
  resendOtp(){
    this.otpValue.setValue('');
    this.otpVerificationForm.reset();

    const data = {
      email: this.getRegistrationEmailId,
    }
    this._apiservice.httpPostPublic('user/resend-register-otp', data).subscribe((res) =>{
      if(res){
        this.toastr.success('', 'OTP send'); 
        clearInterval(this.startingTime);
        this.disabledVerifyButton = false;
        this.disableResendButton = true;
        this.countDown();
      }
    })
  }

  backClicked() {
    this._location.back();
  }

  onOtpChange(e:any){
    this.otpVerificationForm.patchValue({otp: e})
  }
  

}
