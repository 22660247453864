import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/core/shared/api.service';
import { StorageService } from 'src/app/core/shared/storage.service';
import { environment } from 'src/environments/environment';
import { ModalPopupComponent } from '../modal-popup/modal-popup.component';

@Component({
  selector: 'app-overlay-sidebar',
  templateUrl: './overlay-sidebar.component.html',
  styleUrls: ['./overlay-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OverlaySidebarComponent implements OnInit {
  @Input() menuwidth: Number = 0;
  subscription: any = []; 
  subscriptionRoute:any = String;
  customerProfileDetail: any = [];
  firstName: String = '';
  lastName: String = '';
  profilePic: String = '';
  subscribed: String = '';

  constructor(
    private modalService: NgbModal,
    private _apiservice: ApiService,
    private storage: StorageService
  ) { }

  ngOnInit(): void {
    this.storage.ProfilePicChangedEvent.subscribe(()=>{
      this.setBasicVariables();
    });
    this.storage.firstNameChangedEvent.subscribe(()=>{
      this.setBasicVariables();
    })
    this.storage.lastNameChangeEvent.subscribe(()=>{
      this.setBasicVariables();
    })
    this.setBasicVariables();
    this.getSubscription();
  }

  setBasicVariables(){
    this.firstName = this.storage.userFirstName;
    this.lastName = this.storage.userLastName;
    this.profilePic = this.storage.profilePic;
  }

  logout(){
    const modalRef = this.modalService.open(ModalPopupComponent);
    modalRef.componentInstance.modalType = 'user';
  }

  getProfileImg(img: any) {
    return environment.profileBaseUrl + img
  }

  getSubscription(){
    this.subscribed = this.storage.isSubscription;
    if(this.subscribed == 'no'){
      this.subscriptionRoute = '/public/subscription-packages'
    }
    else{
      this.subscriptionRoute = '/public/subscription'
    }

  }

}
