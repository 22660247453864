import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/core/shared/storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  token: String = '';
  showHeader: boolean = false;
  urlName: any;
  headerTitle: any;
  buttonName: string = '';
  buttonEnable: string = '';
  firstName: String = '';
  lastName: String = '';
  profilePic: String = '';
  subUrl: any;
  role: String = '';

  constructor(private router: Router, private storage: StorageService) {}

  ngOnInit(): void {
    this.storage.ProfilePicChangedEvent.subscribe(()=>{
      this.setBasicVariables();
    })
    this.storage.firstNameChangedEvent.subscribe(()=>{
      this.setBasicVariables();
    })
    this.storage.lastNameChangeEvent.subscribe(()=>{
      this.setBasicVariables();
    })
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.urlName = this.router.url.split('/').pop();
    this.subUrl = this.router.url
    
    this.setBasicVariables();

    // for header name
    if (this.urlName == 'help-center-ticket-list') {
      this.headerTitle = 'Help Center';
    } else if (this.urlName == 'help-center') {
      this.headerTitle = 'Help Center';
    } else if (this.urlName == 'blogs') {
      this.headerTitle = 'Blog';
    }else if(this.subUrl.includes('blogs-details')){
      this.headerTitle = 'Blog';
    } else if (this.urlName == 'news') {
      this.headerTitle = 'News';
    }else if(this.urlName == 'affiliate' || this.urlName == 'profit-calculator' || this.urlName == 'vision'){
      this.headerTitle = 'Affiliates';
    }
    else if(this.urlName == 'forex-market' || this.urlName == 'spot-market' || this.urlName == 'future-market' || this.urlName == 'watchlist'){
      this.headerTitle = 'Signal Cards';
    }else if(this.urlName == 'subscription-packages'){
      this.headerTitle = 'Subscriptions';
    }  else {
      this.headerTitle = this.storage.headerName;
    }
  }

  setBasicVariables(){
    this.token = this.storage.token;
    this.role = this.storage.role;
    this.firstName = this.storage.userFirstName;
    this.lastName = this.storage.userLastName
    this.profilePic = this.storage.profilePic;
  }

  openNav() {
    // if (this.menuwidth == 0) {
    //   this.menuwidth = 100;
    // }

    // else {
    //   this.menuwidth = 0;
    // };

    const bodyBlock = document.querySelector('body');

    bodyBlock?.classList.toggle('sidebar_active');
  }

  getProfileImg(img: any) {
    return environment.profileBaseUrl + img
  }
}
