<!-- <div class="card_box_wrap forex_card_wrap"> -->
<div [ngClass]="[
  data?.card_type == 'Forex market' ? 'card_box_wrap forex_card_wrap': '', 
  data?.card_type == 'Margin trade' ? 'card_box_wrap future_card_wrap': '',
  data?.card_type == 'Spot market' ? 'card_box_wrap card_box_wrap': ''
  ]">

  <!-- .forex_card_wrap , .future_card_wrap to .card_box_wrap -->
  <div class="each_signal_card">
    <div class="card_inner">
      <div class="signal_card_left">
        <div class="card_left_top">
          <div class="card_data">
            <label>On Binance</label>
            <span>{{ data?.market }}</span>
          </div>
          <div class="card_data">
            <label>Current Price</label>
            <span *ngIf="data?.card_type == 'Spot market' || data?.card_type =='Margin trade'">{{data.price }}
              BTC</span>
            <span *ngIf="data?.card_type == 'Forex market'">{{ data.price }}</span>
          </div>
          <div class="card_data">
            <label>Created On</label>
            <span>{{
              data.created_at | date : "medium"
              }}</span>
          </div>
          <div class="card_data bk_mrk">
            <button type="button" class="bookmark_btn" (click)="add()">
              <i [ngClass]="
              data?.watch_list_status != 1
                ? 'fa-regular fa-bookmark'
                : 'fa-solid fa-bookmark'
            "></i>
            </button>
            <button type="button" class="trade_btn" placement="top" ngbTooltip="Coming Soon"
              tooltipClass="trade_tooltip">
              Trade
            </button>
          </div>
        </div>
        <div class="card_status">
          <div class="each_status" *ngIf="data.card_type != 'Spot market'">
            <span class="short" *ngIf="data.position == 'Short'">
              <i class="fa-solid fa-sort-down"></i>
              Short
            </span>
            <span class="long" *ngIf="data.position == 'Long'">
              <i class="fa-solid fa-sort-up"></i>
              Long
            </span>
          </div>
          <div class="each_status">
            <label>Risk:</label>
            <span>{{data.risk_status}}</span>
          </div>
          <div class="each_status" *ngIf="data.card_type != 'Spot market'">
            <label>Leverage:</label>
            <span>{{ data?.leverage }}px</span>
          </div>
          <div class="each_status">
            <label>Time Frame:</label>
            <span>{{ data.time_frame}}</span>
          </div>
        </div>
        <div class="card_left_middle">
          <div class="calc_sec">
            <div class="each_calc entry">
              <label>Entry:</label>
              <span>{{ data.entry_from }} - {{ data.entry_to }}</span>
            </div>
            <div class="each_calc loss">
              <label>Stop Loss:</label>
              <span>{{ data.stop_value }} {{ data.quote_currency }} ({{
                data.stop_loss_percentage
                }}%)</span>
            </div>
          </div>
        </div>
        <div class="card_left_bottom">
          <div class="card_left_bottom_inner">
            <div class="label_block">
              <label>Targets:</label>
            </div>
            <div class="value_block">
              <div class="value_row">
                <div class="value_col">
                  <div class="each_value" *ngIf="data?.target_value_obj[0] != undefined">
                    <label>{{ data?.target_value_obj[0]?.index + 1 }}</label>
                    <span>{{ data?.target_value_obj[0]?.targetValue }}
                      <em>({{ data?.target_value_obj[0]?.targetPercentage }}%)</em></span>
                  </div>
                  <div class="each_value" *ngIf="data?.target_value_obj[1] != undefined">
                    <label>{{ data?.target_value_obj[1]?.index + 1 }}</label>
                    <span>{{ data?.target_value_obj[1]?.targetValue }}
                      <em>({{ data?.target_value_obj[1]?.targetPercentage }}%)</em></span>
                  </div>
                </div>
                <div class="value_col">
                  <div class="each_value" *ngIf="data?.target_value_obj[2] != undefined">
                    <label>{{ data.target_value_obj[2]?.index + 1 }}</label>
                    <span>{{ data.target_value_obj[2]?.targetValue }}
                      <em>({{ data.target_value_obj[2]?.targetPercentage }}%)</em></span>
                  </div>
                  <div class="each_value" *ngIf="data?.target_value_obj[3] != undefined">
                    <label>{{ data.target_value_obj[3]?.index + 1 }}</label>
                    <span>{{ data.target_value_obj[3]?.targetValue }}
                      <em>({{ data.target_value_obj[3]?.targetPercentage }}%)</em></span>
                  </div>
                </div>
              </div>
              <!-- Mobile view graph please integrate here also -->
              <div class="calc_mobile_block">
                <div class="card_total_calc">
                  <h3>{{ data.total_percentage }}%</h3>
                  <span>In {{ data.total_hours }} Hours</span>
                  <ul class="signal_lines">
                    <li>
                      <span *ngIf="data.target_value_obj[0]?.targetValue != undefined"
                        [ngClass]="{'inactive': data?.barChanging == false}"></span><label
                        *ngIf="data.target_value_obj[0]?.targetValue != undefined">1</label>
                    </li>
                    <li>
                      <span *ngIf="data.target_value_obj[1]?.targetValue != undefined"
                        [ngClass]="{'inactive': data?.barChanging == false}"></span><label
                        *ngIf="data.target_value_obj[1]?.targetValue != undefined">2</label>
                    </li>
                    <li>
                      <span *ngIf="data.target_value_obj[2]?.targetValue != undefined"
                        [ngClass]="{'inactive': data?.barChanging == false}"></span><label
                        *ngIf="data.target_value_obj[2]?.targetValue != undefined">3</label>
                    </li>
                    <li>
                      <span *ngIf="data.target_value_obj[3]?.targetValue != undefined"
                        [ngClass]="{'inactive': data?.barChanging == false}"></span><label
                        *ngIf="data.target_value_obj[3]?.targetValue != undefined">4</label>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- Mobile view graph ends  integrate here also-->
            </div>
          </div>
        </div>
      </div>
      <div class="signal_card_right">
        <div class="card_total_calc">
          <h3> {{ data.total_percentage }}%</h3>
          <span>In {{ data.total_hours }} Hours</span>
          <ul class="signal_lines">
            <li>
              <span *ngIf="data.target_value_obj[0]?.targetValue != undefined"
                [ngClass]="{'inactive': data?.barChanging == false}"></span><label
                *ngIf="data.target_value_obj[0]?.targetValue != undefined">1</label>
            </li>
            <li>
              <span *ngIf="data.target_value_obj[1]?.targetValue != undefined"
                [ngClass]="{'inactive': data?.barChanging == false}"></span><label
                *ngIf="data.target_value_obj[1]?.targetValue != undefined">2</label>
            </li>
            <li>
              <span *ngIf="data.target_value_obj[2]?.targetValue != undefined"
                [ngClass]="{'inactive': data?.barChanging == false}"></span><label
                *ngIf="data.target_value_obj[2]?.targetValue != undefined">3</label>
            </li>
            <li>
              <span *ngIf="data.target_value_obj[3]?.targetValue != undefined"
                [ngClass]="{'inactive': data?.barChanging == false}"></span><label
                *ngIf="data.target_value_obj[3]?.targetValue != undefined">4</label>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>