<div class="navigation_wrapper">
  <div class="container">
    <div class=" navigation_container">
      <div class="logo">
        <img
          class="logo-black-green"
          (click)="homePage()"
          src="../../../../../assets/images/tenx/logo-black-green.png"
          alt="logo"
        />
      </div>
      <div class="signin">
        <a class="signin-background" routerLink="user/login">Sign In</a>
      </div>
    </div>
  </div>
</div>
<div class="main_wrapper">
  <div class="page_container" id="container">
    <section class="title-page section">
        AFFILIATES
    </section>
    <section class="video-page section">
        <div class="row h-100">
            <div class="text-wrapper col-sm-12 col-md-5 col-lg-5 col-xl-5">
                <h1>Join TenX Affiliate Program And Enjoy Earning Passive Income</h1>
                <button>Get Started</button>
            </div>
            <div class="video-wrapper col-sm-12 col-md-7 col-lg-7 col-xl-7">
                <div class="video-container">
                    <img src="../../../../../assets/images/tenx/video.png" alt="">
                </div>
            </div>
        </div>
    </section>
    <section class="banner-page section">
        <h1>The First Affiliate Marketing Program Based On A Smart Contract.</h1>
        <p>Refer friends and all payments are instantly earned through the BNB smart contract.</p>
        <div class="green-banner">
            <div class="text-wrapper">
                <div class="box1 col-md-6 col-lg-6 col-xl-6">
                    <div class="text">
                        The fastest growing affiliate marketting <br>
                        <span><img src="../../../../../assets/images/tenx/innerPage/ArrowBlack.svg" alt=""></span>
                    </div>
                </div>
                <div class="box2 col-md-6 col-lg-6 col-xl-6">
                    <div class="count">1000+</div>
                    <div class="text">Already joined for our TenX affiliate program waitlist.</div>
                </div>
            </div>
        </div>
    </section>
    <section class="cards-page section">
      <div class="main">
        <div class="left-box col-md-12 col-lg-6 col-xl-6">
          <div class="box">
            <p class="title">4 Level Of Commision</p>
            <p class="content">By sharing your affiliate link you can earn up to four levels of commission. First level is 10%, second level is 8%, third level is 6%, and fourth level is 4%</p>
            <p class="lean-more">Lean More <span><img src="../../../../../assets/images/tenx/innerPage/ArrowBlack.svg" alt=""></span></p>
          </div>
          <div class="box">
            <p class="title">Smart Contract Based Affiliate</p>
            <p class="content">All payments made through this affiliate program are based on our smart contract.</p>
            <p class="lean-more">Lean More <span><img src="../../../../../assets/images/tenx/innerPage/Arrow-white.svg" alt="arrow"></span></p>
          </div>
          <div class="box">
            <p class="title">Get Paid Instantly</p>
            <p class="content">As soon as your referee subscribes, you receive your payment instantly which will also be available for withdrawal.</p>
            <p class="lean-more">Lean More <span><img src="../../../../../assets/images/tenx/innerPage/Arrow-white.svg" alt="arrow"></span></p>
          </div>
        </div>
        <div class="right-box col-md-12 col-lg-6 col-xl-6">
          <div class="box">
            <p class="title">Get Paid By BNB Or BUSD</p>
            <p class="content">All commissions made in either BNB or BUSD will be paid according to that coin used by your referee.</p>
            <p class="lean-more">Lean More <span><img src="../../../../../assets/images/tenx/innerPage/Arrow-white.svg" alt=""></span></p>
          </div>
          <div class="box">
            <p class="title">Unlimited Potential Of Commission</p>
            <p class="content">You don’t only earn commission from who you directly invite, but also the consequenting referees gained from each level</p>
            <p class="lean-more">Lean More <span><img src="../../../../../assets/images/tenx/innerPage/ArrowBlack.svg" alt=""></span></p>
          </div>
          <div class="box">
            <p class="title">No Minimal Withdrawal Limit</p>
            <p class="content">There is no limit amount when you wish to withdraw.</p>
            <p class="lean-more">Lean More <span><img src="../../../../../assets/images/tenx/innerPage/ArrowBlack.svg" alt=""></span></p>
          </div>
        </div>
      </div>
    </section>
    <section class="levels-page section"></section>
  </div>
</div>
