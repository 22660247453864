import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../core/shared/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StorageService } from '../core/shared/storage.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  public form: any = FormGroup;
  public submitted:boolean = false;
  constructor(
    private fb: FormBuilder,
    private _apiservice: ApiService,
    private toastr: ToastrService,
    private router: Router,
    private storage: StorageService
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', Validators.required]
    })
  }

  get f(){
    return this.form.controls;
  }

  onSubmit(){
    this.submitted = true;

    if(this.form.valid){
      this._apiservice.httpPostPublic('/user/forgot-password',this.form.value).subscribe((res:any)=>{
        this.toastr.success('', 'OTP send to email');
        this.router.navigate([`/otp-forgot-password/${btoa(this.form.value.email)}`])
      },(err: any) => {
        if(err.code === 499){
          this.storage.setLocalStorageData('registrationEmailId',this.form.value.email);
          this.router.navigate(['/registration-otp-verification'])
        }
      });
    }
  }

}
