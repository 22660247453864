<div class="newGen">
  <div class="row content-wrapper">
    <div class="col-md-5">
      <h1>Join the TenX Affiliate Program and Enjoy Earning Passive Income</h1>
      <button class="button" (click)="roadmapNotifyNavigation()">
        Get Started
      </button>
    </div>
    <div class="col-md-7 video-section">
      <div class="playBtn">
        <img
          id="videoPlayIcon"
          *ngIf="showPlayIcon"
          src="assets/images/tenx/playbutton.png"
          alt="play button"
        />
      </div>
      <video class="video" id="video" controls (click)="playVideo()">
        <source
          src="assets/images/tenx/innerPage/affiliate-innerpage/tenx_affiliate_program.mp4"
          type="video/mp4"
        />
      </video>
    </div>
  </div>
</div>
