<div class="wrapper">
    <app-header></app-header>
    <app-sidebar *ngIf="token && role == '2'"></app-sidebar>
    <app-spinner></app-spinner>
    <section class="content_body" *ngIf="!token">
        <router-outlet></router-outlet>
    </section>
    <section class="content_body_padding" *ngIf="token">
        <router-outlet></router-outlet>
    </section>
    <app-footer></app-footer>
</div>