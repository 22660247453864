import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { AdminHeaderComponent } from './admin-header/admin-header.component';
import { AdminSidebarComponent } from './admin-sidebar/admin-sidebar.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { FormErrorComponent } from './form-error/form-error.component';
import {RouterModule} from '@angular/router';
import { SpinnerComponent } from './spinner/spinner.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NavComponent } from './nav/nav.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { AnimationLoaderComponent } from './animation-loader/animation-loader.component';
import { LandingpageFooterComponent } from './landingpage-footer/landingpage-footer.component';
import { OverlaySidebarComponent } from './overlay-sidebar/overlay-sidebar.component';
import { ModalPopupComponent } from './modal-popup/modal-popup.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RoundTenPipe } from './filters/round-ten.pipe';
import { ConvertInDaysPipe } from './filters/convert-in-days.pipe';
import { NgChartsModule } from 'ng2-charts';
import { SignalCardsComponent } from './signal-cards/signal-cards.component';
import { GlobalSignalCardComponent } from './global-signal-card/global-signal-card.component';



@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    AdminHeaderComponent,
    AdminSidebarComponent,
    FormErrorComponent,
    SpinnerComponent,
    NavComponent,
    BreadcrumbComponent,
    AnimationLoaderComponent,
    LandingpageFooterComponent,
    OverlaySidebarComponent,
    ModalPopupComponent,
    RoundTenPipe,
    ConvertInDaysPipe,
    SignalCardsComponent,
    GlobalSignalCardComponent,

  ],
  imports: [
    CommonModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    FormsModule,
    AngularEditorModule,
    RouterModule,
    NgxSpinnerModule,
    NgbModule,
    NgChartsModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    AdminHeaderComponent,
    AdminSidebarComponent,
    NgxPaginationModule,
    ReactiveFormsModule,
    FormsModule,
    AngularEditorModule,
    FormErrorComponent,
    SpinnerComponent,
    NgxSpinnerModule,
    NavComponent,
    BreadcrumbComponent,
    AnimationLoaderComponent,
    LandingpageFooterComponent,
    ModalPopupComponent,
    RoundTenPipe,
    ConvertInDaysPipe,
    NgChartsModule,
    SignalCardsComponent,
    GlobalSignalCardComponent
  ]
})
export class SharedModule { }
