import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/core/shared/api.service';
import { Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-signal-cards',
  templateUrl: './signal-cards.component.html',
  styleUrls: ['./signal-cards.component.scss']
})
export class SignalCardsComponent implements OnInit {
  @Input() data: any = [];
  @Output() addInWatchList = new EventEmitter<string>();
  constructor(
  ) { }

  ngOnInit(): void {
    const data = this.data.target_value_obj
    if(parseFloat(data[data.length-1].targetPercentage) >= parseFloat(this.data.total_percentage)){
      this.data.barChanging = true;
      }
      else{
        this.data.barChanging = false;
      }
  }

  add() {
    this.addInWatchList.emit(this.data);
  }

}
