import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/core/shared/storage.service';
@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  public href: string = "";
  constructor(private router: Router, private storeService: StorageService) { }
  loggedIn: boolean = false;
  responsive = 490;
  toggleNavIcon: boolean = false;
  commonMobileNavIcon: boolean = false;
  roadmapMobileNavIcon: boolean = false;
  ngOnInit(): void {
    this.href = this.router.url;
  }
  showAfterLoginToggleIcon() {
    if (this.responsive <= 500 && this.loggedIn) {
      return true
    } else {
      return false
    }
  }

  DynamicMobileNavIcon() {
    if (this.toggleNavIcon) {
      return 'assets/images/mobile_nav_close.png'
    } else {
      return 'assets/images/mobile_nav_open.png'
    }
  }
  DynamicMobileNavIconRoadmap() {
    if (this.toggleNavIcon) {
      return 'assets/images/mobile_nav_close_black.png'
    } else {
      return 'assets/images/mobile_nav_open_black.png'
    }
  }

  toggleAfterLoginNavIcon() {
    this.toggleNavIcon = !this.toggleNavIcon;
  }

  logo() {
    if (this.href == "/tenx") {
      return 'assets/images/tenx/logo.png'
    } else if (this.href == "/signal") {
      return 'assets/images/tenx/logo.png'
    } else if (this.href == "/affiliate") {
      return 'assets/images/tenx/logo-black-green.png'
    } else if (this.href == "/roadmap" || this.href == "/roadmap/notifyMe") {
      return 'assets/images/tenx/logo-black.png'
    } else {
      return 'assets/images/tenx/logo.png'
    }
  }
  navBackground() {
    if (this.href == "/tenx") {
      return 'tenx-bg'
    } else if (this.href == "/signal") {
      return 'signal-bg'
    } else if (this.href == "/affiliate") {
      return 'affiliate-bg'
    } else if (this.href == "/roadmap" || this.href == "/roadmap/notifyMe") {
      return 'roadmap-bg'
    } else {
      return 'tenx-bg'
    }
  }
  SignInBtnColor() {
    if (this.href == "/tenx" || this.href == "/signal" || this.href == "/affiliate") {
      this.commonMobileNavIcon = true;
      return 'greenBtn'
    } else if (this.href == "/roadmap" || this.href == '/roadmap/notifyMe') {
      this.roadmapMobileNavIcon = true;
      return 'blackBtn'
    } else {
      this.commonMobileNavIcon = true;
      return 'greenBtn'
    }
  }
  roadmapNotifyNavigation() {
    this.storeService.waitlist.next(true);
    // this.router.navigate(['/roadmap#notifyMe']);
    this.router.navigate(['/roadmap', 'notifyMe']);
  }
  homePage() {
    this.router.navigate(['/']);
  }

  // signIn() {
  //   this.router.navigate(['/roadmap#notifymeRoadmap']);
  // }


}
