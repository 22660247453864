<header class="header_wrapper">
  <div class="container-fluid">
    <nav class="navbar_inner">
      <div class="header_nav_left">
        <div class="mobile_nav_block">
          <button type="button" class="sidebar_tog">
            <img src="assets/img/menu.png" alt="Toggle" />
          </button>
          <a class="brand"><img src="assets/img/logo.png" alt="Logo" /></a>
        </div>
        <div class="page_title">
          <a class="pg_title">{{ headerTitle }}</a>
        </div>
      </div>

      <div class="header_nav_right">
        <ul>
          <li class="px-3" *ngIf="buttonEnable == 'enable'">
            <button type="button" class="create_btn" (click)="create()">
              {{ buttonName }}
            </button>
          </li>
          <li>
            <button
              type="button"
              class="notify"
              [routerLink]="['/admin/support-list']"
            >
              <i class="fa-solid fa-bell"></i>
              <span class="notify_count">
                {{ supportListData.total }}
                <span class="visually-hidden">unread messages</span>
              </span>
            </button>
          </li>
          <!-- <li>
              <div class="user_profile">
                <h3 class="user_name">{{firstName}} {{lastName}}</h3>
                <div class="profile_img">
                  <span class="profile_dp"
                    ><img src="assets/img/profileicon.png" alt="Profile"
                  /></span>
                </div>
              </div>
            </li> -->
        </ul>
      </div>
    </nav>
  </div>
</header>
