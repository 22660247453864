<div class="plans-wrap">
  <!-- <div class="row responsivePlans">
        <div class="col-md-3">
            <div class="plans planOne">
                
                <div class="plan-data white ">
                    <h3>Monthly</h3>
                    <h4>Subscription</h4>
                    <h5>$199<span>/Year</span></h5>
                </div>
            </div>

        </div>
        <div class="col-md-3">
            <div class="plans planTwo">
                
                <div class="plan-data white">
                    <h3>3 Monthly</h3>
                    <h4>Subscription</h4>
                    <h5>$179<span>/Year</span></h5>
                    <h6>$538</h6>
                    <span>10% dISCOUNT</span>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="plans planThree">
               
                <div class="plan-data white gray">
                    <h3>6 Monthly</h3>
                    <h4>Subscription</h4>
                    <h5>$170<span>/Year</span></h5>
                    <h6>$1194</h6>
                    <span>15% dISCOUNT</span>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="plans planFour">
                
                <div class="plan-data white black">
                    <h3>12 Monthly</h3>
                    <h4>Subscription</h4>
                    <h5>$160<span>/Year</span></h5>
                    <h6>$2388</h6>
                    <span>20% dISCOUNT</span>
                </div>
            </div>
        </div>

    </div> -->
  <div class="">
    <div class="tab-wraper">
      <div class="row">
        <div class="col-md-12 col-lg-4 col-xl-4">
          <h3 class="frequently">
            Frequently<br />
            Asked Questions
          </h3>
        </div>
        <div class="col-md-12 col-lg-8 col-xl-8">
          <div class="toggle-data">
            <div class="wrap" *ngFor="let data of question">
              <h4
                [ngClass]="{ active: data.active }"
                (click)="data.active = !data.active"
              >
                {{ data.title }}
              </h4>
              <p *ngIf="data.active">{{ data.content }}</p>
              <p *ngIf="data.active">{{ data.levels }}</p>
              <p *ngIf="data.active">{{ data.levelContentA }}</p>
              <p *ngIf="data.active">{{ data.levelContentB }}</p>
              <p *ngIf="data.active">{{ data.levelContentC }}</p>
              <p *ngIf="data.active">{{ data.levelContentD }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
