import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/core/shared/api.service';
import { CookieService } from 'ngx-cookie-service';
import { StorageService } from '../core/shared/storage.service';
import { noWhitespaceValidator } from '../core/validators/validate.validators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loginForm: any = FormGroup;
  submitted: boolean = false;
  public showPassword!: boolean;
  keepLoggedIn: boolean = false;
  loggedInEmail: any;
  loggedInPassword: any;
  subscription: any = [];
  isSubscribed:any = String;

  constructor(
    private fb: FormBuilder,
    private _apiservice: ApiService,
    private toastr: ToastrService,
    private router: Router,
    private cookieService: CookieService,
    private storage: StorageService
  ) { }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, noWhitespaceValidator.noWhiteSpace]],
      password: ['', [Validators.required, noWhitespaceValidator.noWhiteSpace]],
      checked: ['']
    })

    this.loggedInEmail = this.cookieService.get('login-email')
    this.loggedInPassword = this.cookieService.get('login-password')

    if(this.loggedInEmail && this.loggedInPassword){
        this.loginForm.value.email = atob(this.cookieService.get('login-email')),
        this.loginForm.value.password =  atob(this.cookieService.get('login-password')),
        this.loginForm.value.checked = true,
      this.loginForm.patchValue({'email':this.loginForm.value.email,'password': this.loginForm.value.password, 'checked': this.loginForm.value.checked})
      this.login();
    }
  }

  login() {
    this.submitted = true;
    if(this.loginForm.invalid){
      return;
    }
    const data = {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password
    }

    this._apiservice.httpPostPublic('user/login', data).subscribe((res) => {
      if (res) {
        if(res.data.twoFactorAuthentication){
          this.toastr.success('', 'OTP send to email');
          setTimeout(() => {
            this.router.navigate([`/otp-verification/${btoa(this.loginForm.value.email)}/${btoa(this.loginForm.value.password)}`])
          }, 1000);
        }else{
          if(this.keepLoggedIn == true || this.loginForm.value.checked == true){
            this.cookieService.set('login-email', btoa(this.loginForm.value.email));
            this.cookieService.set('login-password', btoa(this.loginForm.value.password));
          }
          else if(this.keepLoggedIn == false){
            this.cookieService.delete('login-email')
            this.cookieService.delete('login-password')
          }
          this.toastr.success('', 'Login successfully');
          this.storage.setLocalStorageData('token', res.data.loginResponse.token);
          this.storage.setLocalStorageData('firstName', res.data.loginResponse.first_name);
          this.storage.setLocalStorageData('lastName', res.data.loginResponse.last_name);
          this.storage.setLocalStorageData('user_role', res.data.loginResponse.role);
          this.storage.setLocalStorageData('role', res.data.loginResponse.role);
          this.storage.setLocalStorageData('userId',res.data.loginResponse.id);
          this.storage.setLocalStorageData('profilePic',res.data.loginResponse.profile_picture);
          
          setTimeout(() => {
            let apiUrl = 'payment-history/subscription'
            this._apiservice.httpGet(apiUrl).subscribe((res)=> {
              this.subscription = res.data;
              if(this.subscription?.current_subscription == undefined){
                this.isSubscribed = 'no'
                this.storage.setLocalStorageData('isSubscribed',this.isSubscribed);
              }
              else if(this.subscription?.current_subscription != undefined){
                this.isSubscribed = 'yes'
                this.storage.setLocalStorageData('isSubscribed',this.isSubscribed);
              }
            });
            this.router.navigate(['/public/help-center-ticket-list'])
          }, 1000);
        }
        this.submitted = false;
       
      }
      else if (res?.statusCode == 400) {
        this.toastr.error('error', res.error.error)
      }
    },(err: any) => {
      if(err.code === 499){
        this.storage.setLocalStorageData('registrationEmailId',this.loginForm.value.email);
        this.router.navigate(['/registration-otp-verification'])
      }
    });
    
  }

  keepLogin(value: any){
    this.keepLoggedIn = !value;   
  }

  loginByEnter(event: any) {
    if (event.keyCode === 13) {
      this.login() 
    }
  }

}
