import { Injectable, Input,EventEmitter } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class StorageService {
  tabData = '';
  spotData = '';
  futureData = '';
  waitlist = new BehaviorSubject(false);
  ProfilePicChangedEvent = new EventEmitter();
  firstNameChangedEvent = new EventEmitter();
  lastNameChangeEvent = new EventEmitter();
  constructor(private _cookie: CookieService) {
  }
  setData(key: any, value: any, expiry?: any): void {
    const date = new Date();
    const exp = expiry ? new Date(date.setSeconds(date.getSeconds() + expiry)) : 0;

  }
  getData(key: string): any {
    return this._cookie.get(key);
  }
  clearData(): void {
    this._cookie.deleteAll('/');
  }

  setLocalStorageData(key: any, value: any) {
    localStorage.setItem(key, btoa(value));
  }

  removeLocalStorageData(key: string) {
    localStorage.removeItem(key);
  }

  get userFirstName(): string {
    return atob(localStorage.getItem('firstName') || '');
  };

  get userLastName(): string {
    return atob(localStorage.getItem('lastName') || '');
  };

  get token(): string {
    return atob(localStorage.getItem('token') || '');
  };

  get role(): string {
    return atob(localStorage.getItem('role') || '');
  };

  get admin_role(): string {
    return atob(localStorage.getItem('admin_role') || '');
  };

  get user_role(): string {
    return atob(localStorage.getItem('user_role') || '');
  };

  get userId(): string {
    return atob(localStorage.getItem('userId') || '');
  };

  get categoryName(): string {
    return atob(localStorage.getItem('categoryName') || '');
  };

  get registrationEmailId(): string {
    return atob(localStorage.getItem('registrationEmailId') || '');
  };

  get headerName(): string {
    return atob(localStorage.getItem('header-name') || '');
  };

  get forgetPasswordToken(): string {
    return atob(localStorage.getItem('forgetPasswordToken') || '');
  };

  get profilePic(): string {
    return atob(localStorage.getItem('profilePic') || '');
  };

  get isSubscription(): string {
    return atob(localStorage.getItem('isSubscribed') || '');
  };
}
