import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/core/shared/storage.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  token: String = '';
  menuwidth: Number = 0;
  display: boolean = false;
  constructor(
    private storage: StorageService
  ) {}

  ngOnInit(): void {
    this.token = this.storage.token;
  }

  openNav() {
    // this.display = !this.display;
    // if (this.menuwidth == 0) {
    //   this.menuwidth = 100;
    // }

    // else {
    //   this.menuwidth = 0;
    // };

    const bodyBlock = document.querySelector('body');

    bodyBlock?.classList.toggle('sidebar_active');
  }
}
