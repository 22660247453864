import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../../core/shared/storage.service';

@Component({
  selector: 'app-forex-dailytips',
  templateUrl: './forex-dailytips.component.html',
  styleUrls: ['./forex-dailytips.component.scss'],
})
export class ForexDailytipsComponent implements OnInit {
  futureData: any = [
    { id: 1, active: true },
    { id: 2, active: false },
    { id: 3, active: false },
    { id: 4, active: false },
    { id: 5, active: false },
    { id: 6, active: false },
    { id: 7, active: false },
    { id: 8, active: false },
    { id: 9, active: false },
    { id: 10, active: false },
    { id: 11, active: false },
    { id: 12, active: false },
    { id: 13, active: false },
    { id: 14, active: false },
  ];

  slider: any = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
  ];

  intervalId: any;
  constructor(public storageService: StorageService) {}

  ngOnInit(): void {
    this.storageService.tabData = '1';
    this.automaticSlider();
  }
  ngOnDestroy(): void {
    this.clearSlider();
  }
  // automatic slider using call back function
  automaticSlider() {
    // automatic sliderr
    this.clearSlider();
    this.intervalId = setInterval(() => {
      const currentIndex = this.slider.findIndex(
        (x: any) => x === this.storageService.futureData
      );
      this.storageService.futureData =
        this.slider[currentIndex] === '14'
          ? this.slider[0]
          : this.slider[currentIndex + 1];
      // console.log('interval running');
    }, 4000);
  }
  clearSlider() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
  tab(data: any) {
    this.automaticSlider();
    if (data == 1) {
      this.storageService.futureData = '1';
    }
    if (data == 2) {
      this.storageService.futureData = '2';
    }
    if (data == 3) {
      this.storageService.futureData = '3';
    }
    if (data == 4) {
      this.storageService.futureData = '4';
    }
    if (data == 5) {
      this.storageService.futureData = '5';
    }
    if (data == 6) {
      this.storageService.futureData = '6';
    }
    if (data == 7) {
      this.storageService.futureData = '7';
    }
    if (data == 8) {
      this.storageService.futureData = '8';
    }
    if (data == 9) {
      this.storageService.futureData = '9';
    }
    if (data == 10) {
      this.storageService.futureData = '10';
    }
    if (data == 11) {
      this.storageService.futureData = '11';
    }
    if (data == 12) {
      this.storageService.futureData = '12';
    }
    if (data == 13) {
      this.storageService.futureData = '13';
    }
    if (data == 14) {
      this.storageService.futureData = '14';
    }
  }
}
