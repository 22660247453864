import { Component, OnInit } from '@angular/core';
import { StorageService } from '../core/shared/storage.service';

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss']
})
export class PublicComponent implements OnInit {
  token: String = '';
  role: string = '';
  constructor(
    private storage: StorageService
  ) { }

  ngOnInit(): void {
    this.token = this.storage.token;
    this.role = this.storage.role;
  }

}
